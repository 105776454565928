import React, { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { SpacingSizes } from '../../clarity';
import { PopupTitle, RunningTextPopup, TileButton } from '../../styles/commonText';
import { PopupWrap, PopupOverlay, PopupCloseIcon } from './common';

const PopupWrapCaratWeight = styled(PopupWrap)`
  padding-left: ${SpacingSizes.xlg};
  padding-right: ${SpacingSizes.xlg};
  padding-bottom: ${SpacingSizes.lg};
`;

const RunningTextPopupCaratWeight = styled(RunningTextPopup)`
  margin-bottom: ${SpacingSizes.sm};
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

function CaratWeight(parentState: any) {
  const { setPopupVisible, itemCarat, next } = parentState;
  const hidePopup = () => setPopupVisible(false);
  const hidePopupOnEscapeCb = (e: KeyboardEvent) => {
    if (e && e.code === 'Escape') {
      hidePopup();
    }
  };

  document.addEventListener('keydown', hidePopupOnEscapeCb);

  useEffect(() => () => document.removeEventListener('keydown', hidePopupOnEscapeCb));

  return (
    <>
      <PopupWrapCaratWeight>
        <PopupCloseIcon
          onClose={() => {
            hidePopup();
          }}
        />
        <PopupTitle data-automation="carat-weight-popup-title">Confirm Carat Weight</PopupTitle>
        <RunningTextPopupCaratWeight>
          Select your carat weight below to proceed.
        </RunningTextPopupCaratWeight>
        <Buttons>
          {[
            (parseFloat(itemCarat) / 100).toFixed(2),
            (parseFloat(itemCarat) / 10).toFixed(1),
            itemCarat,
          ].map((value: number, i: number) => (
            <TileButton
              data-automation={`carat-item-select-${i}`}
              selected={value === itemCarat}
              key={`item-carat-${value}`}
              size="sm"
              text={`${value} ct.`}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                next(e, value);
                hidePopup();
              }}
            />
          ))}
        </Buttons>
      </PopupWrapCaratWeight>
      <PopupOverlay
        onClick={() => {
          hidePopup();
        }}
      />
    </>
  );
}

export default CaratWeight;
