import React, { useEffect } from 'react';
import { PopupTitle, RunningTextPopup } from '../../styles/commonText';

import { PopupWrap, PopupOverlay, PopupCloseIcon } from './common';

const CenterDiamondPicture = 'images/popup/center_diamond.svg';

function CenterDiamond({ setPopupVisible }: any) {
  const hidePopup = () => setPopupVisible(false);
  const hidePopupOnEscapeCb = (e: KeyboardEvent) => {
    if (e && e.code === 'Escape') {
      hidePopup();
    }
  };

  document.addEventListener('keydown', hidePopupOnEscapeCb);

  useEffect(() => () => document.removeEventListener('keydown', hidePopupOnEscapeCb));

  return (
    <>
      <PopupWrap>
        <PopupCloseIcon
          onClose={() => {
            hidePopup();
          }}
          dataAutomation="central-diamond-popup-close"
        />
        <PopupTitle>A Center Diamond</PopupTitle>
        <RunningTextPopup>
          A ring&apos;s center diamond is typically the largest, most prominent diamond on the ring.
        </RunningTextPopup>
        <img src={CenterDiamondPicture} alt="Center diamond" />
      </PopupWrap>
      <PopupOverlay
        onClick={() => {
          hidePopup();
        }}
      />
    </>
  );
}

export default CenterDiamond;
