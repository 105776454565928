import React, { useEffect } from 'react';
import { mainTheme, ThemeProvider } from '@worthy-npm/worthy-common-ui-components';
import StepSelectItemType from '../components/submission/step_select_item_type';
import GA from '../data/GA';
import Wizard, { IStep } from '../components/wizard';
import { updateWizardStepName } from '../slices/submitSlice';
import { useAppDispatch } from '../app/hooks';
import CommonLayout from './layout/commonLayout';

const createSteps = (): IStep[] => [
  {
    comp: StepSelectItemType,
    name: 'StepSelectItemType',
    caption: 'Jewelry Type',
    title: 'What type of jewelry do you have?',
    disableBackButton: true,
    disappearFromProgressBar: true,
    disableProgressBar: true,
  },
];

function SubmissionPage() {
  const dispatch = useAppDispatch();
  const steps = createSteps();

  useEffect(() => {
    dispatch(updateWizardStepName(steps[0].name));
    GA.flow();
    const el = document.querySelector("meta[name='theme-color']");
    if (el) {
      el.setAttribute('content', '#000000');
    }
  }, [dispatch, steps]);

  return (
    <ThemeProvider theme={mainTheme}>
      <CommonLayout itemType="" hideCloseBtn>
        <Wizard steps={steps} />
      </CommonLayout>
    </ThemeProvider>
  );
}

export default SubmissionPage;
