/* eslint no-param-reassign: ["error", { "props": false }] */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import type { IStep } from '../components/wizard';
import { CertificateType } from '../data/ItemDetails';
import { redirectToNewItem } from '../lib/commonUtils';
import { nextVisibleStepIndex, prevVisibleStepIndex } from '../lib/wizard';

import WorthyAPI from '../services/worthyAPI';
import type { RootState } from '../app/store';
import { generateEstimation } from '../lib/submitHelper';
import GA from '../data/GA';

const SLICE_NAME = 'submit';

export type TRegistration = {
  [index: string]: string;
};

export interface IRegistration {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface PhotoI {
  id: number;
  src: string | undefined;
  url: string;
  type: string;
}

export interface PhotoOldI {
  id: number;
  src: string | null;
}

const defaultPhotos: PhotoI[] = [];

const defaultPhotosOld: PhotoOldI[] = [
  {
    id: 1,
    src: null,
  },
  {
    id: 2,
    src: null,
  },
  {
    id: 3,
    src: null,
  },
  {
    id: 4,
    src: null,
  },
];

export interface SubmitState {
  type: string;
  setting: string;
  shape: string;
  style: string;
  diamondNumber: number;
  totalCarat: number;
  metalType: string;
  metalWeight: number;
  carat: number;
  color: string;
  clarity: string;
  certificate: string;
  certificateNumber: string;
  brand: string;
  photos: PhotoI[];
  photosOld: PhotoOldI[];
  secondarySubset: string;
  submissionId: string;
  wizard: {
    stepIndex: number;
    isFinal: boolean;
    stepName: string;
  };
  registration: IRegistration;
  registerAPI: {
    loading: boolean;
    isError: boolean;
    errorMsg: string;
    userData: Record<string, any> | null;
    err: Record<string, any> | null;
  };
  submitAPI: {
    loading: boolean;
    isError: boolean;
    errorMsg: string;
    bundleId: number;
    itemId: string;
    forcePhoneNumber: boolean;
    displayOptionalPhoneLabel: boolean;
    forcePhoto: boolean;
    itemAutoRejected: boolean;
    rejectReason: string;
    item: Record<string, any> | null;
    err: Record<string, any> | null;
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  user: {
    id?: string;
    isLoggedIn?: boolean;
    phone?: string;
    userData: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
  };
}

export const initialState: SubmitState = {
  type: '',
  setting: '',
  shape: '',
  carat: 0,
  style: '',
  diamondNumber: 0,
  totalCarat: 0,
  metalType: '',
  metalWeight: 0,
  color: '',
  clarity: '',
  certificate: CertificateType.None,
  certificateNumber: '',
  brand: '', // Empty string to avoid "other" as default
  photos: defaultPhotos,
  photosOld: defaultPhotosOld,
  secondarySubset: '',
  submissionId: uuidv4(),
  wizard: {
    stepIndex: 0,
    stepName: '',
    isFinal: false,
  },
  registration: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  registerAPI: {
    loading: false,
    isError: false,
    errorMsg: '',
    userData: null,
    err: null,
  },
  submitAPI: {
    loading: false,
    isError: false,
    errorMsg: '',
    bundleId: 0,
    itemId: '',
    forcePhoneNumber: false,
    displayOptionalPhoneLabel: false,
    forcePhoto: false,
    itemAutoRejected: false,
    rejectReason: '',
    item: null,
    err: null,
  },
  user: {
    id: '',
    isLoggedIn: false,
    userData: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  },
};

export type ItemInputsTypes = Pick<
  SubmitState,
  | 'type'
  | 'setting'
  | 'brand'
  | 'carat'
  | 'certificate'
  | 'clarity'
  | 'shape'
  | 'color'
  | 'certificateNumber'
>;

export type EarringsItemInputsTypes = Pick<
  SubmitState,
  | 'style'
  | 'metalType'
  | 'metalWeight'
  | 'diamondNumber'
  | 'totalCarat'
  | 'shape'
  | 'carat'
  | 'certificate'
  | 'brand'
  | 'photos'
>;

export const submitItem = createAsyncThunk<any, void, { state: RootState }>(
  `${SLICE_NAME}/submitItem`,
  async (a, { getState }) => {
    const state = getState().submit;
    WorthyAPI.storeStateInBackend(state, state.registration, 'SubmitItemBefore');
    let estimationResponse;
    const response = await WorthyAPI.createItem(state);
    const itemId = response?.data?.item?.id;
    if (itemId) {
      const isLoggedIn = state?.user?.isLoggedIn;
      GA.successfullItemSubmission();
      if (isLoggedIn) {
        estimationResponse = await generateEstimation(itemId);
      } else {
        estimationResponse = await WorthyAPI.estimate(itemId).catch((error) => {
          window?.rollbar?.error(`estimate API failed: ${error}`);
        });
      }
    }
    return {
      user: {
        ...response?.data?.user,
        ...(estimationResponse?.user || {}),
      },
      item: {
        ...response?.data?.item,
        ...(estimationResponse?.item || {}),
      },
    };
  },
);

export const getUser = createAsyncThunk(`${SLICE_NAME}/userData`, async () => {
  const response = await WorthyAPI.ADO();

  return response.data;
});

export const registerUser = createAsyncThunk<
  any,
  { data: SubmitState['registration']; clb: any; successClb?: any },
  { state: RootState }
>(
  `${SLICE_NAME}/registerUser`,
  async ({ data, clb, successClb }, { getState, rejectWithValue }) => {
    const state = getState().submit;
    const { bundleId } = state.submitAPI;
    WorthyAPI.storeStateInBackend(state, data, 'RegisterUserBefore');

    const response = await WorthyAPI.register(data, bundleId).finally(clb);

    if (response.data.errors) {
      return rejectWithValue(response.data.errors);
    }
    if (successClb) {
      successClb(response.data);
    }
    return response.data;
  },
);

export const updateUserPhoneNumber = createAsyncThunk<
  any,
  { data: SubmitState['registration']; callBack: any; successCallBack?: any },
  { state: RootState }
>(
  `${SLICE_NAME}/updatePhoneNumber`,
  async ({ data, callBack, successCallBack }, { getState, rejectWithValue }) => {
    const state = getState().submit;
    const { user } = state;
    WorthyAPI.storeStateInBackend(state, data, 'updateUserPhoneNumber');
    const response = await WorthyAPI.updatePhoneNumber(data, user?.id).finally(callBack);
    if (response.data.errors) {
      return rejectWithValue(response.data.errors);
    }
    if (successCallBack) {
      successCallBack();
    }
    return response.data;
  },
);

export const submitSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateSubmitAPILoading: (state, action: PayloadAction<boolean>) => {
      state.submitAPI.loading = action.payload;
    },
    updateSubmitAPIForcePhone: (state, action: PayloadAction<boolean>) => {
      state.submitAPI.forcePhoneNumber = action.payload;
    },
    updateSubmitAPIItemId: (state, action: PayloadAction<string>) => {
      state.submitAPI.itemId = action.payload;
    },
    updateType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    updateSetting: (state, action: PayloadAction<string>) => {
      state.setting = action.payload;
    },
    updateShape: (state, action: PayloadAction<string>) => {
      state.shape = action.payload;
    },
    updateSecondarySubset: (state, action: PayloadAction<string>) => {
      state.secondarySubset = action.payload;
    },
    updateStyle: (state, action: PayloadAction<string>) => {
      state.style = action.payload;
    },
    updateMetalType: (state, action: PayloadAction<string>) => {
      state.metalType = action.payload;
    },
    updateDiamondNumber: (state, action: PayloadAction<number>) => {
      state.diamondNumber = action.payload;
    },
    updateTotalCarat: (state, action: PayloadAction<number>) => {
      state.totalCarat = action.payload;
    },
    updateCarat: (state, action: PayloadAction<number>) => {
      state.carat = action.payload;
    },
    updateColor: (state, action: PayloadAction<string>) => {
      state.color = action.payload;
    },
    updateClarity: (state, action: PayloadAction<string>) => {
      state.clarity = action.payload;
    },
    updateUser: (state, action: PayloadAction<any>) => {
      const userData = action.payload;
      const isLoggedIn = userData && (userData.type === 'user' ? userData.lead_id : userData.id);
      state.user = {
        ...userData,
        isLoggedIn: !!isLoggedIn,
        userData: {
          id: userData?.id,
          email: userData?.email,
          firstName: userData?.first_name,
          lastName: userData?.last_name,
          phone: userData?.phone,
        },
      };
    },
    updateCertificate: (state, action: PayloadAction<{ type: string; number: string }>) => {
      state.certificate = action.payload.type;
      state.certificateNumber = action.payload.number;
    },
    updateBrand: (state, action: PayloadAction<string>) => {
      state.brand = action.payload;
    },
    updateRegistration: (state, action: PayloadAction<SubmitState['registration']>) => {
      state.registration = action.payload;
    },
    updatePhotos: (state, action: PayloadAction<PhotoI[]>) => {
      state.photos = action.payload;
    },
    updatePhotosOld: (state, action: PayloadAction<PhotoOldI[]>) => {
      state.photosOld = action.payload;
    },
    updateItemAutoRejected: (
      state,
      action: PayloadAction<{ rejected: boolean; reason: string }>,
    ) => {
      state.submitAPI.itemAutoRejected = action.payload.rejected;
      state.submitAPI.rejectReason = action.payload.reason;
    },
    updateWizardStepName: (state, action: PayloadAction<string>) => {
      state.wizard.stepName = action.payload;
    },
    nextStep: (state, action: PayloadAction<Omit<IStep, 'comp'>[]>) => {
      const steps = action.payload;
      const nextStepIndex = nextVisibleStepIndex(
        steps,
        state.wizard.stepIndex,
        !!state.user.isLoggedIn,
        state.secondarySubset,
      );

      if (steps[nextStepIndex]) {
        state.wizard.stepName = steps[nextStepIndex].name;
      }

      if (
        nextStepIndex !== -1 &&
        nextVisibleStepIndex(
          steps,
          nextStepIndex,
          !!state.user.isLoggedIn,
          state.secondarySubset,
        ) === -1
      ) {
        state.wizard.isFinal = true;
      }
      if (nextStepIndex === -1) {
        if (state.submitAPI.itemId && !state.submitAPI.itemAutoRejected) {
          redirectToNewItem(state.submitAPI.itemId);
        }
      } else {
        state.wizard.stepIndex = nextStepIndex;
      }
    },
    restartStep: (state, action: PayloadAction<Omit<IStep, 'comp'>[]>) => {
      const steps = action.payload;
      state.wizard.stepName = steps[0].name;
      state.wizard.stepIndex = 0;
      state.wizard.isFinal = false;
    },
    prevStep: (state, action: PayloadAction<Omit<IStep, 'comp'>[]>) => {
      const steps = action.payload;
      const prevStepIndex = prevVisibleStepIndex(
        steps,
        state.wizard.stepIndex,
        !!state.user.isLoggedIn,
        state.secondarySubset,
      );

      state.wizard.stepName = steps[prevStepIndex].name;

      if (prevStepIndex !== -1) {
        state.wizard.stepIndex = prevStepIndex;
      }
      state.wizard.isFinal = false;
    },
    onReceivedGoogleCredential: (state, action: PayloadAction<string>) => {
      WorthyAPI.storeStateInBackend(state, null, 'ReceivedGoogleCredential', action.payload);
    },
    onRegisteredWithGoogle: (state, action: PayloadAction<any>) => {
      WorthyAPI.storeStateInBackend(state, action.payload, 'Registered/LoggedInWithGoogle');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitItem.pending, (state) => {
      state.submitAPI.loading = true;
      state.submitAPI.isError = false;
      state.submitAPI.errorMsg = '';
      state.photos = defaultPhotos;
      state.photosOld = defaultPhotosOld;
    });
    builder.addCase(submitItem.fulfilled, (state, action) => {
      const itemId = action.payload.item.id;
      state.submitAPI.loading = false;
      state.submitAPI.isError = false;
      state.submitAPI.errorMsg = '';
      state.submitAPI.itemId = itemId;
      state.submitAPI.bundleId = action.payload.item.bundle.id;
      state.submitAPI.forcePhoto = !!action.payload.item.is_photo_mandatory;
      state.submitAPI.forcePhoneNumber = !!action.payload.item.forcePhoneField;
      state.submitAPI.displayOptionalPhoneLabel =
        !!action.payload.item.display_optional_phone_label;
      state.submitAPI.itemAutoRejected = !!action.payload.item.under_minimum_estimation;
      state.submitAPI.item = action.payload;
      state.submitAPI.err = action.payload.err;
      WorthyAPI.storeStateInBackend(state, state.registration, 'SubmitItemSuccess');
    });
    builder.addCase(submitItem.rejected, (state, action) => {
      state.submitAPI.loading = false;
      state.submitAPI.isError = true;
      state.submitAPI.errorMsg = 'SUBMIT_ITEM_FAILED';
      state.submitAPI.err = action.error;
      WorthyAPI.storeStateInBackend(state, state.registration, 'SubmitItemFailure');
    });
    builder.addCase(registerUser.pending, (state) => {
      state.registerAPI.loading = true;
      state.registerAPI.isError = false;
      state.registerAPI.errorMsg = '';
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.registerAPI.loading = false;
      state.registerAPI.isError = true;
      const errAlreadyTaken = JSON.stringify(action.payload || {}).includes('Already taken');
      state.registerAPI.errorMsg = errAlreadyTaken
        ? 'Email already taken. Please Log in instead'
        : 'We are experiencing some technical problems. please try again later.';
      state.registerAPI.err = action.error;
      if (!errAlreadyTaken)
        WorthyAPI.storeStateInBackend(state, state.registration, 'RegisterUserFailure');
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.registerAPI.userData = action.payload.user;
      WorthyAPI.storeStateInBackend(state, state.registration, 'RegisterUserSuccess');
      const userData = action.payload.user;
      state.user = {
        ...userData,
        userData: {
          id: userData?.id,
          email: userData?.email,
          firstName: userData?.first_name,
          lastName: userData?.last_name,
          phone: userData?.phone,
        },
        isLoggedIn: true,
      };
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      const userData = action.payload.user;
      const isLoggedIn = userData && (userData.type === 'user' ? userData.lead_id : userData.id);
      if (userData) {
        state.user = {
          ...userData,
          userData: {
            id: userData?.id,
            email: userData?.email,
            firstName: userData?.first_name,
            lastName: userData?.last_name,
            phone: userData?.phone,
          },
          isLoggedIn: !!isLoggedIn,
        };
      }
    });
    builder.addCase(updateUserPhoneNumber.pending, (state) => {
      state.registerAPI.loading = true;
      state.registerAPI.isError = false;
      state.registerAPI.errorMsg = '';
    });
    builder.addCase(updateUserPhoneNumber.rejected, (state) => {
      state.registerAPI.loading = false;
      state.registerAPI.isError = true;
      state.registerAPI.errorMsg =
        'We are experiencing some technical problems. please try again later.';
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateType,
  updateSetting,
  updateShape,
  updateCarat,
  updateColor,
  updateClarity,
  updateSecondarySubset,
  updateWizardStepName,
  updateStyle,
  updateMetalType,
  updateUser,
  updateDiamondNumber,
  updateTotalCarat,
  updateCertificate,
  updateBrand,
  updateRegistration,
  updateSubmitAPIItemId,
  updateSubmitAPILoading,
  updateSubmitAPIForcePhone,
  prevStep,
  nextStep,
  restartStep,
  updatePhotos,
  updatePhotosOld,
  onReceivedGoogleCredential,
  onRegisteredWithGoogle,
  updateItemAutoRejected,
} = submitSlice.actions;

export const selectSubmitData = (state: RootState) => state.submit;

export const selectItemInputs = (state: RootState): ItemInputsTypes => state.submit;
export const selectType = (state: RootState) => state.submit.type;
export const selectItemType = (state: RootState) => {
  return state.submit.type ? state.submit.type[0].toUpperCase() + state.submit.type.slice(1) : '';
};
export const selectWizard = (state: RootState) => state.submit.wizard;
export const selectStyle = (state: RootState) => state.submit.style;
export const selectSecondarySubset = (state: RootState) => state.submit.secondarySubset;
export const selectShape = (state: RootState) => state.submit.shape;
export const selectColor = (state: RootState) => state.submit.color;
export const selectClarity = (state: RootState) => state.submit.clarity;
export const selectBrand = (state: RootState) => state.submit.brand;
export const selectMetalType = (state: RootState) => state.submit.metalType;
export const selectCertificate = (state: RootState) => state.submit.certificate;
export const selectCertificateNumber = (state: RootState) => state.submit.certificateNumber;
export const selectRegisterAPI = (state: RootState) => state.submit.registerAPI;
export const selectSubmitAPI = (state: RootState) => state.submit.submitAPI;
export const selectUser = (state: RootState) => state.submit.user;
export const selectPhotos = (state: RootState) => state.submit.photos;
export const selectPhotosOld = (state: RootState) => state.submit.photosOld;
export const selectRegister = (state: RootState) => state.submit.registration;
export const selectSubmissionId = (state: RootState) => state.submit.submissionId;

export default submitSlice.reducer;
