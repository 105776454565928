import _ from 'lodash';
import type { IStep } from '../components/wizard';

export const visibleStep = (step: IStep, isLoggedIn: boolean, secondarySubset = '') =>
  !step.hidden &&
  !(isLoggedIn && step.skipOnlogin) &&
  (!step.secondarySubset || step.secondarySubset === secondarySubset);

export const nextVisibleStepIndex = (
  steps: any[],
  currentIndex: number,
  isLoggedIn: boolean,
  secondarySubset = '',
) =>
  steps.findIndex((step, i) => visibleStep(step, isLoggedIn, secondarySubset) && i > currentIndex);

export const prevVisibleStepIndex = (
  steps: any[],
  currentIndex: number,
  isLoggedIn: boolean,
  secondarySubset = '',
) =>
  _.findLastIndex(
    steps,
    (step, i) => visibleStep(step, isLoggedIn, secondarySubset) && i < currentIndex,
    currentIndex - 1,
  );

export const getSubmitIndex = (steps: IStep[], isLoggedIn: boolean, secondarySubset = '') => {
  let index = 0;
  if (_.isEmpty(steps)) {
    throw new Error('steps is empty');
  }
  for (let i = 0; i < steps.length; i += 1) {
    const step = steps[i];
    if (visibleStep(step, isLoggedIn, secondarySubset)) {
      if (step.itemRequired) {
        return index;
      }
      index = i;
    }
  }

  return index;
};

export const getVisibleSteps = (steps: IStep[], isLoggedIn: boolean, secondarySubset = '') => {
  return _.filter(steps, (step) => visibleStep(step, isLoggedIn, secondarySubset));
};

export const getProgressStepCount = (steps: IStep[], isLoggedIn: boolean, secondarySubset = '') =>
  getVisibleSteps(steps, isLoggedIn, secondarySubset).filter(
    (step) => !step.disappearFromProgressBar,
  ).length;

export const getProgressStepIndex = (visibleSteps: Array<IStep>, step: IStep) => {
  let index = 0;

  visibleSteps.find((s) => {
    if (!s.disappearFromProgressBar) index += 1;
    return s.name === step.name;
  });
  return index;
};
