import React, { useEffect, useState } from 'react';
import { Button, FileInput, Typography } from '@worthy-npm/worthy-common-ui-components';
import styled from '@emotion/styled/macro';

interface BtnProps {
  popupAdded: boolean;
  saveCallback: (e: React.ChangeEvent<HTMLInputElement>) => void;
  deleteCallback: () => void;
  text: { cancel: string; save: string };
  disabled: boolean;
}

const FileInputWrap = styled(FileInput)(({ disabled }: Record<string, any>) => ({
  padding: '8px 22px',
  width: '100%',
  justifyContent: 'center',
  color: '#fff',
  backgroundColor: disabled ? '#E5E5E5' : '#3A5FE3',
}));

const ButtonsContainer = function ({
  popupAdded,
  deleteCallback,
  saveCallback,
  text,
  disabled,
}: BtnProps) {
  const [close, setClose] = useState(popupAdded);
  useEffect(() => {
    setClose(popupAdded);
  }, [popupAdded]);
  return (
    <>
      {!close && (
        <Button
          variant="outlined"
          size="large"
          disableElevation
          sx={{ width: '100%' }}
          onClick={deleteCallback}
        >
          {text.cancel}
        </Button>
      )}
      <FileInputWrap
        data-automation="add-file-modal"
        disabled={disabled}
        aria-label="img"
        accept=".pdf,.jpeg,.png,.jpg"
        status="empty"
        onChange={(e) => {
          saveCallback(e);
        }}
        variant="filled"
        padding="7px 50px"
        color="paper"
      >
        <Typography variant="button" color="paper">
          {text.save}
        </Typography>
      </FileInputWrap>
    </>
  );
};

export default ButtonsContainer;
