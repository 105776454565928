import React from 'react';
import styled from '@emotion/styled/macro';
import { IsMobileProp } from '../../styles/commonPropType';
import { RunningText } from '../../styles/commonText';
import GA from '../../data/GA';

export interface StyleI {
  text: string;
  img: string;
  code: string;
  secondarySubset: string;
}

export interface OptionalStepPropsI {
  items?: any;
}

export interface StepProps {
  idx: number;
  stepName: string;
  title?: string;
  stepCaption: string;
  next: React.MouseEventHandler<HTMLElement>;
  prev: React.MouseEventHandler<HTMLElement>;
  props?: OptionalStepPropsI;
}

export const ErrorMessage = styled(RunningText)`
  color: red;
  margin: 10px;
`;

export const InputItem = styled.div<IsMobileProp>`
  max-width: ${(props: any) => (props.isMobile ? 350 : 280)}px;
  width: 100%;
  height: 65px;
  & input,
  label {
    font: 16px 'Roboto' !important;
    font-weight: 300 !important;
  }
  & input,
  input:hover {
    border: 1px solid #2d5dec;
    border-radius: 4px;
  }
  & input:focus {
    border: 2px solid #2d5dec;
    border-radius: 4px;
  }
  &:focus-within label {
    color: #2d5dec !important;
  }
`;

export const sendFocusEvent = (fieldName: string, idx: number, stepName: string) => {
  GA.focus(stepName, idx, fieldName);
};
