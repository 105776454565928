import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import {
  StepTitleText,
  Icon,
  Step,
  StepCTAContainer,
  ResponsiveSquareTile,
  ResponsiveTileContainer,
} from '../../styles/commonText';
import { useMobileVersion, useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectItemType, selectSubmitData, updateSetting } from '../../slices/submitSlice';
import { Styles } from '../../data/ItemDetails';
import { StepProps } from './common';
import CTAContainer from '../CTAContainer';
import { IsMobileProp } from '../../styles/commonPropType';
import ListComponent from '../listComponent/list';
import GA from '../../data/GA';

const SetSolitaire = 'images/icons/set_solitaire.svg';
const SetBridal = 'images/icons/set_bridal_set.svg';
const SetThreeStone = 'images/icons/set_3_stone.svg';
const SetHalo = 'images/icons/set_halo.svg';
const SetOther = 'images/icons/set_other.svg';

const MSetSolitaire = 'images/icons/jewelery/ring-settings/ic_soliteire.svg';
const MSetBridal = 'images/icons/jewelery/ring-settings/ic_bridal_set.svg';
const MSetThreeStone = 'images/icons/jewelery/ring-settings/ic_stones.svg';
const MSetHalo = 'images/icons/jewelery/ring-settings/ic_halo.svg';
const MSetOther = 'images/icons/jewelery/ring-settings/ic_other.svg';

const SetSolitaireSelected = 'images/icons/focus/set_solitaire.svg';
const SetBridalSelected = 'images/icons/focus/set_bridal_set.svg';
const SetThreeStoneSelected = 'images/icons/focus/set_3_stone.svg';
const SetHaloSelected = 'images/icons/focus/set_halo.svg';
const SetOtherSelected = 'images/icons/focus/set_other.svg';

const Settings = [
  {
    code: Styles.Solitaire,
    icon: SetSolitaire,
    iconM: MSetSolitaire,
    iconSelected: SetSolitaireSelected,
  },
  {
    code: Styles.BridalSet,
    icon: SetBridal,
    iconM: MSetBridal,
    iconSelected: SetBridalSelected,
  },
  {
    code: Styles.ThreeStone,
    icon: SetThreeStone,
    iconM: MSetThreeStone,
    iconSelected: SetThreeStoneSelected,
  },
  {
    code: Styles.Halo,
    icon: SetHalo,
    iconM: MSetHalo,
    iconSelected: SetHaloSelected,
  },
  {
    code: Styles.Other,
    icon: SetOther,
    iconM: MSetOther,
    iconSelected: SetOtherSelected,
  },
];

const TileContainer = styled(ResponsiveTileContainer)<IsMobileProp>`
  ${(props: any) =>
    props.isMobile &&
    css`
      max-width: 370px;
      flex-wrap: wrap;
    `}
`;

const Tile = styled(ResponsiveSquareTile)`
  max-width: 110px;
  & img {
    transform: scale(1.5);
  }
  ${(props: any) =>
    props.isMobile &&
    css`
      width: 29%;
    `}
`;

function StepSettings({ next, idx, stepName, prev }: StepProps) {
  const { setting } = useAppSelector(selectSubmitData);
  const dispatch = useAppDispatch();
  const isMobile = useMobileVersion();
  const itemType = useAppSelector(selectItemType);

  return (
    <StepCTAContainer isMobile={isMobile} data-automation="setting-step">
      <Step>
        <StepTitleText isMobile={isMobile}>What is your ring setting like?</StepTitleText>
        {isMobile ? (
          <ListComponent
            next={next}
            idx={idx}
            stepName={stepName}
            itemsConfig={Settings}
            selector={setting}
            updateFn={updateSetting}
          />
        ) : (
          <TileContainer isMobile={isMobile}>
            {Settings.map((s: Icon) => (
              <Tile
                key={s.code}
                data-automation={`setting-item-select-${s.code}`}
                selected={s.code === setting}
                isMobile={isMobile}
                icon={s.icon}
                iconSelected={s.iconSelected}
                text={s.code}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  GA.select(itemType, stepName, idx, s.code);
                  dispatch(updateSetting(s.code));
                  next(e);
                }}
              />
            ))}
          </TileContainer>
        )}
      </Step>
      <CTAContainer idx={idx} prev={prev} />
    </StepCTAContainer>
  );
}

export default StepSettings;
