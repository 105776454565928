/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { Button, TextField, Box } from '@worthy-npm/worthy-common-ui-components';
import { IMask } from 'react-imask';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled/macro';
import { useAppSelector } from '../../../app/hooks';
import { IRegistration, selectRegister, selectSubmitAPI } from '../../../slices/submitSlice';
import { CookieStore } from '../../../services/cookieStore';
import { RunningText } from '../../../styles/commonText';
import { sendFocusEvent } from '../common';

export const ErrorMessage = styled(RunningText)`
  color: red;
  margin-bottom: 20px;
  text-align: center;
  display: inherit;
`;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required('Required field')
    .matches(
      /^[aA-zZ]+[aA-zZ_.,'‘’“”" -]*$/,
      "Allowed characters: Alphabet, space and . ” , '  _ -",
    )
    .min(2, 'Minimum 2 characters')
    .max(64, 'Maximum 64 characters'),
  lastName: yup
    .string()
    .required('Required field')
    .matches(
      /^[aA-zZ]+[aA-zZ_.,'‘’“”" -]*$/,
      "Allowed characters: Alphabet, space and . ” , '  _ -",
    )
    .min(2, 'Minimum 2 characters')
    .max(64, 'Maximum 64 characters'),
  email: yup
    .string()
    .required('Required field')
    .email('Enter a valid email (you@domain.com)')
    .max(64, 'Maximum 64 characters'),
  phoneNumber: yup.string().when('$forcePhoneNumber', {
    is: true,
    then: (localSchema) =>
      localSchema
        .required('Required field')
        .max(14, 'Should be a valid phone number. e.g. (423) 456-7890')
        .min(14, 'Should be a valid phone number. e.g. (423) 456-7890'),
    otherwise: (localSchema) =>
      localSchema
        .notRequired()
        .max(14, 'Should be a valid phone number. e.g. (423) 456-7890')
        .min(14, 'Should be a valid phone number. e.g. (423) 456-7890')
        .nullable()
        .transform((value) => value || null),
  }),
});

const isEmailErrorMsg = (msg: string): boolean => {
  return msg === 'Email already taken. Please Log in instead';
};

const normalizePhoneNumber = (value: string, mask = true) => {
  const masked = IMask.createMask({
    mask: '(#00) 000-0000',
    definitions: {
      '#': /[2-9]/,
    },
  });
  masked.resolve(value);
  return mask ? masked.value : masked.unmaskedValue;
};

interface IStepRegistrationFormProps {
  onSubmit: (data: IRegistration) => void;
  verificationView: boolean;
  apiErrorMsg: string;
  idx: number;
  stepName: string;
}

function StepRegistrationForm(props: IStepRegistrationFormProps) {
  const { verificationView, apiErrorMsg, idx, stepName } = props;
  const registration = useAppSelector(selectRegister);
  const { forcePhoneNumber } = useAppSelector(selectSubmitAPI);
  const isMandatoryPhoneLabelExperiment = CookieStore.getMandatoryPhoneLabel();

  const {
    setError,
    watch,
    reset,
    trigger,
    getFieldState,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { firstName: '', lastName: '', email: '', phoneNumber: '' },
    mode: 'onBlur',
    resolver: yupResolver(schema),
    context: {
      forcePhoneNumber: forcePhoneNumber || isMandatoryPhoneLabelExperiment,
    },
  });

  useEffect(() => {
    reset({
      firstName: registration.firstName,
      lastName: registration.lastName,
      email: registration.email,
    });

    if (isEmailErrorMsg(apiErrorMsg)) {
      setError('email', { message: apiErrorMsg });
    }
  }, [registration, apiErrorMsg]);

  const onSubmitForm = (data: IRegistration) => {
    const { onSubmit } = props;
    onSubmit(data);
  };

  const onFocus = (fieldName: string) => {
    sendFocusEvent(fieldName, idx, stepName);
  };

  const mandatoryFields: {
    key: 'firstName' | 'lastName' | 'email' | 'phoneNumber';
    label: string;
    inputMode: 'text' | 'email' | 'tel';
  }[] = [
    { key: 'firstName', label: 'First Name', inputMode: 'text' },
    { key: 'lastName', label: 'Last Name', inputMode: 'text' },
    { key: 'email', label: 'Email', inputMode: 'email' },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
      {mandatoryFields.map((field) => (
        <TextField
          key={field.key}
          style={{ minHeight: '98px' }}
          {...register(field.key, {
            onChange: () => {
              const { invalid, isTouched } = getFieldState(field.key);
              if (invalid || isTouched) {
                trigger(field.key);
              }
            },
          })}
          onFocus={() => onFocus(field.key)}
          fullWidth
          disabled={verificationView}
          label={field.label}
          name={field.key}
          required
          data-automation={`input-field-${field.key}`}
          InputLabelProps={watch(field.key) ? { shrink: true } : {}}
          inputProps={{
            inputMode: field.inputMode,
            type: field.inputMode,
          }}
          error={!!errors[field.key]}
          helperText={errors?.[field.key]?.message}
          placeholder={field.label}
        />
      ))}

      <TextField
        {...register('phoneNumber', {
          onChange: (e) => {
            e.target.value = normalizePhoneNumber(e.target.value);
            setValue('phoneNumber', normalizePhoneNumber(e.target.value));
            const { invalid, isTouched } = getFieldState('phoneNumber');
            if (invalid || isTouched) {
              trigger('phoneNumber');
            }
          },
        })}
        onFocus={() => onFocus('phoneNumber')}
        autoFocus={verificationView}
        style={{ minHeight: '98px' }}
        fullWidth
        label="Phone Number"
        name="phoneNumber"
        required={forcePhoneNumber || isMandatoryPhoneLabelExperiment}
        data-automation="input-field-phoneNumber"
        inputProps={{
          inputMode: 'tel',
          type: 'tel',
        }}
        error={!!errors.phoneNumber}
        helperText={errors?.phoneNumber?.message}
        placeholder="(423) 456-7890"
      />

      {apiErrorMsg && !isEmailErrorMsg(apiErrorMsg) && <ErrorMessage>{apiErrorMsg}</ErrorMessage>}

      <Box>
        {verificationView ? (
          <Button
            disableElevation
            fullWidth
            type="submit"
            data-automation="verification-button"
            variant="contained"
            size="large"
          >
            Continue
          </Button>
        ) : (
          <Button
            disableElevation
            fullWidth
            type="submit"
            data-automation="registration-button"
            variant="contained"
            size="large"
          >
            Sign Up
          </Button>
        )}
      </Box>
    </form>
  );
}

export default StepRegistrationForm;
