import React from 'react';
import styled from '@emotion/styled/macro';
import { CloseIcon, SpacingSizes } from '../../clarity';
import { useMobileVersion } from '../../app/hooks';
import { IsMobileProp } from '../../styles/commonPropType';

export const PopupWrapStyle = styled.div<IsMobileProp>`
  position: fixed;
  width: ${(props: any) => (props.isMobile ? '100%' : '436px')};
  height: ${(props: any) => (props.isMobile ? '100%' : '')};
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 3;
  background: #fff;
  padding: ${SpacingSizes.smd} ${SpacingSizes.lg};
  box-shadow: 0 4px ${SpacingSizes.md} rgba(0, 0, 0, 0.15);
  text-align: center;
`;

export const PopupOverlay = styled.i`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 2;
  cursor: pointer;
`;

export function PopupWrap({ children }: any) {
  const isMobile = useMobileVersion();

  return <PopupWrapStyle isMobile={isMobile}>{children}</PopupWrapStyle>;
}

export function PopupCloseIcon(props: any) {
  return (
    <CloseIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      overrideCSS={{ position: 'absolute', top: '18px', right: '18px' }}
    />
  );
}
