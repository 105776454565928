import React from 'react';
import '../../styles/reset.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/400.css';
import '@fontsource/playfair-display/700.css';
import styled from '@emotion/styled/macro';
import {
  AppBar,
  Toolbar,
  Container,
  IconButton,
  Box,
  Icon,
  Logo,
  Button,
  Typography,
} from '@worthy-npm/worthy-common-ui-components';
import { useAppSelector } from '../../app/hooks';
import { selectWizard } from '../../slices/submitSlice';
import GA from '../../data/GA';
import { directToItemTypePage } from '../../lib/commonUtils';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  background-color: #f6f8ff;
  height: inherit;
  overflow: hidden;
`;

const DummyCol = styled.div`
  width: 32px;
  height: 32px;
`;

function CommonLayout({
  children,
  itemType,
  hideCloseBtn = false,
}: {
  children: React.ReactNode;
  itemType: string;
  hideCloseBtn?: boolean;
}) {
  const { stepIndex, stepName } = useAppSelector(selectWizard);

  return (
    <Main>
      <AppBar elevation={0} position="sticky" color="transparent">
        <Container maxWidth={false}>
          <Toolbar variant="regular" disableGutters={false}>
            <Box
              display="grid"
              gridTemplateColumns="70px auto 70px"
              alignItems="center"
              justifyItems="stretch"
              width="100%"
              height="76px"
            >
              {hideCloseBtn ? (
                <DummyCol />
              ) : (
                <IconButton
                  data-automation="home-button"
                  onClick={directToItemTypePage}
                  variant="outlined"
                  size="small"
                  color="secondary"
                  style={{ width: '32px', height: '32px' }}
                >
                  <Icon.CloseIcon style={{ width: '16px', height: '16px' }} />
                </IconButton>
              )}

              <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                <Logo />
              </Box>

              <Button
                id="intercom-launcher"
                data-automation="brand-skip-button"
                variant="filled"
                size="small"
                style={{ display: 'none' }}
                onClick={() => {
                  GA.intercomClick(itemType, stepName, stepIndex);
                }}
                endIcon={
                  <Box display="flex" justifyContent="center" alignContent="center">
                    <Icon.HelpIcon />
                  </Box>
                }
              >
                <Typography variant="body1" color="primary" fontWeight="500" fontSize="12px">
                  Help
                </Typography>
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </Main>
  );
}

CommonLayout.defaultProps = {
  hideCloseBtn: false,
};

export default CommonLayout;
