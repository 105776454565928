import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Icon, Item, List, Typography } from '@worthy-npm/worthy-common-ui-components';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { StepGridContainer } from '../../styles/common';
import { parametrize } from '../../lib/commonUtils';
import GA from '../../data/GA';
import { selectItemType } from '../../slices/submitSlice';

export interface ListProps {
  idx: number;
  stepName: string;
  next: React.MouseEventHandler<HTMLElement>;
  itemsConfig: { [k: string]: string }[];
  selector: string;
  updateFn: (payload: string) => any;
}

export default function ListComponent({
  stepName,
  idx,
  next,
  itemsConfig,
  selector,
  updateFn,
}: ListProps) {
  const dispatch = useAppDispatch();
  const step = _.toLower(stepName.split('Step')[1]);
  const itemType = useAppSelector(selectItemType);
  const [busy, setBusy] = useState(false);

  return (
    <StepGridContainer>
      <List spacing={2}>
        {itemsConfig.map((item) => (
          <Item
            data-automation={`${step}-item-select-${parametrize(item.code)}`}
            key={item.code}
            variant="outlined"
            selected={item.code === selector}
            onClick={(e) => {
              if (busy) return;
              setBusy(true);
              GA.select(itemType, stepName, idx, item.code);
              dispatch(updateFn(item.code));
              _.delay(() => {
                next(e);
                setTimeout(() => {
                  setBusy(false);
                }, 300);
              }, 600);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Box display="flex" flexDirection="row" alignItems="center">
                <img src={item.iconM} alt={item.code} height="28px" />
                <Typography
                  variant="subtitle1"
                  color="text.primary"
                  paddingLeft="12px"
                  textTransform="capitalize"
                >
                  {item.code}
                </Typography>
              </Box>
              <Box>{item.code === selector ? <Icon.GreenCheck display="block" /> : null}</Box>
            </Box>
          </Item>
        ))}
      </List>
    </StepGridContainer>
  );
}
