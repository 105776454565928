/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { LinearProgress, Box, mainTheme } from '@worthy-npm/worthy-common-ui-components';
import { useMobileVersion, useAppDispatch, useAppSelector } from '../app/hooks';
import {
  nextStep,
  prevStep,
  restartStep,
  selectSubmitData,
  selectSecondarySubset,
  selectSubmitAPI,
  selectUser,
  selectWizard,
  submitItem,
  selectItemType,
  updateSubmitAPILoading,
  updateItemAutoRejected,
} from '../slices/submitSlice';
import StepLoading from './steps/step_loading';
import ProgressBar from './progrssBar/progressBar';
import {
  getProgressStepCount,
  getSubmitIndex,
  getVisibleSteps,
  getProgressStepIndex,
} from '../lib/wizard';
import { StepProps } from './steps/common';
import { IsMobileProp } from '../styles/commonPropType';
import GA from '../data/GA';
import { NewFlowsTypes } from './submission/common';
import RetryDialog from './retryDialog';
import ItemRejection from './itemRejection';
import { isItemRejected } from '../validation/validations';

const BackButtonIcon = 'images/icons/back_button.svg';

export interface IStep {
  comp: React.FC<StepProps>;
  name: string;
  caption: string;
  title?: string;
  hidden?: boolean;
  itemRequired?: boolean;
  skipOnlogin?: boolean;
  secondarySubset?: string;
  key?: string;
  props?: object;
  disableProgressBar?: boolean; // disable progress bar for this step
  disappearFromProgressBar?: boolean; // exclude from progressBar steps calculation
  disableBackButton?: boolean; // disable going back
}

interface WizardProps {
  steps: IStep[];
}

const WizardContainer = styled.div<IsMobileProp>`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: ${(props: any) => (props.isMobile ? 0 : 70)}px;
  overflow: hidden;
`;

const CommonWizardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CommonContentContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const ContentContainer = styled.div`
  height: 100%;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
`;
const ProgressContainer = styled.div<IsMobileProp>`
  height: ${(props: any) => (props.isMobile ? 0 : 70)}px;
  position: fixed;
  bottom: 0px;
  width: 100%;
`;

const BackButton = styled.button`
  width: 20px;
  height: 40px;
  cursor: pointer;
  border: none;
  background: url(${BackButtonIcon}) 50% 50% no-repeat;
  background-size: contain;
  margin-left: 0;
  position: absolute;
  left: 30px;
  top: 100px;
  z-index: 5;
`;

const StepContainer = styled.div`
  max-width: 100%;
  position: relative;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: row-reverse;
`;

const StepStyle = styled.div<{ active: boolean; left: boolean }>`
  position: absolute;
  justify-content: center;
  transition: opacity 2s, left 1.5s;
  opacity: ${(props: any) => (props.active ? 1 : 0)};
  left: ${(props: any) => (props.active ? '0' : props.left ? '200%' : '-200%')};
  z-index: ${(props: any) => (props.active ? 1 : 0)};
  overflow: ${(props: any) => (props.active ? 'auto' : 'hidden')};
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
`;

function Step({ steps, idx, next, prev, props }: any) {
  const { stepIndex } = useAppSelector(selectWizard);
  const step = steps[idx];
  const WrappedComp = step.comp;

  return (
    <StepStyle active={stepIndex === idx} left={stepIndex < idx}>
      <WrappedComp
        idx={idx}
        stepName={step.name}
        title={step.title}
        next={next}
        prev={prev}
        props={props}
        stepCaption={step.caption}
        experiments={{ list: step.list }}
      />
    </StepStyle>
  );
}

function Wizard({ steps }: WizardProps) {
  if (_.isEmpty(steps)) throw new Error('At least one step is required for this component');

  const dispatch = useAppDispatch();
  const { stepIndex } = useAppSelector(selectWizard);
  const { loading } = useAppSelector(selectSubmitAPI);
  const { isLoggedIn } = useAppSelector(selectUser);
  const { type } = useAppSelector(selectSubmitData);
  const itemType = useAppSelector(selectItemType);
  const secondarySubset = useAppSelector(selectSecondarySubset);
  const isMobile = useMobileVersion();
  const visibleSteps = getVisibleSteps(steps, !!isLoggedIn, secondarySubset);
  const progressStepCount = getProgressStepCount(steps, !!isLoggedIn, secondarySubset);
  const [filteredStepIndex, setFilteredStepIndex] = useState(stepIndex);
  const [showRetryDialog, setShowRetryDialog] = useState(false);
  const [estimationFailed, setEstimationFailed] = useState(false);
  const { itemAutoRejected } = useAppSelector(selectSubmitAPI);

  const stepsWithoutComp: Omit<IStep, 'comp'>[] = steps.map((step) => ({
    ...step,
    comp: null,
  }));
  const submitIndex = getSubmitIndex(steps, !!isLoggedIn, secondarySubset);

  useEffect(() => {
    setFilteredStepIndex(visibleSteps.findIndex((step) => step.name === steps[stepIndex].name));
    GA.stepView(steps[stepIndex].name, stepIndex);
  }, [stepIndex]);

  const next = async () => {
    let isFailed;
    if (stepIndex === submitIndex) {
      if (itemAutoRejected) return { success: false };

      const status = await dispatch(submitItem() as any);

      if (status.meta.requestStatus === 'rejected' && itemType !== 'Ring') {
        setShowRetryDialog(true);
        return { success: false };
      }
      setShowRetryDialog(false);
      const { rejected, rejectReason } = isItemRejected(status.payload.item);

      isFailed = rejected || itemAutoRejected;

      dispatch(updateItemAutoRejected({ rejected: isFailed, reason: rejectReason }));
      dispatch(updateSubmitAPILoading(true));
    }

    isFailed = (steps[stepIndex].name.includes('Registration') && itemAutoRejected) || isFailed;

    if (isFailed) {
      setEstimationFailed(true);
    } else {
      dispatch(nextStep(stepsWithoutComp));
    }

    // To prevent flickering on last step set SubmitAPILoading flag to true
    // and switch after execute Next and 0.5 sec for animation
    if (stepIndex === submitIndex) {
      _.delay(() => {
        dispatch(updateSubmitAPILoading(false));
      }, 500);
    }

    return { success: true };
  };

  const prev = async () => {
    GA.previousClick(itemType, steps[stepIndex].name, stepIndex);
    dispatch(prevStep(stepsWithoutComp));
  };

  const restart = () => {
    dispatch(restartStep(stepsWithoutComp));
    setShowRetryDialog(false);
  };

  return type === '' || NewFlowsTypes.includes(type) ? (
    <CommonWizardContainer>
      {!steps[stepIndex].disableProgressBar && !showRetryDialog && !estimationFailed && (
        <Box top="-6px" position="relative">
          <LinearProgress
            data-automation="progress-bar"
            value={getProgressStepIndex(visibleSteps, steps[stepIndex])}
            max={progressStepCount}
            sx={{ padding: '6px 0;' }}
          />
        </Box>
      )}
      <CommonContentContainer>
        <StepContainer>
          {loading && <StepLoading color={mainTheme.palette.background.default} />}
          {estimationFailed && <ItemRejection />}
          {!estimationFailed &&
            steps.map((step, i) => (
              <Step
                steps={steps}
                key={step.name}
                next={next}
                prev={!steps[stepIndex].disableBackButton && prev}
                idx={i}
                props={step.props}
              />
            ))}
        </StepContainer>
      </CommonContentContainer>
      {showRetryDialog && <RetryDialog retryHook={next} restartHook={restart} />}
    </CommonWizardContainer>
  ) : (
    <WizardContainer isMobile={isMobile}>
      <ContentContainer>
        {!steps[stepIndex].disableBackButton && !isMobile && !loading && stepIndex > 0 && (
          <BackButton data-automation={`wizard-prev-button-${stepIndex}`} onClick={prev} />
        )}
        <StepContainer>
          {loading && <StepLoading />}
          {estimationFailed && <ItemRejection />}
          {steps.map((step, i) => (
            <Step
              steps={steps}
              key={step.name}
              next={next}
              prev={!steps[stepIndex].disableBackButton && prev}
              idx={i}
            />
          ))}
        </StepContainer>
      </ContentContainer>
      <ProgressContainer isMobile={isMobile}>
        {isMobile || steps[stepIndex].disableProgressBar ? null : (
          <ProgressBar
            indx={filteredStepIndex}
            count={progressStepCount}
            caption={steps[stepIndex].caption}
          />
        )}
      </ProgressContainer>
    </WizardContainer>
  );
}

export default Wizard;
