import React from 'react';
import styled from '@emotion/styled/macro';
import {
  Box,
  FadeModal,
  Icon,
  IconButton,
  Typography,
} from '@worthy-npm/worthy-common-ui-components';

interface IModal {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  min-width: 870px;
  min-height: 650px;
`;

export default function ModalTemplate({ title, isOpen, onClose, children }: IModal) {
  return (
    <FadeModal open={isOpen} onClose={onClose} data-automation="modal-component">
      <Box margin="24px">
        <Header>
          <Typography variant="subtitle1" color="secondary.contrastText">
            {title}
          </Typography>
          <IconButton
            onClick={onClose}
            variant="outlined"
            size="small"
            color="secondary"
            style={{ width: '32px', height: '32px' }}
          >
            <Icon.CloseIcon data-automation="close" style={{ width: '16px', height: '16px' }} />
          </IconButton>
        </Header>

        <Content>{children}</Content>
      </Box>
    </FadeModal>
  );
}
