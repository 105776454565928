import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import {
  BlueWordButton,
  StepTitleText,
  Step,
  NextStepButton,
  StepCTAContainer,
  ResponsiveSquareTile,
  ResponsiveTileContainer,
} from '../../styles/commonText';
import { MediumLinkButton } from '../../clarity';

import { DiamondColor } from '../../data/ItemDetails';
import CenterDiamond from '../popup/center_diamond';
import { useMobileVersion, useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectItemType, selectSubmitData, updateColor } from '../../slices/submitSlice';
import { StepProps } from './common';
import CTAContainer from '../CTAContainer';
import { IsMobileProp } from '../../styles/commonPropType';
import GA from '../../data/GA';

const Tile = styled(ResponsiveSquareTile)`
  max-width: 66px;
  max-height: 66px;
  ${(props: any) =>
    props.isMobile &&
    css`
      width: 20%;
    `}
`;

const TileContainer = styled(ResponsiveTileContainer)<IsMobileProp>`
  gap: 12px;
  max-width: 500px;
  flex-wrap: wrap;
  ${(props: any) =>
    props.isMobile &&
    css`
      max-width: 370px;
    `}
`;

interface IconColor {
  code: string;
  caption: string;
}

const Colors = [
  {
    code: DiamondColor.D,
    caption: 'D',
  },
  {
    code: DiamondColor.E,
    caption: 'E',
  },
  {
    code: DiamondColor.F,
    caption: 'F',
  },
  {
    code: DiamondColor.G,
    caption: 'G',
  },
  {
    code: DiamondColor.H,
    caption: 'H',
  },
  {
    code: DiamondColor.I,
    caption: 'I',
  },
  {
    code: DiamondColor.J,
    caption: 'J',
  },
  {
    code: DiamondColor.K,
    caption: 'K',
  },
  {
    code: DiamondColor.L,
    caption: 'L',
  },
  {
    code: DiamondColor.MtoZ,
    caption: 'M-Z',
  },
  {
    code: DiamondColor.Fancy,
    caption: 'Fancy',
  },
];

function StepColor({ next, idx, stepName, prev }: StepProps) {
  const [popupVisible, showPopup] = useState<boolean>(false);
  const { color } = useAppSelector(selectSubmitData);
  const itemType = useAppSelector(selectItemType);
  const dispatch = useAppDispatch();
  const isMobile = useMobileVersion();

  return (
    <StepCTAContainer isMobile={isMobile}>
      <Step>
        <StepTitleText>
          <span>Ok, now select your&nbsp;</span>
          <BlueWordButton
            data-automation="color-center-diamond-button"
            onClick={() => showPopup(!popupVisible)}
          >
            center diamond
          </BlueWordButton>
          <span>’s color grade below.</span>
        </StepTitleText>
        <TileContainer isMobile={isMobile}>
          {Colors.map((c: IconColor) => (
            <Tile
              isMobile={isMobile}
              data-automation={`color-item-select-${c.code}`}
              selected={c.code === color}
              key={c.code}
              text={c.caption}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                dispatch(updateColor(c.code));
                GA.select(itemType, stepName, idx, c.code);
                next(e);
              }}
            />
          ))}
        </TileContainer>
        {popupVisible && <CenterDiamond setPopupVisible={showPopup} />}
      </Step>
      <CTAContainer idx={idx} prev={prev}>
        <NextStepButton onClick={next} data-automation="wizard-next-button">
          <MediumLinkButton
            onClick={() => {
              dispatch(updateColor(DiamondColor.NotSure));
              GA.colorNotSure(stepName, idx);
            }}
            text={"I'm not sure"}
          />
        </NextStepButton>
      </CTAContainer>
    </StepCTAContainer>
  );
}

export default StepColor;
