import React, { useState } from 'react';
import {
  Grid,
  GridItem,
  Button,
  Typography,
  Box,
  Icon,
} from '@worthy-npm/worthy-common-ui-components';
import { StepGridContainer, Step, StepContainer, NextStepButton } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectBrand, selectItemType, updateBrand } from '../../../slices/submitSlice';
import { JewelryBrand } from '../../../data/ItemDetails';
import { StepProps } from '../common';
import { parametrize } from '../../../lib/commonUtils';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';

const Items = [
  {
    img: '/images/icons/brands/tiffany.png',
    code: JewelryBrand.Tiffany,
  },
  {
    img: '/images/icons/brands/cartier.png',
    code: JewelryBrand.Cartier,
  },
  {
    img: '/images/icons/brands/van_cleef.png',
    code: JewelryBrand.VanCleefArpens,
  },
  {
    img: '/images/icons/brands/harry_winston.png',
    code: JewelryBrand.HarryWinston,
  },
  {
    img: '/images/icons/brands/graff.png',
    code: JewelryBrand.Graff,
  },
  {
    img: '/images/icons/brands/bvlgari.png',
    code: JewelryBrand.Bvlgari,
  },
  {
    img: '/images/icons/brands/chopard.png',
    code: JewelryBrand.Chopard,
  },
  {
    text: JewelryBrand.Other,
    code: JewelryBrand.Other,
  },
];

function StepBrand({ next, idx, stepName, prev, title, stepCaption }: StepProps) {
  const dispatch = useAppDispatch();
  const brand = useAppSelector(selectBrand);
  const itemType = useAppSelector(selectItemType);
  const [busy, setBusy] = useState(false);

  return (
    <StepContainer data-automation="brand-step">
      <Step>
        <StepTitle
          idx={idx}
          prev={prev}
          stepTitle={title || `What brand is your ${itemType.toLowerCase()}s?`}
          stepName={stepName}
          stepCaption={stepCaption}
          stepNotice="Select an option below"
        />

        <StepGridContainer>
          <Grid display="grid" gridTemplateColumns="50% 50%">
            {Items.map((item) => (
              <GridItem
                data-automation={`brand-step-item-${parametrize(item.code)}`}
                key={item.code}
                style={{
                  margin: '4px',
                  backgroundColor: 'white',
                  height: '93px',
                  width: 'auto',
                  padding: 0,
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (busy) return;
                  setBusy(true);
                  GA.select(itemType, stepName, idx, item.code);
                  dispatch(updateBrand(item.code));
                  setTimeout(() => {
                    next(e);
                    setTimeout(() => {
                      setBusy(false);
                    }, 600);
                  }, 600);
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  height="100%"
                  position="relative"
                >
                  {item.img ? (
                    <img src={item.img} alt={item.text} width="100%" />
                  ) : (
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      paddingLeft="12px"
                      textTransform="capitalize"
                      flexGrow={1}
                      flexShrink={1}
                      textAlign="center"
                      padding={0}
                    >
                      {item.text}
                    </Typography>
                  )}

                  <Box
                    data-automation="brand-step-item-checked"
                    position="absolute"
                    bottom="5px"
                    right="5px"
                  >
                    {item.code === brand ? <Icon.GreenCheck display="block" /> : null}
                  </Box>
                </Box>
              </GridItem>
            ))}
          </Grid>

          <NextStepButton>
            <Button
              disableElevation
              data-automation="brand-skip-button"
              onClick={(e) => {
                GA.brandSkip(itemType, stepName, idx);
                next(e);
              }}
              variant="filled"
              size="large"
              endIcon={<Icon.ContinueIcon />}
            >
              Skip
            </Button>
          </NextStepButton>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepBrand;
