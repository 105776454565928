import React, { useState } from 'react';
import {
  GridItem,
  Typography,
  Grid,
  Box,
  Icon,
  Alert,
} from '@worthy-npm/worthy-common-ui-components';
import styled from '@emotion/styled/macro';
import { Step, StepContainer, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectClarity, selectItemType, updateClarity } from '../../../slices/submitSlice';
import { DiamondClarity } from '../../../data/ItemDetails';
import StepTitle from '../../stepTitle';
import { parametrize } from '../../../lib/commonUtils';
import GA from '../../../data/GA';
import { StepProps } from '../common';

const Items = Object.values(DiamondClarity);

const GridItemClarity = styled(GridItem)`
  margin: 4px;
  background-color: white;
  height: 90px;
  width: auto;
  padding: 14px 7px;
  cursor: pointer;
`;

function StepClarity({ next, idx, stepName, prev, title, stepCaption }: StepProps) {
  const dispatch = useAppDispatch();
  const clarity = useAppSelector(selectClarity);
  const itemType = useAppSelector(selectItemType);
  const [busy, setBusy] = useState(false);

  const handleClaritySelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: string) => {
    if (busy) return;

    setBusy(true);
    GA.select(itemType, stepName, idx, item);
    dispatch(updateClarity(item));
    setTimeout(() => {
      next(e);
      setTimeout(() => {
        setBusy(false);
      }, 600);
    }, 600);
  };

  return (
    <StepContainer data-automation="clarity-step">
      <Step>
        <StepTitle
          idx={idx}
          prev={prev}
          stepTitle={title || 'What clarity grade does your diamond have?'}
          stepName={stepName}
          stepNotice="Select an option below"
          stepCaption={stepCaption}
        />

        <StepGridContainer>
          <Grid display="grid" gridTemplateColumns="33.3% 33.3% 33.3%">
            {Items.map((item: string) => (
              <GridItemClarity
                data-automation={`clarity-step-item-${parametrize(item)}`}
                key={item}
                onClick={(e) => handleClaritySelect(e, item)}
              >
                <Box
                  display="flex"
                  padding="15px 25px"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  position="relative"
                >
                  <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                    <img
                      src={
                        item !== 'not sure'
                          ? `/images/icons/jewelery/diamond-clarity/${item}.svg`
                          : 'images/icons/other.svg'
                      }
                      alt={item}
                      height="28px"
                    />
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      textTransform={item === 'not sure' ? 'capitalize' : 'uppercase'}
                    >
                      {item}
                    </Typography>
                  </Box>
                  <Box data-automation="clarity-step-item-checked" position="absolute" right="1px">
                    {item === clarity ? <Icon.GreenCheck display="block" /> : null}
                  </Box>
                </Box>
              </GridItemClarity>
            ))}
          </Grid>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepClarity;
