import React from 'react';
import { Box, Icon, IconButton, Popup, Typography } from '@worthy-npm/worthy-common-ui-components';
import styled from '@emotion/styled/macro';

const PopupWrap = styled(Box)`
  padding: 24px 10px 16px 10px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
`;

interface IModal {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export default function PopupComponent({ title, isOpen, onClose, children }: IModal) {
  return (
    <Popup
      open={isOpen}
      anchor="bottom"
      PaperProps={{ sx: { display: 'flex' } }}
      variant="temporary"
      onClose={onClose}
      data-automation="modal"
    >
      <PopupWrap>
        <Header>
          <Typography variant="subtitle1" color="secondary.contrastText">
            {title}
          </Typography>
          <IconButton
            onClick={onClose}
            variant="outlined"
            size="small"
            color="secondary"
            style={{ width: '32px', height: '32px' }}
          >
            <Icon.CloseIcon data-automation="close" style={{ width: '16px', height: '16px' }} />
          </IconButton>
        </Header>
        {children}
      </PopupWrap>
    </Popup>
  );
}
