export interface LinksObject {
  ringApp: string;
  submissionFlowRepositioning: string;
  ring: string;
  necklace: string;
  bracelet: string;
  looseDiamond: string;
  earrings: string;
  watch: string;
  register: string;
  jbAppPage: string;
}

export const links: LinksObject = {
  ringApp: `${process.env.REACT_APP_APP_URL}`,
  submissionFlowRepositioning: `${process.env.REACT_APP_SUBMISSION_URL}`,
  ring: '/ring',
  necklace: '/necklace',
  bracelet: `${process.env.REACT_APP_APP_URL}/submit/bracelet`,
  looseDiamond: '/loose_diamond',
  earrings: '/earrings',
  watch: `${process.env.REACT_APP_APP_URL}/submit/watch`,
  register: `${process.env.REACT_APP_APP_URL}/register`,
  jbAppPage: `${process.env.REACT_APP_APP_URL}/multiple_jewelry`,
};

export const NewFlowsTypes = ['earrings', 'necklace', 'loose diamond'];
