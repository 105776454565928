import { EmailInput, NameInput, PhoneInput } from '../../clarity';
import { IRegistration } from '../../slices/submitSlice';

export interface IUILabel {
  caption: string;
  component: any;
  isValid: boolean;
  value: string;
}

interface IStateInput {
  value: string;
  isValid: boolean;
}

export interface TLocalInputsState {
  [key: string]: IStateInput;
}

export const initialState: TLocalInputsState = {
  firstName: {
    value: '',
    isValid: true,
  },
  lastName: {
    value: '',
    isValid: true,
  },
  email: {
    value: '',
    isValid: true,
  },
  phoneNumber: {
    value: '',
    isValid: true,
  },
};

export interface IUserData {
  event: string;
  email: string;
  phone?: string;
}
