import React from 'react';
import styled from '@emotion/styled/macro';
import { StepContainer, StepTitleText } from '../../styles/commonText';
import Loading from '../loading';

const StepStyle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: ${(props) => props?.color};
`;

// eslint-disable-next-line react/require-default-props
function StepLoading({ color }: { color?: string }) {
  return (
    <StepStyle color={color || 'white'}>
      <StepContainer>
        <StepTitleText> </StepTitleText>
        <Loading />
      </StepContainer>
    </StepStyle>
  );
}
export default StepLoading;
