import React from 'react';
import styled from '@emotion/styled/macro';
import { GeneralColorNames, GENERAL_COLOR_VALUES } from '../../clarity';
import { useMobileVersion, useAppSelector } from '../../app/hooks';
import { selectWizard } from '../../slices/submitSlice';
import { directToItemTypePage } from '../../lib/commonUtils';

const Logo = 'images/worthy_logo_black.svg';
const StepIconInProgress = 'images/steps_icon_in_progress.svg';
const StepIconFinished = 'images/steps_icon_ready.svg';

const HeaderWrapper = styled.header<any>`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props: any) => (props.isMobile ? 10 : 30)}px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: ${(props: any) => (props.isMobile ? 40 : 50)}px;
    left: 0;
    right: 0;
    background-color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_100]};
    z-index: -1;
  }
`;

const FlowIcon = styled.img`
  top: 50%;
`;

const LogoLink = styled.div<any>`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: ${(props: any) => (props.isMobile ? 25 : 60)}px;
  margin-bottom: ${(props: any) => (props.isMobile ? 0 : 30)}px;
  width: 360px;
  cursor: pointer;
`;

function Header() {
  const { isFinal } = useAppSelector(selectWizard);
  const isMobile = useMobileVersion();
  const iconWidth = isMobile ? 90 : 110;

  return (
    <HeaderWrapper isMobile={isMobile}>
      <LogoLink isMobile={isMobile} onClick={directToItemTypePage}>
        <img alt="Worthy Logo" height="20" width="147" src={Logo} />
      </LogoLink>
      <FlowIcon
        width={iconWidth}
        src={isFinal ? StepIconFinished : StepIconInProgress}
        alt="Form in progress"
      />
    </HeaderWrapper>
  );
}

export default Header;
