import * as React from 'react';
import { Helmet } from 'react-helmet';
import { GoogleSigninButton } from '@worthy-npm/worthy-common-ui-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import WorthyAPI from '../services/worthyAPI';
import {
  onReceivedGoogleCredential,
  updateRegistration,
  updateUser,
  onRegisteredWithGoogle,
} from '../slices/submitSlice';
import { useAppDispatch } from '../app/hooks';
import { CookieStore } from '../services/cookieStore';
import GA from '../data/GA';

const makeGoogleCallback = (fSetErrMsg: any, onSuccess: any, setLoading: any, dispatch: any) => {
  return (response: any) => {
    const handleError = (error: any) => {
      if (error.status === 401 && error.data === 'User not found') {
        fSetErrMsg(
          <>
            Email doesn&apos;t exist in our system.{' '}
            <a href="/register" className="blue">
              Register
            </a>
          </>,
        );
      } else {
        fSetErrMsg('Something went wrong. Please try later.');
      }
    };
    // start processing
    if (setLoading) setLoading(true);
    dispatch(onReceivedGoogleCredential(response.credential));

    // sending email to GTM as soon as we can
    WorthyAPI.getUserDataFromGoogleCredential(response.credential).then((userData) => {
      GA.googleData({
        userEmail: userData?.email,
        userFirstName: userData?.firstName,
        userLastName: userData?.lastName,
        userPhone: userData?.phoneNumber,
      });
    });

    return WorthyAPI.registerWithGoogle(response.credential)
      .then(async (result) => {
        fSetErrMsg('');
        const userData = result.data;
        dispatch(
          updateRegistration({
            firstName: userData.firstName || userData?.user?.first_name || '',
            lastName: userData.lastName || userData?.user?.last_name || '',
            email: userData.email || userData?.user?.email || '',
            phoneNumber: '',
          }),
        );

        const userRespond = await WorthyAPI.ADO();
        dispatch(onRegisteredWithGoogle(result.data));
        if (userRespond.status === 200 && userRespond?.data?.user) {
          dispatch(updateUser(userRespond.data.user));
        }

        if (onSuccess) {
          onSuccess(userRespond?.data?.user, userData?.loginSuccessful, userData?.lead_id);
        }
      })
      .catch(handleError)
      .finally(() => {
        if (setLoading) setLoading(false);
      });
  };
};

function GetGoogleClientID() {
  return process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID || '';
}

export function GoogleSignInEnabled() {
  return GetGoogleClientID() !== '' && CookieStore.getGoogleSigninExp();
}

const GoggleLoginError = styled.div`
  color: red;
  margin-top: 5px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;

export function GoogleSignIn({
  onSuccess,
  setLoading,
  disabled = false,
  options = {},
}: {
  onSuccess: any;
  setLoading: any;
  disabled?: boolean | undefined;
  options?: any;
}) {
  const [errMsg, setErrMsg] = React.useState(null);
  const dispatch = useAppDispatch();
  // Don't render anything if the Google client id is not configured
  if (!GoogleSignInEnabled()) return <div />;

  return (
    <div
      data-automation="google-signin-button"
      style={disabled ? { pointerEvents: 'none', opacity: '0.7', padding: '1rem' } : {}}
    >
      <Helmet>
        <script src="https://accounts.google.com/gsi/client" async defer />
      </Helmet>
      <GoogleSigninButton
        callback={makeGoogleCallback(setErrMsg, onSuccess, setLoading, dispatch)}
        clientId={GetGoogleClientID()}
        options={{ ...{ width: '350' }, ...options }}
      />
      {errMsg && <GoggleLoginError>{errMsg}</GoggleLoginError>}
    </div>
  );
}

GoogleSignIn.propTypes = {
  disabled: PropTypes.bool,
};

GoogleSignIn.defaultProps = {
  disabled: false,
  options: {},
};

export default GoogleSignIn;
