import Compressor from 'compressorjs';
import { CookieStore } from '../services/cookieStore';
import { links } from '../components/submission/common';

export function isIframe() {
  return window.self !== window.top;
}

export const redirectTo = (url: string) => window.location.replace(url);

export const currentUrl = (): string => window.location.href;

export const redirectToNewItem = (itemId: string) => {
  redirectTo(`${process.env.REACT_APP_APP_URL}/item/${itemId}/skip_photo_upload`);
};

export const isMobile = () => process.env.REACT_APP_DEVTYPE === 'mobile';
export const isTablet = () => process.env.REACT_APP_DEVTYPE === 'tablet';
export const isDesktop = () => process.env.REACT_APP_DEVTYPE === 'desktop';

export const parametrize = (value: string) => {
  return value
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/\s/g, '-');
};

// this fuction should be called exactly when the session is needed , the cookie need few seconds to be set
export const getSessionData = () => CookieStore.getSessionData();

export const compress = (file: File | Blob): Promise<File | Blob> =>
  new Promise(
    (resolve, reject) =>
      new Compressor(file, {
        quality: 0.8,
        maxHeight: 4096,
        maxWidth: 4096,
        success: resolve,
        error: (error) => {
          console.info(`Error while compressing ${JSON.stringify(error)}`);
          resolve(file);
        },
      }),
  );

export const getItemType = () => {
  const path = window.location.pathname;
  if (path.startsWith('/ring')) return 'ring';
  if (path.startsWith('/earrings')) return 'earrings';
  if (path.startsWith('/necklace')) return 'necklace';
  if (path.startsWith('/loose_diamond')) return 'loose diamond';
  return '';
};

export const directToItemTypePage = () => {
  window.location.href = links.submissionFlowRepositioning;
};
