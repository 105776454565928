import React, { useState } from 'react';
import {
  Button,
  TextField,
  GridItem,
  Typography,
  Grid,
  Box,
  Icon,
} from '@worthy-npm/worthy-common-ui-components';

import styled from '@emotion/styled/macro';
import { NextStepButton, Step, StepContainer, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectItemType, updateCertificate } from '../../../slices/submitSlice';
import { StepProps } from '../common';
import { EarringsCertificateType } from '../../../data/ItemDetails';
import { parametrize } from '../../../lib/commonUtils';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';

const FormWrap = styled.div`
  overflow: hidden;
`;

interface IFormContainerProps {
  active: boolean;
}

const FormContent = styled.div<IFormContainerProps>`
  position: relative;
  height: ${(props) => (props.active ? '150px' : '0')};
  top: ${(props) => (props.active ? '0px' : '-150px')};
  transition: all 0.5s ease;
`;

const Certs = [
  {
    text: 'GIA',
    code: EarringsCertificateType.GIA,
    img: undefined,
    number: true,
  },
  {
    text: 'IGI',
    code: EarringsCertificateType.IGI,
    img: undefined,
    number: false,
  },
  {
    text: 'GSI',
    code: EarringsCertificateType.GSI,
    img: undefined,
    number: false,
  },
  {
    text: 'Other',
    code: EarringsCertificateType.Other,
    img: undefined,
    number: false,
  },
];

const Notice = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #999999;
  text-align: center;
  margin: 30px 0;
`;

function Certificate({ next, idx, stepName, prev, title, stepCaption }: StepProps) {
  const dispatch = useAppDispatch();
  const itemType = useAppSelector(selectItemType);

  const [kind, setKind] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [activeNext, setActiveNext] = useState<boolean>(false);
  const [activeNumber, setActiveNumber] = useState<boolean>(false);
  const [busy, setBusy] = useState(false);

  return (
    <StepContainer data-automation="certificate-step">
      <Step>
        <StepTitle
          stepCaption={stepCaption}
          idx={idx}
          prev={prev}
          stepTitle={title || 'What type of certificate do you have?'}
          stepName={stepName}
          stepNotice="Select an option below"
        />

        <StepGridContainer>
          <Grid display="grid" gridTemplateColumns="50% 50%">
            {Certs.map((item) => (
              <GridItem
                data-automation={`certificate-step-item-${parametrize(item.code)}`}
                key={item.code}
                style={{
                  margin: '4px',
                  backgroundColor: 'white',
                  height: '60px',
                  width: 'auto',
                  padding: '14px 7px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (item.code === kind) {
                    setKind('');
                    setActiveNext(false);
                    setActiveNumber(false);
                  } else {
                    setKind(item.code);
                    setActiveNext(true);
                    if (item.number) {
                      setActiveNumber(true);
                    } else {
                      setActiveNumber(false);
                    }
                  }
                }}
              >
                <Box
                  display="flex"
                  padding="15px 25px"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  position="relative"
                  data-testid={`certificate-step-item-${parametrize(item.code)}`}
                >
                  <Typography variant="subtitle1" color="text.primary">
                    {item.text}
                  </Typography>

                  <Box position="absolute" bottom="-6px" right="1px">
                    {item.code === kind ? <Icon.GreenCheck display="block" /> : null}
                  </Box>
                </Box>
              </GridItem>
            ))}
          </Grid>

          <FormWrap>
            <FormContent active={activeNumber} data-testid="number-input">
              <Notice>Enter the GIA report number (Optional)</Notice>
              <TextField
                fullWidth
                style={{ padding: '0 4px' }}
                inputProps={{ inputMode: 'numeric' }}
                data-automation="certificate-number-input"
                variant="outlined"
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
            </FormContent>
          </FormWrap>

          <NextStepButton>
            {activeNext ? (
              <Button
                disableElevation
                data-automation="certificate-next-button"
                data-testid="next-button"
                onClick={(e) => {
                  if (kind && !busy) {
                    setBusy(true);
                    GA.select(itemType, stepName, idx, kind);
                    dispatch(updateCertificate({ type: kind, number }));
                    next(e);
                    setTimeout(() => {
                      setBusy(false);
                    }, 150);
                  }
                }}
                variant="contained"
                size="large"
                endIcon={<Icon.ContinueIcon />}
              >
                Continue
              </Button>
            ) : (
              <Button
                data-automation="certificate-skip-button"
                data-testid="skip-button"
                onClick={(e) => {
                  GA.certificateSkip(itemType, stepName, idx);
                  dispatch(updateCertificate({ type: EarringsCertificateType.None, number: '' }));
                  next(e);
                }}
                variant="filled"
                size="large"
                endIcon={<Icon.ContinueIcon />}
              >
                Skip
              </Button>
            )}
          </NextStepButton>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default Certificate;
