import React, { useEffect } from 'react';
import { ThemeProvider, mainTheme } from '@worthy-npm/worthy-common-ui-components';
import CommonLayout from './layout/commonLayout';
import Wizard, { IStep } from '../components/wizard';
import { useAppDispatch } from '../app/hooks';
import { updateSecondarySubset, updateWizardStepName, updateType } from '../slices/submitSlice';
import StepShape from '../components/steps/common/step_shape';
import Weight from '../components/steps/common/step_weight';
import StepRegistration from '../components/steps/common/step_registration';
import StepPhotoUpload from '../components/steps/common/step_photoUpload';
import GA from '../data/GA';
import Certificate from '../components/steps/common/step_certificate';
import StepColor from '../components/steps/common/step_color';
import StepClarity from '../components/steps/common/step_clarity';
import { ItemTypes } from '../data/ItemDetails';

const createSteps = (): IStep[] => [
  {
    comp: StepShape,
    name: 'StepDiamondShape',
    caption: 'Diamond Shape',
    title: 'What is the shape of your diamond?',
    disableBackButton: true,
  },
  {
    comp: Weight,
    name: 'StepCaratWeight',
    caption: 'Carat Weight',
    title: 'Great! And what is the carat weight of your diamond?',
  },
  {
    comp: StepColor,
    name: 'StepColor',
    caption: 'Diamond Color',
    title: 'What is the color of your diamond?',
  },
  {
    comp: StepClarity,
    name: 'StepClarity',
    caption: 'Diamond Clarity',
    title: 'What is the clarity of your diamond?',
  },
  {
    comp: Certificate,
    name: 'StepCertificate',
    caption: 'Certificate',
    title: 'If you have a certificate for your diamond, what type do you have?',
  },
  {
    comp: StepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    itemRequired: true,
    skipOnlogin: true,
    disableBackButton: true,
  },
  {
    comp: StepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload photos',
    itemRequired: true,
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
  },
];

function LooseDiamondPage() {
  const dispatch = useAppDispatch();
  const steps = createSteps();

  dispatch(updateType(ItemTypes.LooseDiamond));

  useEffect(() => {
    // default subset for earring to prevent wrong calculation for count of steps
    dispatch(updateSecondarySubset('diamond_center_stone'));
    dispatch(updateWizardStepName(steps[0].name));
    GA.flow();

    const el = document.querySelector("meta[name='theme-color']");
    if (el) {
      el.setAttribute('content', '#F6F8FF');
    }
  }, [dispatch, steps]);

  return (
    <ThemeProvider theme={mainTheme}>
      <CommonLayout itemType={ItemTypes.LooseDiamond}>
        <Wizard steps={steps} />
      </CommonLayout>
    </ThemeProvider>
  );
}

export default LooseDiamondPage;
