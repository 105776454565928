import styled from '@emotion/styled/macro';

// eslint-disable-next-line import/prefer-default-export
export const SecondaryButton = styled.button`
  border: 1px solid #2d5dec;
  background: #ffffff;
  padding: 0.5em;
  border-radius: 3px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #2d5dec;
  cursor: pointer;
  padding: 11px 22px 12px;
  font: 500 16px Roboto;
  white-space: nowrap;
  text-decoration: none;
  height: 42px;
  min-width: 100px;
`;
