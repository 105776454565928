/* eslint-disable */

import styled from '@emotion/styled/macro';
import React, { DetailedHTMLProps, useEffect, useRef, useState } from 'react';

// NEED TO BE REMOVED

export enum GeneralColorNames {
  // Brand color palette
  worthy_gold = 'worthy_gold',
  worthy_black = 'worthy_black',
  worthy_white = 'worthy_white',

  // common colors
  black = 'black',

  // Action colors
  worthy_purple = 'worthy_purple',
  worthy_mint = 'worthy_mint',
  worthy_blue = 'worthy_blue',
  royal_blue = 'royal_blue',
  purple_400 = 'purple_400',
  purple_500 = 'purple_500',
  purple_100 = 'purple_100',
  purple_200 = 'purple_200',
  purple_300 = 'purple_300',

  // old action colors
  worthy_orange = 'worthy_orange',
  orange_200 = 'orange_200',
  orange_100 = 'orange_100',
  orange_400 = 'orange_400',
  orange_500 = 'orange_500',

  // action color pointers
  worthy_primary = 'worthy_orange',
  worthy_primary_100 = 'orange_100',
  worthy_primary_200 = 'orange_200',
  worthy_primary_400 = 'orange_400',
  worthy_primary_500 = 'orange_500',

  // Supporting grays
  gray_100 = 'gray_100',
  gray_200 = 'gray_200',
  gray_300 = 'gray_300',
  gray_400 = 'gray_400',
  gray_500 = 'gray_500',
  gray_600 = 'gray_600',
  gray_700 = 'gray_700',
  gray_800 = 'gray_800',

  // Feedback
  green_100 = 'green_100',
  green_200 = 'green_200',
  yellow_100 = 'yellow_100',
  yellow_200 = 'yellow_200',
  red_100 = 'red_100',
  red_200 = 'red_200',

  // Validation
  green_300 = 'green_300',
  red_300 = 'red_300',

  // Alert
  worthy_alert_text = 'worthy_alert_text',
  lavender_blush = 'lavender_blush',
  mulberry = 'mulberry',

  worthy_alert_info_100 = 'worthy_alert_info_100',
  worthy_alert_info_300 = 'worthy_alert_info_300',

  worthy_alert_error_100 = 'worthy_alert_error_100',
  worthy_alert_error_300 = 'worthy_alert_error_300',

  worthy_alert_success_100 = 'worthy_alert_success_100',
  worthy_alert_success_300 = 'worthy_alert_success_300',

  worthy_alert_warning_100 = 'worthy_alert_warning_100',
  worthy_alert_warning_300 = 'worthy_alert_warning_300',

  transparent = 'transparent',

  // Repositioning colors
  black_main = 'black_main',
  gray_601 = 'gray_601',
  gray_501 = 'gray_501',
  gray_401 = 'gray_401',
  gray_301 = 'gray_301',
  gray_201 = 'gray_201',
  gray_101 = 'gray_101',
  primary_900 = 'primary_900',
  primary_800 = 'primary_800',
  primary_700_main = 'primary_700_main',
  primary_600 = 'primary_600',
  primary_500 = 'primary_500',
  primary_400 = 'primary_400',
  primary_300 = 'primary_300',
  primary_200 = 'primary_200',
  primary_100 = 'primary_100',
  mellow_success = 'mellow_success',
}

export const GENERAL_COLOR_VALUES = {
  [GeneralColorNames.worthy_gold]: '#BBA56D',
  [GeneralColorNames.worthy_black]: '#141414',
  [GeneralColorNames.worthy_white]: '#fff',

  [GeneralColorNames.black]: '#000',

  [GeneralColorNames.worthy_purple]: '#9e00ff',
  [GeneralColorNames.purple_100]: '#f8ecff',
  [GeneralColorNames.purple_200]: '#f2dcff',
  [GeneralColorNames.purple_300]: '#eac8ff',
  [GeneralColorNames.purple_400]: '#8600d8',
  [GeneralColorNames.purple_500]: '#6d04ae',

  [GeneralColorNames.worthy_orange]: '#F5A723',
  [GeneralColorNames.orange_500]: '#DC8A00',
  [GeneralColorNames.orange_400]: '#ED9C14',
  [GeneralColorNames.orange_200]: '#FFD89E',
  [GeneralColorNames.orange_100]: '#FFE8C1',

  [GeneralColorNames.worthy_primary_100]: '#FFE8C1',

  [GeneralColorNames.worthy_blue]: '#0096C2',
  [GeneralColorNames.royal_blue]: '#2F80ED',
  [GeneralColorNames.lavender_blush]: '#FFECF4',
  [GeneralColorNames.mulberry]: '#C7427C',

  [GeneralColorNames.gray_100]: '#f2f2f2',
  [GeneralColorNames.gray_200]: '#dbdbdb',
  [GeneralColorNames.gray_300]: '#c4c4c4',
  [GeneralColorNames.gray_400]: '#aaa',
  [GeneralColorNames.gray_500]: '#919191',
  [GeneralColorNames.gray_700]: '#D8D8D8',
  [GeneralColorNames.gray_800]: '#F1F1F1',
  [GeneralColorNames.gray_600]: '#6a6a6a',

  [GeneralColorNames.green_100]: '#e3f6ec',
  [GeneralColorNames.green_200]: '#74b48b',
  [GeneralColorNames.green_300]: '#00bc35',
  [GeneralColorNames.worthy_mint]: '#4cddc3',

  [GeneralColorNames.red_100]: '#ffeced',
  [GeneralColorNames.red_200]: '#d06969',
  [GeneralColorNames.red_300]: '#ff275b',

  [GeneralColorNames.yellow_100]: '#fdf5de',
  [GeneralColorNames.yellow_200]: '#f3c757',

  [GeneralColorNames.worthy_alert_text]: '#666',

  [GeneralColorNames.worthy_alert_info_100]: '#EFF5FF',
  [GeneralColorNames.worthy_alert_info_300]: '#93A2DA',

  [GeneralColorNames.worthy_alert_error_100]: '#FFECED',
  [GeneralColorNames.worthy_alert_error_300]: '#D06969',

  [GeneralColorNames.worthy_alert_success_100]: '#E3F6EC',
  [GeneralColorNames.worthy_alert_success_300]: '#74B48B',

  [GeneralColorNames.worthy_alert_warning_100]: '#FDF5DE',
  [GeneralColorNames.worthy_alert_warning_300]: '#F3C757',

  [GeneralColorNames.transparent]: 'transparent',

  // Repositioning
  [GeneralColorNames.black_main]: '#1C1C1C',
  [GeneralColorNames.gray_601]: '#323231',
  [GeneralColorNames.gray_501]: '#666667',
  [GeneralColorNames.gray_401]: '#999999',
  [GeneralColorNames.gray_301]: '#CCCCCC',
  [GeneralColorNames.gray_201]: '#E5E5E5',
  [GeneralColorNames.gray_101]: '#F4F4F4',
  [GeneralColorNames.primary_900]: '#002DB7',
  [GeneralColorNames.primary_800]: '#1245DE',
  [GeneralColorNames.primary_700_main]: '#2D5DEC',
  [GeneralColorNames.primary_600]: '#5A83FF',
  [GeneralColorNames.primary_500]: '#80A0FF',
  [GeneralColorNames.primary_400]: '#A8BEFF',
  [GeneralColorNames.primary_300]: '#CBD8FF',
  [GeneralColorNames.primary_200]: '#DAE4FF',
  [GeneralColorNames.primary_100]: '#EAEFFF',
  [GeneralColorNames.mellow_success]: '#CFEDD7',
};

export enum SpacingSizes {
  x3s = '3px',
  xxs = '6px',
  xs = '12px',
  xsm = '15px',
  sm = '18px',
  smd = '24px',
  md = '30px',
  mdl = '36px',
  lg = '42px',
  xlg = '48px',
  xl = '60px',
  xxl = '96px',
  xxxl = '120px',
}

export const LineHeights = {
  18: '18px',
  20: '20px',
  24: '24px',
  28: '28px',
  30: '30px',
  32: '32px',
  34: '34px',
  36: '36px',
  40: '40px',
  48: '48px',
  54: '54px',
  58: '58px',
  60: '60px',
  74: '74px',
  82: '82px',
};

export enum GeneralFontNames {
  opensans_xl,
  opensans_xl_extrabold,
  opensans_h1,
  opensans_h1_extrabold,
  opensans_h1_bold,
  opensans_h2_semibold,
  opensans_h3_bold,
  opensans_h3_semibold,
  opensans_48_extrabold,
  opensans_36_extrabold,
  opensans_30_bold,
  opensans_30_semibold,
  opensans_22_extrabold,
  opensans_24_semibold,
  opensans_20_semibold,
  opensans_26_bold,
  opensans_18,
  opensans_18_semibold,
  opensans_18_regular,
  opensans_16,
  opensans_16_semibold,
  opensans_16_bold,
  opensans_16_regular,
  opensans_14,
  opensans_14_semibold,
  opensans_12,
  playfair_60,
  playfair_54,
  playfair_48,
  playfair_36,
  playfair_22,
  playfair_18,
  playfair_16_bold,
  roboto_36,
  roboto_36_bold,
  roboto_36_italic,
  roboto_16_italic,
  roboto_22_semibold,
  roboto_22,
  roboto_22_italic,
  roboto_18_semibold,
  roboto_18_quarterbold,
  roboto_18,
  roboto_16_semibold,
  roboto_16_quarterbold,
  roboto_16,
  roboto_14_semibold,
  roboto_14_quarterbold,
  roboto_14,
}

/**
 * Available font values.
 */
export const GENERAL_FONT_VALUES = {
  [GeneralFontNames.opensans_xl_extrabold]: '800 60px Open Sans',
  [GeneralFontNames.opensans_xl]: '700 54px Open Sans',
  [GeneralFontNames.opensans_h2_semibold]: '600 30px Open Sans',
  [GeneralFontNames.opensans_h1_bold]: '700 48px Open Sans',
  [GeneralFontNames.opensans_h1_extrabold]: '800 48px Open Sans',
  [GeneralFontNames.opensans_h3_bold]: '700 24px Open Sans',
  [GeneralFontNames.opensans_h3_semibold]: '600 24px Open Sans',
  [GeneralFontNames.opensans_48_extrabold]: '800 48px Open Sans',
  [GeneralFontNames.opensans_36_extrabold]: '800 36px Open Sans',
  [GeneralFontNames.opensans_30_bold]: '700 30px Open Sans',
  [GeneralFontNames.opensans_30_semibold]: '600 30px Open Sans',
  [GeneralFontNames.opensans_26_bold]: '700 26px Open Sans',
  [GeneralFontNames.opensans_24_semibold]: '600 24px Open Sans',
  [GeneralFontNames.opensans_22_extrabold]: '800 22px Open Sans',
  [GeneralFontNames.opensans_20_semibold]: '600 20px Open Sans',
  [GeneralFontNames.opensans_18]: '18px Open Sans',
  [GeneralFontNames.opensans_18_semibold]: '600 18px Open Sans',
  [GeneralFontNames.opensans_18_regular]: '400 18px Open Sans',
  [GeneralFontNames.opensans_16]: '16px Open Sans',
  [GeneralFontNames.opensans_16_semibold]: '600 16px Open Sans',
  [GeneralFontNames.opensans_16_bold]: '700 16px Open Sans',
  [GeneralFontNames.opensans_16_regular]: '400 16px Open Sans',
  [GeneralFontNames.opensans_14]: '14px Open Sans',
  [GeneralFontNames.opensans_14_semibold]: '600 14px Open Sans',
  [GeneralFontNames.opensans_12]: '12px Open Sans',
  [GeneralFontNames.playfair_16_bold]: '700 16px Playfair Display',
  [GeneralFontNames.playfair_60]: '700 60px Playfair Display',
  [GeneralFontNames.playfair_54]: '700 54px Playfair Display',
  [GeneralFontNames.playfair_48]: '700 48px Playfair Display',
  [GeneralFontNames.playfair_36]: '700 36px Playfair Display',
  [GeneralFontNames.playfair_18]: '700 18px Playfair Display',
  [GeneralFontNames.playfair_22]: '700 22px Playfair Display',
  [GeneralFontNames.roboto_36]: '300 36px Roboto',
  [GeneralFontNames.roboto_36_bold]: '700 36px Roboto',
  [GeneralFontNames.roboto_36_italic]: '300 italic 36px Roboto',
  [GeneralFontNames.roboto_16_italic]: '300 italic 16px Roboto',
  [GeneralFontNames.roboto_22_semibold]: '500 22px Roboto',
  [GeneralFontNames.roboto_22]: '300 22px Roboto',
  [GeneralFontNames.roboto_22_italic]: '300 italic 22px Roboto',
  [GeneralFontNames.roboto_18_semibold]: '500 18px Roboto',
  [GeneralFontNames.roboto_18_quarterbold]: '400 18px Roboto',
  [GeneralFontNames.roboto_18]: '300 18px Roboto',
  [GeneralFontNames.roboto_16_semibold]: '500 16px Roboto',
  [GeneralFontNames.roboto_16_quarterbold]: '400 16px Roboto',
  [GeneralFontNames.roboto_16]: '300 16px Roboto',
  [GeneralFontNames.roboto_14_semibold]: '500 14px Roboto',
  [GeneralFontNames.roboto_14_quarterbold]: '400 14px Roboto',
  [GeneralFontNames.roboto_14]: '300 14px Roboto',
};

export enum MarginVsides {
  na = 'na',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

const NUMBER_INPUT_STYLE = {
  borderRadius: '4px',
  borderStyle: 'solid',
  display: 'block',
  font: '16px Open Sans',
  minWidth: '50px',
  width: '100%',
  boxSizing: 'border-box',
  padding: '12px 0 12px 18px',
  borderWidth: '1px',
  borderColor: '#2d5dec',
  outline: 'none',
};

const NUMBER_INPUT_DIS_STYLE = {
  paddingTop: '11px',
  paddingBottom: '11px',
  border: '2px solid #D8D8D8',
  background: '#fff',
};

const formatPhoneNumber = (phoneNumberString: string) => {
  // add a phone mask
  const regex = /^(1{0,1})(\d{3})(\d{3})(\d*)/;
  const reformat = '$1($2)$3-$4';

  return phoneNumberString.replace(regex, reformat);
};

export function DotsLoading(props: any) {
  const animation = `@keyframes scale {
          0% {transform: scale(0,0);}
          25% {transform: scale(0.9,0.9);}
          50% {
            transform: scale(1,1);
            margin: 0 4px;
          }
          100% {transform: scale(0.0);}
        }`;
  const style = {
    display: 'inline-block',
    height: '11px',
    width: '11px',
    backgroundClip: 'padding-box',
    borderRadius: '500px',
  };
  const backgroundColor = {
    backgroundColor: props.backgroundColor || GENERAL_COLOR_VALUES[GeneralColorNames.worthy_white],
  };
  const circles = [];
  for (let i = 1; i <= 3; i++) {
    circles.push(
      <span
        style={Object.assign({}, style, props.size, backgroundColor, {
          animation: 'scale 1s ' + i * 0.1 + 's infinite cubic-bezier(0.6, -0.28, 0.735, 0.045)',
        })}
        key={i}
      ></span>,
    );
  }
  return (
    <div style={{ backgroundColor: 'transparent' }}>
      <style> {animation} </style>
      {circles}
    </div>
  );
}

export function MediumPrimaryButton(props: any) {
  const [animating, setAnimating] = useState(false);
  const [hover, setHover] = useState(false);

  const style = {
    padding: props.noPadding ? '0' : '11px 22px 12px',
    color: GENERAL_COLOR_VALUES[GeneralColorNames.worthy_white],
    textAlign: 'center' as const,
    display: 'inline-block',
    borderRadius: '3px',
    cursor: 'pointer',
    font: '500 16px Roboto',
    width: '100px',
    whiteSpace: 'nowrap' as const,
    textDecoration: 'none',
    height: '42px',
    border: 'none',
    background: `${GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main]} center`,
    transition: 'background 0.6s',
  };

  const disabledStyle = {
    background: `${GENERAL_COLOR_VALUES[GeneralColorNames.gray_300]} center`,
    cursor: 'not-allowed',
  };

  const hoveredStyle = {
    background: `${
      GENERAL_COLOR_VALUES[GeneralColorNames.primary_900]
    } radial-gradient(circle, transparent 1%, ${
      GENERAL_COLOR_VALUES[GeneralColorNames.primary_900]
    } 1%) center/15000%`,
    transition: 'background .4s',
  };

  const getStyle = () => {
    return Object.assign({}, style, props.disabled ? disabledStyle : {}, hover ? hoveredStyle : {});
  };
  const stopAnimation = () => {
    setAnimating(false);
  };

  const handleClick = () => {
    setAnimating(true);
    props.click(stopAnimation);
  };

  const animatingVisibility = (state: boolean) => {
    return state ? ('hidden' as 'hidden') : ('visible' as 'visible');
  };

  return (
    <div
      data-automation={props.dataAutomation ? props.dataAutomation : ''}
      style={{ display: 'inline-block' }}
    >
      <button
        style={getStyle()}
        disabled={props.disabled}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={() => {
          if (!animating) {
            handleClick();
          }
        }}
      >
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              marginLeft: 'auto',
              marginRight: 'auto',
              left: 0,
              right: 0,
              visibility: animatingVisibility(!animating),
            }}
          >
            <DotsLoading />
          </div>
        </div>
        <div style={{ visibility: animatingVisibility(animating) }}>{props.text as string}</div>
      </button>
    </div>
  );
}

export function MediumLinkButton(props: any) {
  const [hover, setHover] = useState(false);

  const isLink = function (props: any) {
    return 'href' in props;
  };

  const isButton = function (props: any) {
    return 'click' in props;
  };

  const linkStyle = function () {
    const basicStyle = {
      padding: '9px 30px 10px',
      textAlign: 'center' as const,
      display: 'inline-block',
      borderRadius: '7px',
      cursor: 'pointer',
      font: '600 16px "Open Sans"',
      minWidth: '96px',
      whiteSpace: 'nowrap' as const,
      textDecoration: 'none',
      color: GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main],
      border: 'none',
      background: 'transparent center',
      transition: 'background 0.6s',
    };

    const hoveredStyle = {
      background: `${
        GENERAL_COLOR_VALUES[GeneralColorNames.primary_100]
      } radial-gradient(circle, transparent 1%, ${
        GENERAL_COLOR_VALUES[GeneralColorNames.primary_100]
      } 1%) center/15000%`,
      transition: 'background .4s',
    };

    return Object.assign({}, basicStyle, hover ? hoveredStyle : {});
  };

  const link = (
    <a
      href={props.href}
      style={linkStyle()}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      target={props.target}
    >
      {props.text}
    </a>
  );

  const button = (
    <a
      onClick={props.click}
      style={linkStyle()}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {props.text}
    </a>
  );

  if (isLink(props)) {
    return link;
  } else if (isButton(props)) {
    return button;
  } else {
    return <div>{props.text}</div>;
  }
}

enum LangCodes {
  en = 'en',
}

/**
 * Lang class for translations
 */
class Lang {
  /**
   * Vocabulary
   */
  private static vocabulary = {
    empty_email: { [LangCodes.en]: 'Enter your email' },
    invalid_email: { [LangCodes.en]: 'Enter a valid email (you@domain.com)' },
    empty: { [LangCodes.en]: 'The field cannot be empty' },
    empty_text: { [LangCodes.en]: 'Enter letters only (two or more)' },
    empty_password: { [LangCodes.en]: 'Enter your password' },
    empty_phone: { [LangCodes.en]: 'Enter your phone number' },
    invalid_digits: { [LangCodes.en]: 'Enter a valid phone' },
    phone_length: { [LangCodes.en]: 'Enter a 10-digit US phone number' },
    invalid_phone_format: { [LangCodes.en]: 'Enter a 10-digit US phone number' },
  };

  /**
   * Retrieve a string by key and lang
   *
   * @param key key to translatable string
   * @param lang LangCodes key
   *
   * @return string translated string or key
   */
  static getString(key: string, lang: LangCodes = LangCodes.en): string {
    //@ts-ignore
    return this.vocabulary[key][lang] ? this.vocabulary[key][lang] : key;
  }
}

class Validator {
  isValid: boolean;
  validationMessage: string;

  value: string;
  lettersRegexp: RegExp = /^[A-Za-z]+$/;
  digitsRegexp: RegExp = /^[0-9]*$/;
  emailRegexp: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  constructor(value: string) {
    this.value = value;

    this.isValid = true;
    this.validationMessage = '';
  }

  isEmailEmpty(): Validator {
    if (this.value.length <= 0) {
      this.isValid = false;
      this.validationMessage = Lang.getString('empty_email');
    }

    return this;
  }

  isEmail(): Validator {
    if (!this.emailRegexp.test(this.value)) {
      this.isValid = false;
      this.validationMessage = Lang.getString('invalid_email');
    }

    return this;
  }

  isEmpty(isText?: boolean): Validator {
    if (this.value.length < 1) {
      this.isValid = false;
      this.validationMessage = Lang.getString(isText ? 'empty_text' : 'empty');
    }

    return this;
  }

  isEmptyPassword(): Validator {
    if (this.value.length <= 0) {
      this.isValid = false;
      this.validationMessage = Lang.getString('empty_password');
    }

    return this;
  }

  isLetters(): Validator {
    if (!this.lettersRegexp.test(this.value)) {
      this.isValid = false;
      this.validationMessage = Lang.getString('empty_text');
    }

    return this;
  }

  isPhoneCorrectFormat(): Validator {
    const pattern = /^1?[2-9]{1}[0-9]{9}$/;

    if (this.value.length > 0 && !pattern.test(this.value)) {
      this.isValid = false;
      this.validationMessage = Lang.getString('invalid_phone_format');
    }

    return this;
  }

  isPhoneEmpty(): Validator {
    if (this.value.length <= 0) {
      this.isValid = false;
      this.validationMessage = Lang.getString('empty_phone');
    }

    return this;
  }

  isDigits(): Validator {
    if (!this.digitsRegexp.test(this.value)) {
      this.isValid = false;
      this.validationMessage = Lang.getString('invalid_digits');
    }

    return this;
  }

  isCorrectPhoneLength() {
    const usPhoneLength = 10;
    const usPhoneLengthWithOne = 11;

    if (this.value.charAt(0) === '1') {
      this.isValid = this.value.length === usPhoneLengthWithOne;
      this.validationMessage =
        this.value.length === usPhoneLengthWithOne ? '' : Lang.getString('phone_length');
    } else if (this.value.length !== usPhoneLength) {
      this.isValid = false;
      this.validationMessage = Lang.getString('phone_length');
    }

    return this;
  }
}

export const phoneFilteredValue = (value: string, prevValue: string | null = null) => {
  if (value.length < 1) {
    return value;
  }
  const isDeletion = prevValue && prevValue.length > value.length;

  if (isDeletion && prevValue.slice(-1) === '-' && prevValue.slice(-2) === `${value.slice(-1)}-`) {
    value = value.slice(0, -1);
  }

  return value.replace(/[\D\s._-]+/g, '');
};

const validations_method: any = {
  email: (value: string) => {
    let validator = new Validator(value);
    validator.isEmail().isEmailEmpty();

    return { isValid: validator.isValid, validationMessage: validator.validationMessage };
  },
  text: (value: string) => {
    let validator = new Validator(value);
    validator.isLetters().isEmpty(true);

    return { isValid: validator.isValid, validationMessage: validator.validationMessage };
  },
  number: (value: string) => {
    // let validator = new Validator(value);
    // validator.isDigits()

    return { isValid: true, validationMessage: '' };
  },
  phone: (value: string) => {
    let validator = new Validator(phoneFilteredValue(value));
    validator.isPhoneCorrectFormat();
    return { isValid: validator.isValid, validationMessage: validator.validationMessage };
  },
};

export function EmailInput(props: any) {
  return inputGenerator(props, 'email');
}

export function NameInput(props: any) {
  return inputGenerator(props, 'text');
}

export function PhoneInput(props: any) {
  return inputGenerator(props, 'phone');
}

export function NumberInput(props: any) {
  return inputGenerator({ ...props, isValid: true }, 'number');
}

function InputValidationLabel(props: any) {
  let style: any = {
    display: 'block',
    color: 'rgb(255, 39, 91)',
    font: '14px "Open Sans"',
    padding: '3px 0px 0px',
    position: 'static',
    minHeight: '19px',
  };
  if (props.isValid) {
    const color = { color: GENERAL_COLOR_VALUES[GeneralColorNames.gray_500] };
    style = { ...style, color };
  }

  return (
    <div>
      <span style={style} dangerouslySetInnerHTML={{ __html: props.validationMessage || '' }} />
    </div>
  );
}

function InputLabel(props: any) {
  const getLabelColor = () => {
    if (props.state.focused && props.state.isValid) {
      return GeneralColorNames.primary_700_main;
    }

    if (!props.state.isValid) {
      return GeneralColorNames.red_300;
    }

    if (props.disabled) {
      return GeneralColorNames.gray_400;
    }

    return GeneralColorNames.gray_500;
  };

  const styles: DetailedHTMLProps<any, string> = Object.assign(
    {},
    {
      display: 'block',
      color: 'rgb(255, 39, 91)',
      font: '300 16px Roboto',
      position: 'absolute',
      top: '0px',
      left: '0px',
      transition:
        'color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      transform: 'translate(12px, 13px) scale(1)',
      padding: '0px 6px',
      backgroundColor: 'rgb(255, 255, 255)',
      transformOrigin: 'left top',
      pointerEvents: 'none',
    },
    {
      transform: props.isInside
        ? 'translate(12px, -9px) scale(0.9)'
        : 'translate(12px, 13px) scale(1)',
      color: GENERAL_COLOR_VALUES[getLabelColor()],
    },
  );

  return (
    <div>
      <label style={styles}>{props.text}</label>
    </div>
  );
}

function inputGenerator(props: any, type: string) {
  const stateInit = {
    focused: false,
    value: props.initialValue,
    isValid: true,
    validationMessage: (props.description as string) || '',
    hasValidationMessage: true,
    showMessage: true,
    style: { marginTop: props.marginVsides || 'na', marginBottom: props.marginVsides || 'na' },
    disabled: props.disabled,
  };
  const validate: any = validations_method[type];

  const [state, setState] = useState(stateInit);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current && inputRef.current.focus();
    }

    if (props.verification) {
      setState({ ...state, disabled: props.disabled, value: props.initialValue });
    }
  }, [inputRef, props.verification, props.disabled, props.initialValue]);

  useEffect(() => {
    setState((prevState) => {
      return prevState.disabled !== props.disabled
        ? {
            ...prevState,
            isValid: true,
          }
        : { ...prevState };
    });
  }, [props.disabled]);

  const getIsLabelOnFrame = (): boolean => {
    return state.focused || (state.value || '').length > 0 || props.disabled;
  };

  const onInputChange = (e: any) => {
    let value = e.target.value;

    let { isValid, validationMessage } = validate(value, props);
    let resValid = typeof props.isValid !== 'undefined' ? props.isValid : isValid;

    setState((prevState) => {
      return {
        ...prevState,
        value:
          type === 'phone' ? formatPhoneNumber(phoneFilteredValue(value, prevState.value)) : value,
        isValid: resValid,
        validationMessage: validationMessage || props.description || '',
        showMessage: false,
      };
    });

    if (props.change) {
      const newValidation = props.change(value);
      setState((prevState) => ({
        ...prevState,
        isValid: newValidation === undefined ? prevState.isValid : newValidation,
      }));
    }
  };

  const onInputFocus = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      focused: true,
    }));
    if (props.focus) {
      props.focus(e.target.value);
    }
  };

  const onInputBlur = (e: any) => {
    if (props.blur) {
      props.blur(e.target.value);
    }

    setState((prevState) => ({
      ...prevState,
      focused: false,
    }));
  };

  const onEnter = (e?: any) => {
    let { isValid, validationMessage } = validate(state.value, props);
    // Enabling validation from outside
    let resValid = typeof props.isValid !== 'undefined' ? props.isValid : isValid;

    setState((prevState) => {
      return {
        ...prevState,
        showMessage: true,
        isValid: resValid,
        validationMessage: validationMessage || props.description || '',
      };
    });

    props.enter && props.enter(state.value, resValid);
    if (e?.target && (e.target as any).blur) {
      (e.target as any).blur();
    }
    return resValid;
  };

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onEnter(e);
    }
  };

  const getStyle = (): any => {
    return !state.isValid
      ? Object.assign({}, NUMBER_INPUT_STYLE, {
          borderColor: GENERAL_COLOR_VALUES[GeneralColorNames.red_300],
        })
      : Object.assign({}, NUMBER_INPUT_STYLE, props.disabled ? NUMBER_INPUT_DIS_STYLE : {});
  };

  const inside = getIsLabelOnFrame();
  const placeholder = inside ? props.placeholder : '';
  return (
    <InputContainer
      data-automation={props.dataAutomation ? props.dataAutomation : ''}
      style={{ position: 'relative', ...state.style }}
    >
      <input
        ref={inputRef}
        disabled={props.disabled}
        value={state.value}
        placeholder={placeholder}
        style={getStyle()}
        onChange={onInputChange}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onKeyDown={onKeyPress}
        type={type}
      />
      <InputLabel text={props.label} state={state} disabled={props.disabled} isInside={inside} />
      {state.hasValidationMessage && (
        <InputValidationLabel
          isValid={state.isValid}
          validationMessage={(state.focused && state.validationMessage) || ''}
        />
      )}
    </InputContainer>
  );
}

const InputContainer = styled.div`
  & > input:focus {
    border-width: 2px !important;
  }
`;

export function CloseIcon(props: any) {
  const styles = {
    display: 'initial',
    cursor: 'pointer',
    position: 'absolute',
    top: '18px',
    right: '18px',
  };

  const getStyle = () => {
    return Object.assign({}, styles, props.overrideCSS);
  };

  return (
    <span onClick={props.onClose} data-automation="x">
      <svg
        data-automation="x-btn-svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={getStyle()}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.55871 0.267453C1.20215 -0.0891315 0.624037 -0.0891539 0.267453 0.267403C-0.0891315 0.62396 -0.0891539 1.20208 0.267403 1.55866L5.70879 7.00047L0.268148 12.4415C-0.0884092 12.7981 -0.088386 13.3762 0.268198 13.7328C0.624783 14.0894 1.2029 14.0893 1.55945 13.7327L7 8.29178L12.4405 13.7327C12.7971 14.0893 13.3752 14.0894 13.7318 13.7328C14.0884 13.3762 14.0884 12.7981 13.7319 12.4415L8.29121 7.00047L13.7326 1.55866C14.0892 1.20208 14.0891 0.62396 13.7325 0.267403C13.376 -0.0891539 12.7978 -0.0891315 12.4413 0.267453L7 5.70916L1.55871 0.267453Z"
          fill="#141414"
        />
      </svg>
    </span>
  );
}
