import * as React from 'react';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import GA from '../data/GA';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getUser, selectSubmissionId, selectUser } from '../slices/submitSlice';
import EarringsPage from './earrings';
import NecklacePage from './necklace';
import RingPage from './ring';
import SubmissionPage from './submission';
import { BaseEvent } from '../data/events';
import LooseDiamondPage from './looseDiamond';

function IndexPage() {
  const dispatch = useAppDispatch();
  const [isUserDataEventSent, setIsUserDataEventSent] = React.useState(false);
  const { userData } = useAppSelector(selectUser);
  const submissionId = useAppSelector(selectSubmissionId);

  BaseEvent.setSubmissionId(submissionId);

  useEffect(() => {
    dispatch(getUser() as any);
  }, [dispatch]);

  useEffect(() => {
    if (!isUserDataEventSent && userData.email) {
      GA.userData({
        userId: +userData.id,
        userFullName: `${userData.firstName} ${userData.lastName}`,
        userEmail: userData.email,
        userPhone: userData.phone,
      });
      setIsUserDataEventSent(true);
    }
  }, [userData, isUserDataEventSent]);

  return (
    <Routes>
      <Route index element={<SubmissionPage />} />
      <Route path="/ring" element={<RingPage />} />
      <Route path="/earrings" element={<EarringsPage />} />
      <Route path="/necklace" element={<NecklacePage />} />
      <Route path="/loose_diamond" element={<LooseDiamondPage />} />
      <Route
        path="*"
        element={
          <main style={{ padding: '1rem' }}>
            <p>There&apos;s nothing here!</p>
          </main>
        }
      />
    </Routes>
  );
}

export default IndexPage;
