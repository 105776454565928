import React, { useEffect } from 'react';
import { ThemeProvider, mainTheme } from '@worthy-npm/worthy-common-ui-components';
import CommonLayout from './layout/commonLayout';
import Wizard, { IStep } from '../components/wizard';
import { useAppDispatch } from '../app/hooks';
import { updateSecondarySubset, updateWizardStepName, updateType } from '../slices/submitSlice';
import StepShape from '../components/steps/common/step_shape';
import { NecklaceStyle, Subsets } from '../data/ItemDetails';
import DiamondNumber from '../components/steps/common/step_stoneNumber';
import StepBrand from '../components/steps/common/step_brand';
import TotalWeight from '../components/steps/common/step_totalWeight';
import Weight from '../components/steps/common/step_weight';
import StepRegistration from '../components/steps/common/step_registration';
import StepStyle from '../components/steps/common/step_style';
import { StyleI } from '../components/steps/common';
import StepMetalType from '../components/steps/step_metalType';
import StepPhotoUpload from '../components/steps/common/step_photoUpload';
import GA from '../data/GA';

const CenterStoneStyle = ['Pendant', 'Drop', 'Other'];

export const createNecklaceStyleItems = () => {
  let necklaceStyleItems: StyleI[] = [];

  Object.entries(NecklaceStyle).forEach(([key, value]) => {
    const img =
      value === 'Other'
        ? 'images/icons/other.svg'
        : `images/icons/jewelery/necklace/${value.toLowerCase()}.png`;
    const style = {
      key: `necklace-style-${value}`,
      text: key,
      img,
      code: value,
      secondarySubset: CenterStoneStyle.includes(key)
        ? Subsets.CenterStone
        : Subsets.MinorStoneBatch,
    };
    necklaceStyleItems = [...necklaceStyleItems, style];
  });

  return necklaceStyleItems;
};

export const createSteps = (): IStep[] => [
  {
    comp: StepStyle,
    name: 'StepNecklaceStyle',
    caption: 'Necklace Style',
    title: 'What is the style of your necklace?',
    disableBackButton: true,
    props: { items: createNecklaceStyleItems() },
  },
  {
    comp: StepShape,
    name: 'StepLargestDiamondsShape',
    caption: 'Diamonds Shape',
    title: 'What is the largest diamond shape?',
    secondarySubset: Subsets.CenterStone,
  },
  {
    comp: DiamondNumber,
    name: 'StepNumberOfStones',
    caption: 'Number of Stones',
    title: 'Great! How many diamonds are set in your necklace?',
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    comp: TotalWeight,
    name: 'StepTotalCaratWeight',
    caption: 'Carat Weight',
    title: 'What is the total carat weight?',
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    comp: StepShape,
    name: 'StepCommonDiamondsShape',
    caption: '',
    title: 'What is the most common diamond shape in your necklace?',
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    comp: Weight,
    name: 'StepCaratWeight',
    caption: 'Carat Weight',
    title: 'Great! What is the largest diamond’s carat weight?',
    secondarySubset: Subsets.CenterStone,
  },
  {
    comp: StepMetalType,
    name: 'StepMetalType',
    caption: 'Metal Type',
    title: 'What metal are your necklace made of?',
    secondarySubset: Subsets.CenterStone,
  },
  {
    comp: StepBrand,
    name: 'StepBrand',
    caption: 'Brand',
    title: 'What brand is your necklace?',
  },
  {
    comp: StepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    itemRequired: true,
    skipOnlogin: true,
    disableBackButton: true,
  },
  {
    comp: StepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload photos',
    itemRequired: true,
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
  },
];

function NecklacePage() {
  const dispatch = useAppDispatch();
  const steps = createSteps();

  dispatch(updateType('necklace'));

  useEffect(() => {
    // default subset for earring to prevent wrong calculation for count of steps
    dispatch(updateSecondarySubset('diamond_center_stone'));
    dispatch(updateWizardStepName(steps[0].name));
    GA.flow();

    const el = document.querySelector("meta[name='theme-color']");
    if (el) {
      el.setAttribute('content', '#F6F8FF');
    }
  }, [dispatch, steps]);

  return (
    <ThemeProvider theme={mainTheme}>
      <CommonLayout itemType="necklace">
        <Wizard steps={steps} />
      </CommonLayout>
    </ThemeProvider>
  );
}

export default NecklacePage;
