export const ItemTypes = {
  Ring: 'ring',
  Bracelet: 'bracelet',
  LooseDiamond: 'loose diamond',
  Earrings: 'earrings',
  Necklace: 'necklace',
  JewelryBox: 'jewelry box',
  Watch: 'watch',
};

export const ItemTypesPlural = {
  Ring: 'Rings',
  Bracelet: 'Bracelets',
  'Loose Diamond': 'Loose Diamonds',
  Necklace: 'Necklaces',
  'Jewelry Box': 'Jewelry Boxes',
  Watch: 'Watches',
  Earrings: 'earrings',
};

export const Styles = {
  Solitaire: 'solitaire',
  BridalSet: 'bridal set',
  ThreeStone: '3 stone',
  Halo: 'halo',
  Drop: 'drop',
  Pendant: 'pendant',
  Link: 'link',
  Choker: 'choker',
  Collar: 'collar',
  Lariat: 'lariat',
  Riviera: 'riviera',
  Station: 'station',
  Tennis: 'Tennis',
  Charm: 'charm',
  Slide: 'slide',
  Bangle: 'bangle',
  Other: 'Other',
};

export const MetalTypes = {
  Platinum: 'Platinum',
  K24: '24k',
  K22: '22k',
  K18: '18k',
  K14: '14k',
  Under10K: '10k or less',
  Other: 'Other',
};

export const DiamondClarity = {
  FL: 'fl',
  IF: 'if',
  VVS1: 'vvs1',
  VVS2: 'vvs2',
  VS1: 'vs1',
  VS2: 'vs2',
  SI1: 'si1',
  SI2: 'si2',
  I1: 'i1',
  I2: 'i2',
  I3: 'i3',
  NotSure: 'not sure',
};

export const DiamondColor = {
  D: 'd',
  E: 'e',
  F: 'f',
  G: 'g',
  H: 'h',
  I: 'i',
  J: 'j',
  K: 'k',
  L: 'l',
  MtoZ: 'm-z',
  Fancy: 'fancy',
  NotSure: 'not sure',
};

export const DiamondShape = {
  Round: 'round',
  Princess: 'princess',
  Oval: 'oval',
  Marquise: 'marquise',
  Cushion: 'cushion',
  Pear: 'pear',
  Emerald: 'emerald',
  Other: 'other',
};

export const EarringsStyle = {
  Stud: 'Stud',
  Jacket: 'Jacket',
  CentralCluster: 'Central Cluster',
  BlossomCluster: 'Blossom Cluster',
  Chandelier: 'Chandelier',
  Hoops: 'Hoops',
  Huggie: 'Huggie',
  Drop: 'Drop',
  Other: 'Other',
};

export const NecklaceStyle = {
  Link: 'Link',
  Choker: 'Choker',
  Collar: 'Collar',
  Pendant: 'Pendant',
  Riviera: 'Riviera',
  Station: 'Station',
  Drop: 'Drop',
  Lariat: 'Lariat',
  Other: 'Other',
};

export const Subsets = {
  CenterStone: 'diamond_center_stone',
  MinorStoneBatch: 'diamond_minor_stone_batch',
  Earrings: 'jewelry_earrings',
  Rings: 'jewelry_rings',
};

export const SubsetForEarringsStyle = {
  [EarringsStyle.Stud]: Subsets.CenterStone,
  [EarringsStyle.Jacket]: Subsets.MinorStoneBatch,
  [EarringsStyle.CentralCluster]: Subsets.CenterStone,
  [EarringsStyle.BlossomCluster]: Subsets.MinorStoneBatch,
  [EarringsStyle.Chandelier]: Subsets.MinorStoneBatch,
  [EarringsStyle.Hoops]: Subsets.MinorStoneBatch,
  [EarringsStyle.Huggie]: Subsets.MinorStoneBatch,
  [EarringsStyle.Drop]: Subsets.CenterStone,
  [EarringsStyle.Other]: Subsets.CenterStone,
};

export const SubsetForNecklaceStyle = {
  [NecklaceStyle.Link]: Subsets.MinorStoneBatch,
  [NecklaceStyle.Choker]: Subsets.MinorStoneBatch,
  [NecklaceStyle.Collar]: Subsets.MinorStoneBatch,
  [NecklaceStyle.Pendant]: Subsets.CenterStone,
  [NecklaceStyle.Riviera]: Subsets.MinorStoneBatch,
  [NecklaceStyle.Station]: Subsets.MinorStoneBatch,
  [NecklaceStyle.Lariat]: Subsets.MinorStoneBatch,
  [NecklaceStyle.Drop]: Subsets.CenterStone,
  [EarringsStyle.Other]: Subsets.CenterStone,
};

export const JewelryBrand = {
  Tiffany: 'tiffany co',
  Cartier: 'cartier',
  VanCleefArpens: 'van cleef arpens',
  HarryWinston: 'harry winston',
  Bvlgari: 'bvlgari',
  DavidYurman: 'david yurman',
  Graff: 'graff',
  Chopard: 'chopard',
  Other: 'other',
};

export const CertificateType = {
  None: 'none',
  GIA: 'gia',
  Other: 'other',
};

export const EarringsCertificateType = {
  GIA: 'gia',
  IGI: 'igi',
  GSI: 'gsi',
  Other: 'other',
  None: 'none',
};

const fromSimple = (obj: any) => {
  const ret: any = {};
  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key;
  });
  return ret;
};

export const FieldEarringsStyleToSimple = {
  jewelry_style_earring_stud: EarringsStyle.Stud,
  jewelry_style_earring_jacket: EarringsStyle.Jacket,
  jewelry_style_earring_central_cluster: EarringsStyle.CentralCluster,
  jewelry_style_earring_blossom_cluster: EarringsStyle.BlossomCluster,
  jewelry_style_earring_chandelier: EarringsStyle.Chandelier,
  jewelry_style_earring_hoops: EarringsStyle.Hoops,
  jewelry_style_earring_huggie: EarringsStyle.Huggie,
  jewelry_style_earring_drop: EarringsStyle.Drop,
  jewelry_style_earring_other: EarringsStyle.Other,
};

export const FieldEarringsStyleFromSimple = fromSimple(FieldEarringsStyleToSimple);

export const FieldNecklaceStyleToSimple = {
  jewelry_style_necklace_link: NecklaceStyle.Link,
  jewelry_style_necklace_choker: NecklaceStyle.Choker,
  jewelry_style_necklace_collar: NecklaceStyle.Collar,
  jewelry_style_necklace_pendant: NecklaceStyle.Pendant,
  jewelry_style_necklace_riviera: NecklaceStyle.Riviera,
  jewelry_style_necklace_station: NecklaceStyle.Station,
  jewelry_style_necklace_lariat: NecklaceStyle.Lariat,
  jewelry_style_necklace_drop: NecklaceStyle.Drop,
  jewelry_style_necklace_other: EarringsStyle.Other,
};

export const FieldNecklaceStyleFromSimple = fromSimple(FieldNecklaceStyleToSimple);

export const FieldStyleToSimple = {
  jewelry_style_ring_solitaire: Styles.Solitaire,
  jewelry_style_ring_bridal_set: Styles.BridalSet,
  jewelry_style_ring_3_stone: Styles.ThreeStone,
  jewelry_style_ring_halo: Styles.Halo,
  jewelry_style_ring_other: Styles.Other,
};
export const FieldStyleFromSimple = fromSimple(FieldStyleToSimple);

export const FieldShapeToSimple = {
  diamond_shape_round: DiamondShape.Round,
  diamond_shape_princess: DiamondShape.Princess,
  diamond_shape_cushion: DiamondShape.Cushion,
  diamond_shape_marquise: DiamondShape.Marquise,
  diamond_shape_emerald: DiamondShape.Emerald,
  diamond_shape_other: DiamondShape.Other,
};
export const FieldShapeFromSimple = fromSimple(FieldShapeToSimple);

export const FieldColorToSimple = {
  diamond_color_d: DiamondColor.D,
  diamond_color_e: DiamondColor.E,
  diamond_color_f: DiamondColor.F,
  diamond_color_g: DiamondColor.G,
  diamond_color_h: DiamondColor.H,
  diamond_color_i: DiamondColor.I,
  diamond_color_j: DiamondColor.J,
  diamond_color_k: DiamondColor.K,
  diamond_color_l: DiamondColor.L,
  diamond_color_m_z: DiamondColor.MtoZ,
  diamond_color_fancy: DiamondColor.Fancy,
  diamond_color_not_sure: DiamondColor.NotSure,
};
export const FieldColorFromSimple = fromSimple(FieldColorToSimple);

export const FieldClarityToSimple = {
  diamond_clarity_fl: DiamondClarity.FL,
  diamond_clarity_if: DiamondClarity.IF,
  diamond_clarity_vvs1: DiamondClarity.VVS1,
  diamond_clarity_vvs2: DiamondClarity.VVS2,
  diamond_clarity_vs1: DiamondClarity.VS1,
  diamond_clarity_vs2: DiamondClarity.VS2,
  diamond_clarity_si1: DiamondClarity.SI1,
  diamond_clarity_si2: DiamondClarity.SI2,
  diamond_clarity_i1: DiamondClarity.I1,
  diamond_clarity_i2: DiamondClarity.I2,
  diamond_clarity_i3: DiamondClarity.I3,
  diamond_clarity_not_sure: DiamondClarity.NotSure,
};
export const FieldClarityFromSimple = fromSimple(FieldClarityToSimple);

export const FieldCertificateToSimple = {
  diamond_certificate_none: CertificateType.None,
  diamond_certificate_gia: CertificateType.GIA,
  diamond_certificate_other: CertificateType.Other,
};
export const FieldCertificateFromSimple = fromSimple(FieldCertificateToSimple);

export const FieldEarringsCertificateToSimple = {
  diamond_certificate_gia: EarringsCertificateType.GIA,
  diamond_certificate_igi: EarringsCertificateType.IGI,
  diamond_certificate_gsi: EarringsCertificateType.GSI,
  diamond_certificate_other: EarringsCertificateType.Other,
  diamond_certificate_none: CertificateType.None,
};

export const FieldEarringsCertificateFromSimple = fromSimple(FieldEarringsCertificateToSimple);

export const FieldMetaTypeToSimple = {
  jewelry_metal_type_10k_gold: MetalTypes.Under10K,
  jewelry_metal_type_14k_gold: MetalTypes.K14,
  jewelry_metal_type_18k_gold: MetalTypes.K18,
  jewelry_metal_type_22k_gold: MetalTypes.K22,
  jewelry_metal_type_24k_gold: MetalTypes.K24,
  jewelry_metal_type_platinum: MetalTypes.Platinum,
  jewelry_metal_type_other: MetalTypes.Other,
};
export const FieldMetaTypeFromSimple = fromSimple(FieldMetaTypeToSimple);

export const FieldBrandToSimple = {
  jewelry_brand_tiffany_co: JewelryBrand.Tiffany,
  jewelry_brand_cartier: JewelryBrand.Cartier,
  jewelry_brand_van_cleef_arpels: JewelryBrand.VanCleefArpens,
  jewelry_brand_harry_winston: JewelryBrand.HarryWinston,
  jewelry_brand_david_yurman: JewelryBrand.DavidYurman,
  jewelry_brand_bvlgari: JewelryBrand.Bvlgari,
  jewelry_brand_other: JewelryBrand.Other,
  jewelry_brand_graff: JewelryBrand.Graff,
  jewelry_brand_chopard: JewelryBrand.Chopard,
};
export const FieldBrandFromSimple = fromSimple(FieldBrandToSimple);
