import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { Box, Typography } from '@worthy-npm/worthy-common-ui-components';
import { IMask } from 'react-imask';
import { ExternalLink, RunningText } from '../../../styles/commonText';
import { Step, StepContainer, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  IRegistration,
  registerUser,
  selectItemType,
  selectRegister,
  selectRegisterAPI,
  selectSubmitAPI,
  updateItemAutoRejected,
  updateRegistration,
  updateUserPhoneNumber,
} from '../../../slices/submitSlice';
import { StepProps } from '../common';
import { sendGeneralGAEvent } from '../../../data/events';
import { GoogleSignIn } from '../../google_signin';
import { CookieStore } from '../../../services/cookieStore';
import { LoadingContainer, LoadingOverlay, LoadingDiamondImg } from '../../overlay';
import { IsMobileProp } from '../../../styles/commonPropType';
import StepTitle from '../../stepTitle';
import { GENERAL_COLOR_VALUES, GeneralColorNames } from '../../../clarity';
import StepRegistrationForm from './step_registration_form';
import { getItemIdFromWindow, setItemIdToWindow } from '../../../lib/item';
import { generateEstimation, postRegistrationItemEvents } from '../../../lib/submitHelper';
import { isItemRejected } from '../../../validation/validations';

const RunningTextBold = styled(RunningText)`
  font-weight: 500;
`;

const LogInContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 50px;
  align-items: center;
`;

const FooterIcon = styled.img`
  margin: 0 8px;
`;

const VSuccess = 'images/icons/v_success.svg';

const GoogleVerified = styled.div<IsMobileProp>`
  padding: 1rem 0;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #00bc35;
  text-align: center;
`;

export function GoogleSignInEnabled() {
  const googleClientID = process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID || '';
  return googleClientID !== '' && CookieStore.getGoogleSigninExp();
}

function LogInLink() {
  return <ExternalLink href={`${process.env.REACT_APP_APP_URL}/signin`}>Log in</ExternalLink>;
}

const TermsConditions = styled.a`
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main]};
`;

export const termsConditionsLink = (
  <TermsConditions
    href={`${process.env.REACT_APP_SITE_URL}/about/terms-and-conditions`}
    target="_blank"
  >
    terms & conditions
  </TermsConditions>
);

export const privacyPolicyLink = (
  <TermsConditions href={`${process.env.REACT_APP_SITE_URL}/about/privacy-policy`} target="_blank">
    privacy policy
  </TermsConditions>
);

const normalizePhoneNumber = (value: string, mask = true) => {
  if (!value) {
    return '';
  }
  const masked = IMask.createMask({
    mask: '+1 (000) 000-0000',
  });
  masked.resolve(value);
  return mask ? masked.value : masked.unmaskedValue;
};

function StepRegistration({ next, stepName, idx, prev }: StepProps) {
  const registerAPI = useAppSelector(selectRegisterAPI);
  const registration = useAppSelector(selectRegister);
  const itemType = useAppSelector(selectItemType);
  const dispatch = useAppDispatch();
  const [verificationView, setVerificationView] = useState(false);
  const [loading, setLoading] = useState(false);
  const { itemId } = useAppSelector(selectSubmitAPI);

  // Only show loader to prevent disabling from Google signin button
  // and do it manually after esimation event
  const showLoader = (_state: boolean) => {
    setLoading(true);
  };
  const enableVerification = () =>
    CookieStore.getVerifyPhoneNumberExp() && CookieStore.getGoogleSigninExp();
  const getStepName = () => (verificationView ? 'StepVerification' : stepName);

  useEffect(() => {
    setLoading(registerAPI.loading);
  }, [registerAPI]);

  setItemIdToWindow(itemId);

  interface IUser {
    id: number;
    phone: string;
    email: string;
  }

  const afterSuccessfulLoginOrRegistrationActivities = async (
    itemID: string,
    isRegistration: boolean,
  ) => {
    const result = await generateEstimation(itemID);
    const { user, item }: any = result;

    const { rejected, rejectReason } = isItemRejected(item);
    if (rejected) {
      dispatch(updateItemAutoRejected({ rejected, reason: rejectReason }));
    }
    if (!user || !item) {
      return;
    }
    if (!isRegistration) {
      return;
    }

    postRegistrationItemEvents(user, item);
  };

  const onSuccessfulGoogleLogin = async (
    user: IUser | undefined,
    isUserLogin: boolean,
    leadId: number,
  ) => {
    const isRegistration = !isUserLogin;
    const stepNameWithLogin = isUserLogin ? 'StepLogin' : getStepName();
    // send an event on successful Google
    sendGeneralGAEvent({
      action: `successful Google ${isUserLogin ? 'login' : 'registration'}`,
      idx,
      stepName: stepNameWithLogin,
      clickText: 'Google button',
      itemType,
      leadId,
      userId: user?.id,
    });
    // also send the same event as the regular
    sendGeneralGAEvent({
      action: `success${isUserLogin ? ' login' : ''}`,
      idx,
      stepName: stepNameWithLogin,
      itemType,
      leadId,
      userId: user?.id,
    });

    await afterSuccessfulLoginOrRegistrationActivities(getItemIdFromWindow(), isRegistration);

    setLoading(false);

    // Enable verification if experiment is enabled and user is new and user doesn't have phone number
    if (enableVerification() && !isUserLogin && (!user?.phone || user?.phone === '0000000001')) {
      setVerificationView(true);
    } else {
      next('' as any);
    }
  };

  const sendEventsWithFieldsData = (data: IRegistration) => {
    Object.values(data).forEach((key) => {
      sendGeneralGAEvent({
        action: 'click',
        stepName: getStepName(),
        idx,
        clickText: key.value,
        itemType,
      });
    });
  };

  const sendEventContinueClick = () => {
    // send event for user clicked 'continue'
    sendGeneralGAEvent({
      action: 'continue',
      idx,
      stepName: getStepName(),
      itemType,
    });
  };

  const onPhoneVerificationSubmit = (data: IRegistration) => {
    if (registerAPI.loading) {
      return;
    }

    sendEventsWithFieldsData(data);
    sendEventContinueClick();

    const registerWithPhone = { ...registration, phoneNumber: data.phoneNumber };
    dispatch(
      updateUserPhoneNumber({
        data: registerWithPhone,
        callBack: () => {},
        successCallBack: () => {
          if (registerWithPhone.phoneNumber !== '') {
            sendGeneralGAEvent({
              action: 'phone success',
              idx,
              stepName: getStepName(),
              itemType,
            });
          }
          next('' as any);
        },
      } as any) as any,
    );
  };

  const onRegistrationSubmit = (data: IRegistration) => {
    if (registerAPI.loading) {
      return;
    }
    sendEventsWithFieldsData(data);

    dispatch(updateRegistration(data));
    dispatch(
      registerUser({
        data,
        clb: () => {},
        successClb: async (response: any) => {
          sendGeneralGAEvent({
            action: 'success',
            idx,
            stepName: getStepName(),
            leadId: response?.lead_id,
            userId: response?.user?.id,
            itemType,
          });
          await afterSuccessfulLoginOrRegistrationActivities(itemId, true);
          next('' as any);
        },
      } as any) as any,
    );
  };

  const onSubmit = (data: IRegistration) => {
    setLoading(true);

    const preparedData = {
      ...data,
      ...{ phoneNumber: normalizePhoneNumber(data.phoneNumber, false) },
    };

    if (verificationView) {
      onPhoneVerificationSubmit(preparedData);
    } else {
      onRegistrationSubmit(preparedData);
    }
  };

  return (
    <LoadingContainer>
      {loading && (
        <LoadingOverlay background="#f6f8ff">
          <LoadingDiamondImg />
        </LoadingOverlay>
      )}

      <StepContainer data-automation={verificationView ? 'verification-step' : 'registration-step'}>
        <Step>
          <StepTitle
            idx={idx}
            prev={prev}
            stepTitle="See how much you can get!"
            stepName="Sign Up"
            stepNotice=""
            stepCaption="Sign Up"
          />

          <StepGridContainer>
            {verificationView && (
              <GoogleVerified>
                <img src={VSuccess} alt="" width="16px" />
                <span> Google account verified </span>
              </GoogleVerified>
            )}

            <StepRegistrationForm
              onSubmit={onSubmit}
              verificationView={verificationView}
              apiErrorMsg={registerAPI.errorMsg}
              stepName={getStepName()}
              idx={idx}
            />

            {!verificationView && (
              <Box marginTop="20px">
                <LogInContainer>
                  <RunningTextBold>Already signed up?&nbsp;</RunningTextBold>
                  <LogInLink />
                </LogInContainer>
              </Box>
            )}

            {GoogleSignInEnabled() && (
              <Box height="76px" marginTop="20px" display="flex" justifyContent="center">
                <GoogleSignIn
                  onSuccess={onSuccessfulGoogleLogin}
                  setLoading={showLoader}
                  disabled={verificationView}
                  options={{ width: '350', size: 'large', shape: 'circle' }}
                />
              </Box>
            )}

            <Typography variant="body2" marginTop="24px" textAlign="center" fontSize="12px">
              By signing up, you agree to the Worthy {termsConditionsLink} and to receive marketing
              communications from Worthy in accordance with our {privacyPolicyLink}.
            </Typography>

            <Box display="flex" justifyContent="center" marginTop="24px" marginBottom="25px">
              <FooterIcon src="/images/icons/bbb.svg" />
              <FooterIcon src="/images/icons/secured.svg" />
            </Box>
          </StepGridContainer>
        </Step>
      </StepContainer>
    </LoadingContainer>
  );
}

export default StepRegistration;
