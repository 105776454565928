import React, { useEffect } from 'react';
import { mainTheme, ThemeProvider } from '@worthy-npm/worthy-common-ui-components';
import Layout from './layout/layout';
import Wizard, { IStep } from '../components/wizard';
import StepShape from '../components/steps/step_shape';
import StepSettings from '../components/steps/step_setting';
import StepCarat from '../components/steps/step_carat';
import StepRegistration from '../components/steps/step_registration';
import { useAppDispatch } from '../app/hooks';
import { updateType } from '../slices/submitSlice';
import StepPhotoUpload from '../components/steps/step_photoUpload';
import StepColor from '../components/steps/step_color';
import StepClarity from '../components/steps/step_clarity';
import GA from '../data/GA';

export const createSteps = (): IStep[] => [
  { comp: StepSettings, name: 'StepSettings', caption: 'Setting' },
  { comp: StepShape, name: 'StepShape', caption: 'Center diamond shape' },
  { comp: StepCarat, name: 'StepCarat', caption: 'Center diamond carat' },
  {
    comp: StepColor,
    name: 'StepColor',
    caption: 'Center diamond color',
    hidden: true,
  },
  {
    comp: StepClarity,
    name: 'StepClarity',
    caption: 'Diamond clarity',
    hidden: true,
  },
  {
    comp: StepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    itemRequired: true,
    skipOnlogin: true,
  },
  {
    comp: StepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload photos',
    itemRequired: true,
    disableProgressBar: true,
    disableBackButton: true,
  },
];

function RingPage() {
  const dispatch = useAppDispatch();

  const steps = createSteps();

  useEffect(() => {
    dispatch(updateType('ring'));
    GA.flow();
  }, [dispatch]);

  return (
    <ThemeProvider theme={mainTheme}>
      <Layout>
        <Wizard steps={steps} />
      </Layout>
    </ThemeProvider>
  );
}

export default RingPage;
