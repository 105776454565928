/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

import { css } from '@emotion/react/macro';
import {
  GENERAL_COLOR_VALUES,
  GENERAL_FONT_VALUES,
  GeneralColorNames,
  GeneralFontNames,
  SpacingSizes,
  LineHeights,
} from '../clarity';
import { IsMobileProp, IsVCentredProp } from './commonPropType';

export interface Icon {
  code: string;
  icon: string;
  iconSelected: string;
}

export const RunningText = styled.span`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.roboto_16]};
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.black_main]};
  line-height: ${LineHeights[30]};
`;

export const RunningTextPopup = styled(RunningText)`
  display: block;
  margin-bottom: ${SpacingSizes.md};
`;

const LINK_STYLES = `
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.roboto_16]};
  text-decoration: none;
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main]};
  font-weight: 500;
`;

// CLink stands for "common link"
export const CLink = styled(Link)`
  ${LINK_STYLES}
`;

export const ExternalLink = styled.a`
  ${LINK_STYLES}
`;

export const TitleText = styled.span`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.playfair_22]};
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.black_main]};
  line-height: ${LineHeights[36]};
  margin-bottom: 30px;
`;

interface ISpan {
  isMobile?: boolean;
}

export const StepTitleText = styled(TitleText)<ISpan>`
  display: block;
  text-align: center;
`;

export const PopupTitle = styled(TitleText)`
  display: block;
  margin: 16px 0 20px;
`;

export const BlueWord = styled.span`
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_600]};
`;

export const BlueWordButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_600]};
  cursor: pointer;
`;

export const FormContent = styled.section<IsMobileProp>`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props: any) => (props.isMobile ? SpacingSizes.xs : SpacingSizes.smd)};
  margin: 0 auto;
  padding: 0;
  justify-content: center;
  max-width: 800px;
`;

const TileButtonBasicStyle = styled.button`
  width: 109px;
  height: 109px;
  border-radius: 3px;
  border: 1px solid
    ${(props: any) =>
      props.selected
        ? GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main]
        : GENERAL_COLOR_VALUES[GeneralColorNames.primary_600]};
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.roboto_16]};
  line-height: ${LineHeights['24']};
  padding: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: transparent;
`;

const TileButtonTag = styled(TileButtonBasicStyle)`
  position: relative;
  text-transform: capitalize;
  align-items: flex-end;
  &:hover,
  &:focus,
  &:active {
    & img.selected {
      z-index: 2;
      opacity: 1;
    }
    box-shadow: 0 0 0 1px ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main]};
  }
  & img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 40%;
    margin: 20px auto;
    width: 40%;
  }
  & span {
    top: -12px;
    position: relative;
  }
  & img.selected {
    opacity: 0;
  }
  ${(props: any) =>
    props.selected &&
    css`
      box-shadow: 0 0 0 1px ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main]};
      & img.selected {
        z-index: 2;
        opacity: 1;
      }
    `}
`;

const TileSizes = {
  xs: {
    width: 60,
    height: 60,
  },
  s: {
    width: 66,
    height: 66,
  },
  sm: {
    width: 84,
    height: 84,
  },
  smm: {
    width: 90,
    height: 90,
  },
  m: {
    width: 109,
    height: 109,
  },
};

const TileButtonTextTag = styled(TileButtonBasicStyle)`
  width: ${(props: any) => {
    const size = props.size || 'm';
    // @ts-ignore
    return TileSizes[size].width;
  }}px;
  height: ${(props: any) => {
    const size = props.size || 'm';
    // @ts-ignore
    return TileSizes[size].height;
  }}px;
  align-items: center;
  padding: ${(props: any) => (props.size === 'm' ? '10px' : '5px')};
  box-shadow: ${(props: any) =>
    props.selected
      ? `0 0 0 2px ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main]}`
      : 'transparent'};
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 2px ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main]};
    border-radius: 3px;
  }
`;

export const FormContentNarrow = styled(FormContent)`
  max-width: 310px;
`;
export const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export function TileButton(props: any) {
  // eslint-disable-next-line react/destructuring-assignment
  return props.icon ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TileButtonTag {...props}>
      <IconContainer>
        {props.iconSelected && (
          <img
            data-automation="icon-selected"
            alt=""
            className="selected"
            src={props.iconSelected}
          />
        )}
        {props.icon && <img data-automation="icon" alt="" src={props.icon} />}
      </IconContainer>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      <span> {props.text} </span>
    </TileButtonTag>
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading,react/destructuring-assignment
    <TileButtonTextTag {...props}>{props.text}</TileButtonTextTag>
  );
}

export const ResponsiveSquareTile = styled(TileButton)`
  width: 100%;
  height: 100%;
  &::before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  &::after {
    display: block;
    content: '';
    clear: both;
  }
`;

export const ResponsiveTileContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  gap: ${SpacingSizes.xs};
`;

export const ResponsiveBannerText = styled.div`
  display: flex;
  width: fit-content;
  margin: 2rem auto;
  justify-content: center;
  .add-jewelery-icon {
    width: 9%;
  }
`;

export const Step = styled.div`
  display: block;
  align-items: center;
`;

export const MuiStep = styled.div`
  display: block;
  align-items: center;
  overflow: auto;
  width: 100%;
  padding: 14px 24px;
`;

export const NextStepButton = styled.div<any>`
  text-align: center;
`;

export const BrandTileButton = styled.div`
  border: ${(props: { selected: boolean }) => (props.selected ? 3 : 1)}px solid #80a0ff;
  border-radius: 3px;
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.roboto_16]};
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

export const StepCTAContainer = styled.div<IsMobileProp | IsVCentredProp>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props: any) => (props.isVCentred ? 'space-evenly' : 'space-between')};
  height: 100%;
  max-height: ${(props: any) => (props.isMobile ? '100%' : '350px')};
  margin: 0 26px;
`;
