import axios, { AxiosResponse } from 'axios';
import {
  CertificateType,
  DiamondClarity,
  DiamondColor,
  FieldBrandFromSimple,
  FieldCertificateFromSimple,
  FieldClarityFromSimple,
  FieldColorFromSimple,
  FieldEarringsStyleFromSimple,
  FieldMetaTypeFromSimple,
  FieldNecklaceStyleFromSimple,
  FieldShapeFromSimple,
  FieldStyleFromSimple,
  ItemTypes,
  MetalTypes,
  SubsetForEarringsStyle,
  SubsetForNecklaceStyle,
  Subsets,
} from '../data/ItemDetails';

import { getSessionData, isIframe } from '../lib/commonUtils';
import type { ItemInputsTypes, SubmitState } from '../slices/submitSlice';
import { CookieStore } from './cookieStore';

const { REACT_APP_SITE_URL } = process.env;

const DEFAULT_PHONE = '0000000001';

interface ISubmitItemResponse {
  item: {
    bundle: {
      id: number;
    };
    id: string;
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  user: {};
}

export default class WorthyAPI {
  static defaultOptions = {
    withCredentials: true,
  };

  static async ADO() {
    return axios.get(`${REACT_APP_SITE_URL}/api/v2/sellers/ado`, this.defaultOptions);
  }

  static async storeStateInBackend(state: any, user: any, trigger: string, credential = '') {
    return axios
      .post(
        `${process.env.REACT_APP_SUBMIT_BE_URL}/store-state-data`,
        { state, user, credential, trigger },
        this.defaultOptions,
      )
      .catch((err: any) => {
        // Just log the error
        if (window.rollbar && window.rollbar.error)
          window.rollbar.error('Error sending state', err);
        console.error('Error sending state', err);
      });
  }

  static async createItem(params: SubmitState) {
    switch (params.type) {
      case ItemTypes.Earrings:
        return this.createEarringsItem(params);
      case ItemTypes.Necklace:
        return this.createNecklaceItem(params);
      case ItemTypes.LooseDiamond:
        return this.createLooseDiamondItem(params);
      default:
        return this.createRingItem(params);
    }
  }

  static createRingItem(params: ItemInputsTypes) {
    const color = params.color || DiamondColor.NotSure;
    const clarity = params.clarity || DiamondClarity.NotSure;

    return axios.post<ISubmitItemResponse>(
      `${REACT_APP_SITE_URL}/api/v2/sellers/item/submit_properties`,
      {
        item_type: 'Rings',
        know2c: true,
        user_flow: '',
        submitted_from: isIframe() ? 'widget' : 'app',
        submit_second_item: null,
        seller_item: {
          item_type_id: '1',
          desc: 'Rings',
          initial_transaction_type: '1',
          subsets: {
            1: {
              type: 'jewelry_rings',
              properties_set: {
                item_type: '1',
                jewelry_style_ring: FieldStyleFromSimple[params.setting],
                ring_size: 'ring_size_6_25',
                jewelry_metal_weight: '5',
                jewelry_brand: FieldBrandFromSimple[params.brand],
              },
            },
            2: {
              type: 'diamond_center_stone',
              properties_set: {
                diamond_color_shade: 'diamond_color_shade_na',
                diamond_cut_grade: 'diamond_cut_grade_very_good',
                diamond_polish: 'diamond_polish_very_good',
                diamond_symmetry: 'diamond_symmetry_very_good',
                diamond_fluorescence_intensity: 'diamond_fluorescence_intensity_none',
                diamond_diamond_treated: 'diamond_diamond_treated_no',
                diamond_clarity_enhanced: 'diamond_clarity_enhanced_no',
                diamond_color_enhanced: 'diamond_color_enhanced_no',
                diamond_chips: 'diamond_chips_none',
                diamond_inclusions_location: 'diamond_inclusions_location_none',
                diamond_inclusions_level: 'diamond_inclusions_level_eye_clean',
                diamond_culet_size: 'diamond_culet_size_none',
                diamond_culet_condition: 'diamond_culet_condition_natural',
                diamond_certificate: FieldCertificateFromSimple[params.certificate],
                diamond_certificate_number: params.certificateNumber,
                diamond_setting_type: 'diamond_setting_type_prong',
                diamond_carat_weight: `${params.carat}`,
                diamond_shape: FieldShapeFromSimple[params.shape],
                diamond_color: FieldColorFromSimple[color],
                diamond_clarity: FieldClarityFromSimple[clarity],
                diamond_type: 'diamond_type_natural',
                stone_type: 'stone_type_diamond',
              },
            },
          },
        },
        sessionData: getSessionData(),
        input_props: {
          itemType: 'ring',
          style: params.setting,
          shape: params.shape,
          caratWeight: params.carat,
          color,
          clarity,
          certificateType: params.certificate,
          certificateId: params.certificateNumber,
          brand: params.brand,
          submitTimestamp: new Date().toISOString(),
        },
      },
      this.defaultOptions,
    );
  }

  static createLooseDiamondItem(params: ItemInputsTypes) {
    const color = params.color || DiamondColor.NotSure;
    const clarity = params.clarity || DiamondClarity.NotSure;

    return axios.post<ISubmitItemResponse>(
      `${REACT_APP_SITE_URL}/api/v2/sellers/item/submit_properties`,
      {
        item_type: 'Loose Diamonds',
        know2c: true,
        user_flow: '',
        submitted_from: isIframe() ? 'widget' : 'app',
        submit_second_item: null,
        seller_item: {
          item_type_id: '1',
          desc: 'Loose Diamonds',
          initial_transaction_type: '1',
          subsets: {
            1: {
              type: 'diamond_loose_diamonds',
              properties_set: {
                item_type: '4',
                diamond_color_shade: 'diamond_color_shade_na',
                diamond_cut_grade: 'diamond_cut_grade_very_good',
                diamond_polish: 'diamond_polish_very_good',
                diamond_symmetry: 'diamond_symmetry_very_good',
                diamond_fluorescence_intensity: 'diamond_fluorescence_intensity_none',
                diamond_diamond_treated: 'diamond_diamond_treated_no',
                diamond_clarity_enhanced: 'diamond_clarity_enhanced_no',
                diamond_color_enhanced: 'diamond_color_enhanced_no',
                diamond_chips: 'diamond_chips_none',
                diamond_inclusions_location: 'diamond_inclusions_location_none',
                diamond_inclusions_level: 'diamond_inclusions_level_eye_clean',
                diamond_culet_size: 'diamond_culet_size_none',
                diamond_culet_condition: 'diamond_culet_condition_natural',
                diamond_certificate: FieldCertificateFromSimple[params.certificate],
                diamond_certificate_number: params.certificateNumber,
                diamond_setting_type: 'diamond_setting_type_prong',
                diamond_carat_weight: `${params.carat}`,
                diamond_shape: FieldShapeFromSimple[params.shape],
                diamond_color: FieldColorFromSimple[color],
                diamond_clarity: FieldClarityFromSimple[clarity],
                diamond_type: 'diamond_type_natural',
                // stone_type: 'stone_type_diamond'
              },
            },
          },
        },
        sessionData: getSessionData(),
        input_props: {
          itemType: ItemTypes.LooseDiamond,
          style: params.setting,
          shape: params.shape,
          caratWeight: params.carat,
          color,
          clarity,
          certificateType: params.certificate,
          certificateId: params.certificateNumber,
          brand: params.brand,
          submitTimestamp: new Date().toISOString(),
        },
      },
      this.defaultOptions,
    );
  }

  static async createNecklaceItem(params: SubmitState) {
    let secondSubset = null;
    let inputProps = {
      itemType: ItemTypes.Necklace.toLowerCase(),
      style: params.style.toLowerCase(),
      brand: params.brand.toLowerCase(),
      shape: params.shape.toLowerCase(),
      color: DiamondColor.NotSure.toLowerCase(),
      clarity: DiamondClarity.NotSure.toLowerCase(),
      submitTimestamp: new Date().toISOString(),
    };

    const SubsetStyle = SubsetForNecklaceStyle[params.style];
    if (SubsetStyle === Subsets.CenterStone) {
      inputProps = {
        ...inputProps,
        ...{
          metalType: FieldMetaTypeFromSimple[params.metalType],
          caratWeight: params.carat,
        },
      };

      secondSubset = {
        type: SubsetStyle,
        properties_set: {
          diamond_shape: FieldShapeFromSimple[params.shape],
          diamond_carat_weight: params.carat,
          diamond_color: FieldColorFromSimple[DiamondColor.NotSure],
          diamond_clarity: FieldClarityFromSimple[DiamondClarity.NotSure],
        },
      };
    }
    if (SubsetStyle === Subsets.MinorStoneBatch) {
      inputProps = {
        ...inputProps,
        ...{
          numDiamonds: params.diamondNumber,
          caratWeightTotal: params.totalCarat,
        },
      };

      secondSubset = {
        type: SubsetStyle,
        properties_set: {
          diamond_number_of_stones: params.diamondNumber,
          diamond_total_carat_weight: params.totalCarat,
          diamond_shape: FieldShapeFromSimple[params.shape],
          diamond_average_color_grade: 'diamond_average_color_grade_na',
          diamond_average_clarity_grade: 'diamond_average_clarity_grade_na',
          diamond_certificate: FieldCertificateFromSimple[CertificateType.None],
        },
      };
    }

    return axios.post<ISubmitItemResponse>(
      `${REACT_APP_SITE_URL}/api/v2/sellers/item/submit_properties`,
      {
        item_type: 'Necklace',
        know2c: true,
        user_flow: '',
        submitted_from: isIframe() ? 'widget' : 'app',
        submit_second_item: null,
        seller_item: {
          item_type_id: '1',
          desc: 'Necklace',
          initial_transaction_type: '1',
          subsets: {
            1: {
              type: 'jewelry_necklaces',
              properties_set: {
                item_type: '1',
                jewelry_style_necklace: FieldNecklaceStyleFromSimple[params.style],
                jewelry_metal_type: params.metalType
                  ? FieldMetaTypeFromSimple[params.metalType]
                  : 'jewelry_metal_type_other',
                ...(params.metalWeight && { jewelry_metal_weight: params.metalWeight }),
                ...(params.brand && { jewelry_brand: FieldBrandFromSimple[params.brand] }),
              },
            },
            2: secondSubset,
          },
        },
        sessionData: getSessionData(),
        input_props: inputProps,
      },
      this.defaultOptions,
    );
  }

  static async createEarringsItem(params: SubmitState) {
    let secondSubset = null;
    let inputProps = {
      itemType: 'earrings',
      styleEarrings: params.style,
      metalType: FieldMetaTypeFromSimple[MetalTypes.Other],
      metalWeight: 0,
      brand: params.brand,
    };

    if (SubsetForEarringsStyle[params.style] === Subsets.CenterStone) {
      inputProps = {
        ...inputProps,
        ...{
          caratWeight: params.totalCarat,
          certificateType: params.certificate,
          submitTimestamp: new Date().toISOString(),
        },
      };

      secondSubset = {
        type: SubsetForEarringsStyle[params.style],
        properties_set: {
          diamond_shape: FieldShapeFromSimple[params.shape],
          diamond_carat_weight: params.carat,
          diamond_color: FieldColorFromSimple[DiamondColor.NotSure],
          diamond_clarity: FieldClarityFromSimple[DiamondClarity.NotSure],
          diamond_certificate: FieldCertificateFromSimple[params.certificate],
          diamond_certificate_number: params.certificateNumber,
        },
      };
    }
    if (SubsetForEarringsStyle[params.style] === Subsets.MinorStoneBatch) {
      inputProps = {
        ...inputProps,
        ...{
          itemType: 'erring',
          style: params.style,
          numberOfStones: params.diamondNumber,
          totalCaratWeight: params.totalCarat,
          caratWeight: params.carat,
          shape: params.shape,
          certificateType: params.certificate,
          certificateId: params.certificateNumber,
          submitTimestamp: new Date().toISOString(),
        },
        ...(params.brand && { brand: params.brand }),
      };
      secondSubset = {
        type: SubsetForEarringsStyle[params.style],
        properties_set: {
          diamond_number_of_stones: params.diamondNumber,
          diamond_total_carat_weight: params.totalCarat,
          diamond_shape: FieldShapeFromSimple[params.shape],
          diamond_average_color_grade: 'diamond_average_color_grade_na',
          diamond_average_clarity_grade: 'diamond_average_clarity_grade_na',
          diamond_certificate: FieldCertificateFromSimple[CertificateType.None],
        },
      };
    }

    return axios.post<ISubmitItemResponse>(
      `${REACT_APP_SITE_URL}/api/v2/sellers/item/submit_properties`,
      {
        item_type: 'Earrings',
        know2c: true,
        user_flow: '',
        submitted_from: isIframe() ? 'widget' : 'app',
        submit_second_item: null,
        seller_item: {
          item_type_id: '1',
          desc: 'Earrings',
          initial_transaction_type: '1',
          subsets: {
            1: {
              type: 'jewelry_earrings',
              properties_set: {
                item_type: '1',
                jewelry_style_earring: FieldEarringsStyleFromSimple[params.style],
                jewelry_metal_type: params.metalType
                  ? FieldMetaTypeFromSimple[params.metalType]
                  : 'jewelry_metal_type_other',
                ...(params.metalWeight && { jewelry_metal_weight: params.metalWeight }),
                ...(params.brand && { jewelry_brand: FieldBrandFromSimple[params.brand] }),
              },
            },
            2: secondSubset,
          },
        },
        sessionData: getSessionData(),
        // worthy-personalizer can't process event with earring in implementation moment
        // input_props: inputProps,
      },
      this.defaultOptions,
    );
  }

  static async register(params: SubmitState['registration'], bundleId: number) {
    return axios.post(
      `${REACT_APP_SITE_URL}/api/v2/sellers/registration`,
      {
        first_name: params.firstName,
        last_name: params.lastName,
        email: params.email,
        phone: params.phoneNumber || DEFAULT_PHONE,
        referer: CookieStore.getReferer(),
        friendbuy_referer: CookieStore.getRefererFriendbuy(),
        contact_preference: 'phone_call',
        bundle_id: bundleId,
        user_flow: null, // ?
        optional_phone_exp: true,
      },
      this.defaultOptions,
    );
  }

  static async updatePhoneNumber(params: SubmitState['registration'], userId: string | undefined) {
    return axios.post(
      `${REACT_APP_SITE_URL}/api/v2/sellers/user/update_missing_details`,
      {
        phone: params.phoneNumber || DEFAULT_PHONE,
        user_id: userId,
      },
      this.defaultOptions,
    );
  }

  static async uploadImage(itemId: string, data: FormData) {
    return axios
      .post<
        any,
        AxiosResponse<{
          paths: string[]; // 'https://cdn1.worthy.com/item_submission_photo/864297/1'
        }>
      >(`${REACT_APP_SITE_URL}/api/v2/sellers/item/${itemId}/upload_images`, data, {
        ...this.defaultOptions,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((r) => {
        if (r.status === 200 && r.data.paths) {
          return r.data;
        }
        throw new Error('uploadImage failed');
      });
  }

  static async removeImages(files: string[], itemId: string) {
    return axios
      .post<
        any,
        AxiosResponse<{
          removed: boolean; // true
          status: string; // 'ok'
        }>
      >(
        `${REACT_APP_SITE_URL}/api/v2/sellers/item/${itemId}/remove_images`,
        {
          files,
        },
        this.defaultOptions,
      )
      .then((r) => {
        if (r.status === 200) {
          return r.data;
        }
        throw new Error('removeImage failed');
      });
  }

  static async estimate(itemId: string) {
    return axios
      .get(`${REACT_APP_SITE_URL}/api/v2/sellers/item/${itemId}/estimate`, this.defaultOptions)
      .then((r) => r.data);
  }

  static async generateItemEstimationByPublicId(itemId: string) {
    return axios
      .post(
        `${REACT_APP_SITE_URL}/api/v2/sellers/item/${itemId}/generate_estimation`,
        {},
        this.defaultOptions,
      )
      .then((r) => r.data);
  }

  static async registerWithGoogle(credential: string) {
    return axios.post(
      `${REACT_APP_SITE_URL}/api/v2/sellers/register_with_google`,
      { credential },
      {
        withCredentials: true,
      },
    );
  }

  static async getUserDataFromGoogleCredential(credential: string) {
    return axios
      .get(
        `${process.env.REACT_APP_SUBMIT_BE_URL}/get-user-data-from-google-credential?credential=${credential}`,
        this.defaultOptions,
      )
      .then((r) => r.data);
  }
}
