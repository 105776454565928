import React, { useState } from 'react';
import { Item, List, Box, Typography, Icon } from '@worthy-npm/worthy-common-ui-components';
import { Step, StepContainer, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectItemType, selectShape, updateShape } from '../../../slices/submitSlice';
import { StepProps } from '../common';
import { DiamondShape } from '../../../data/ItemDetails';
import { parametrize } from '../../../lib/commonUtils';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';

const ShapeRound = '/images/icons/jewelery/diamond-shape/ic_round.svg';
const ShapePrincess = '/images/icons/jewelery/diamond-shape/ic_princess.svg';
const ShapeCushion = '/images/icons/jewelery/diamond-shape/ic_cusion.svg';
const ShapeMarquise = '/images/icons/jewelery/diamond-shape/ic_marquise.svg';
const ShapeEmerald = '/images/icons/jewelery/diamond-shape/ic_emerald.svg';
const ShapeOther = '/images/icons/jewelery/diamond-shape/ic_other.svg';

const Shapes = [
  {
    text: DiamondShape.Round,
    img: ShapeRound,
    code: DiamondShape.Round,
  },
  {
    text: DiamondShape.Princess,
    img: ShapePrincess,
    code: DiamondShape.Princess,
  },
  {
    text: DiamondShape.Cushion,
    img: ShapeCushion,
    code: DiamondShape.Cushion,
  },
  {
    text: DiamondShape.Marquise,
    img: ShapeMarquise,
    code: DiamondShape.Marquise,
  },
  {
    text: DiamondShape.Emerald,
    img: ShapeEmerald,
    code: DiamondShape.Emerald,
  },
  {
    text: DiamondShape.Other,
    img: ShapeOther,
    code: DiamondShape.Other,
  },
];

function StepShape({ next, idx, stepName, prev, title, stepCaption }: StepProps) {
  const dispatch = useAppDispatch();
  const shape = useAppSelector(selectShape);
  const itemType = useAppSelector(selectItemType);
  const [busy, setBusy] = useState(false);

  return (
    <StepContainer data-automation="shape-step">
      <Step>
        <StepTitle
          idx={idx}
          prev={prev}
          stepCaption={stepCaption}
          stepTitle={title || 'What is the shape of the largest diamond?'}
          stepName={stepName}
          stepNotice="Select an option below"
        />

        <StepGridContainer>
          <List spacing={2}>
            {Shapes.map((item) => (
              <Item
                data-automation={`shape-step-item-${parametrize(item.code)}`}
                key={item.code}
                variant="outlined"
                onClick={(e) => {
                  if (busy) return;
                  setBusy(true);
                  GA.select(itemType, stepName, idx, item.code);
                  dispatch(updateShape(item.code));
                  setTimeout(() => {
                    next(e);
                    setTimeout(() => {
                      setBusy(false);
                    }, 600);
                  }, 600);
                }}
                style={{ cursor: 'pointer' }}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img src={item.img} alt={item.text} height="28px" />
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      paddingLeft="12px"
                      textTransform="capitalize"
                    >
                      {item.text}
                    </Typography>
                  </Box>
                  <Box data-automation="shape-step-item-checked">
                    {item.code === shape ? <Icon.GreenCheck display="block" /> : null}
                  </Box>
                </Box>
              </Item>
            ))}
          </List>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepShape;
