import GA from '../data/GA';
import WorthyAPI from '../services/worthyAPI';

const SUPER_EXPENSIVE = 'Super Expensive';

export const generateEstimation = async (itemId: string): Promise<Record<string, unknown>> => {
  try {
    GA.estimationCalcution(itemId);

    const result = await WorthyAPI.generateItemEstimationByPublicId(itemId);
    const { item } = result;
    if (!item) {
      throw new Error('item missing after generateEstimation');
    }

    GA.estimatedSuccessfully(itemId, item?.estimation?.amount);

    return result;
  } catch (error) {
    GA.estimationFailed(itemId);
    window?.rollbar?.error(`generate estimation API failed: ${error}`);
    return {};
  }
};

export const postRegistrationItemEvents = (user: any, item: any) => {
  const isSuperExpensive = item.value_category === SUPER_EXPENSIVE;
  if (item?.submission_qualification === 0 && !isSuperExpensive) {
    const fallbackScore = 0.1083;
    const factor = item?.estimation?.fb_ml1_exp_factor;
    const score = item?.prereg_score || fallbackScore;
    const amount = (item?.estimation?.amount || 0) * score * factor;
    const conversionValue = amount * (item?.estimation?.fb_ml4_prereg_score_weight || 0);
    GA.firstQualifiedSubmission({
      userId: user?.id,
      projectedLeadValue: amount,
      itemsCount: user?.items_count,
      daysSinceUserCreated: user?.days_since_user_created,
      postregScore: score,
      estimationValue: item?.estimation?.amount,
      conversionValue,
    });
  }
};
