import * as React from 'react';
// import styled, { keyframes } from 'styled-components';
import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/react/macro';
import { LoadingProp } from '../styles/commonPropType';

const LoadingAnimation = '/images/animated_loading.svg';
const LoadingDiamondAnimation = '/images/animated_loading_diamond.gif';

const rotation = keyframes`
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
`;

const RotatingImage = styled.img`
  margin: auto;
  width: 100px;
  height: 100px;
  animation: ${rotation} 1s linear infinite;
`;

export function LoadingImg() {
  return <RotatingImage src={LoadingAnimation} alt="loading" />;
}

export function LoadingDiamondImg() {
  return <img data-automation="loading-overlay" src={LoadingDiamondAnimation} alt="loading" />;
}

export const LoadingContainer = styled.div`
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const LoadingOverlay = styled.div<LoadingProp>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  color: #000;
  background: ${(props: any) => (props.background ? props.background : '#fff')};
  z-index: 5;
  filter: saturation(40%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 200%;
`;
