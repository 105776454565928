/**
 * Layout component that may query for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import '../../styles/reset.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/400.css';
import '@fontsource/playfair-display/700.css';
import styled from '@emotion/styled/macro';
import Header from '../../components/header/header';

const Main = styled.main`
  display: flex;
  flex-direction: column;
`;

function Layout({ children }: { children: React.ReactNode }) {
  return (
    <Main>
      <Header />
      {children}
    </Main>
  );
}

export default Layout;
