/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { Alert, Box, Icon, Item, List, Typography } from "@worthy-npm/worthy-common-ui-components";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectType, updateType } from "../../slices/submitSlice";
import { ItemTypes, ItemTypesPlural } from "../../data/ItemDetails";
import GA from '../../data/GA';
import { StepProps } from "../steps/common";
import { Step, StepContainer, StepGridContainer } from "../../styles/common";
import StepTitle from "../stepTitle";
import { parametrize } from "../../lib/commonUtils";
import { links, LinksObject } from "./common";

const RingImg = 'images/icons/jewelery/type/ic_ring.svg';
const NecklaceImg = 'images/icons/jewelery/type/ic_necklace.svg';
const BraceletImg = 'images/icons/jewelery/type/ic_bracelet.svg';
const LooseDiamondImg = 'images/icons/jewelery/type/ic_loose_diamond.svg';
const EarringsImg = 'images/icons/jewelery/type/ic_earrings.svg';
const WatchImg = 'images/icons/jewelery/type/ic_lux_watch.svg';

export const Types = [
  {
    text: ItemTypes.Ring,
    img: RingImg,
    code: ItemTypes.Ring,
  },
  {
    text: ItemTypes.Necklace,
    img: NecklaceImg,
    code: ItemTypes.Necklace,
  },
  {
    text: ItemTypes.Bracelet,
    img: BraceletImg,
    code: ItemTypes.Bracelet,
  },
  {
    text: ItemTypes.LooseDiamond,
    img: LooseDiamondImg,
    code: ItemTypes.LooseDiamond,
  },
  {
    text: ItemTypes.Earrings,
    img: EarringsImg,
    code: ItemTypesPlural.Earrings,
  },
  {
    text: ItemTypes.Watch,
    img: WatchImg,
    code: ItemTypes.Watch,
  },
];

function StepSelectItemType({ idx, stepName, title, stepCaption }: StepProps) {
  const dispatch = useAppDispatch();
  const itemType = useAppSelector(selectType);
  const [busy, setBusy] = useState(false);

  return (
    <StepContainer data-automation="type-select-step" data-testid="type-select-step" style={{height: "unset"}}>
      <Step>
        <StepTitle
          idx={idx}
          prev={false}
          stepCaption={stepCaption}
          stepTitle={title || 'What type of jewelry do you have?'}
          stepName={stepName}
          stepNotice="Select an option below"
        />

        <StepGridContainer>
          <List spacing={2}>
            {Types.map((item) => (
              <Item
                data-automation={`type-select-step-item-${parametrize(item.code)}`}
                key={item.code}
                variant="outlined"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (busy) return;
                  setBusy(true);
                  GA.submitItemType(item.code)
                  dispatch(updateType(item.code));
                    setTimeout(() => {
                      setBusy(false);
                      window.location.href = links[_.camelCase(item.code) as keyof LinksObject];
                    }, 600);
                }}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img src={item.img} alt={item.text} height="28px" />
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      paddingLeft="12px"
                      textTransform="capitalize"
                    >
                      {item.text}
                    </Typography>
                  </Box>
                  <Box data-automation={`type-select-step-item-checked-${item.code}`} data-testid={`type-select-step-item-checked-${item.code}`}>
                     {item.code === itemType ? <Icon.GreenCheck display="block" /> : null}
                  </Box>
                </Box>
              </Item>
            ))}
          </List>

          <Alert style={{ marginTop: '20px' }} variant="outlined" severity="info">
            <Typography>You will be able to add more jewelry later in the process.</Typography>
          </Alert>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepSelectItemType;
