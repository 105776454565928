import React, { useState } from 'react';
import { GridItem, Typography, Grid, Box, Icon } from '@worthy-npm/worthy-common-ui-components';
import { Step, StepContainer, StepGridContainer } from '../../styles/common';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectItemType, selectMetalType, updateMetalType } from '../../slices/submitSlice';
import { StepProps } from './common';
import { MetalTypes } from '../../data/ItemDetails';
import StepTitle from '../stepTitle';
import { parametrize } from '../../lib/commonUtils';
import GA from '../../data/GA';

const Items = Object.values(MetalTypes);

function StepMetalType({ next, idx, stepName, prev, stepCaption }: StepProps) {
  const dispatch = useAppDispatch();
  const metalType = useAppSelector(selectMetalType);
  const itemType = useAppSelector(selectItemType);
  const [busy, setBusy] = useState(false);

  return (
    <StepContainer data-automation="metal-type-step">
      <Step>
        <StepTitle
          idx={idx}
          prev={prev}
          stepTitle={`What metal are your ${itemType.toLowerCase()} made of?`}
          stepName={stepName}
          stepNotice="Select an option below"
          stepCaption={stepCaption}
        />

        <StepGridContainer>
          <Grid display="grid" gridTemplateColumns="50% 50%">
            {Items.map((item: string) => (
              <GridItem
                data-automation={`metal-type-step-item-${parametrize(item)}`}
                key={item}
                style={{
                  margin: '4px',
                  backgroundColor: 'white',
                  height: '60px',
                  width: 'auto',
                  padding: '14px 7px',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (busy) return;
                  setBusy(true);
                  GA.select(itemType, stepName, idx, item);
                  dispatch(updateMetalType(item));
                  setTimeout(() => {
                    next(e);
                    setTimeout(() => {
                      setBusy(false);
                    }, 600);
                  }, 600);
                }}
              >
                <Box
                  display="flex"
                  padding="15px 25px"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  position="relative"
                >
                  <Typography variant="subtitle1" color="text.primary">
                    {item}
                  </Typography>

                  <Box
                    data-automation="metal-type-step-item-checked"
                    position="absolute"
                    right="1px"
                  >
                    {item === metalType ? <Icon.GreenCheck display="block" /> : null}
                  </Box>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepMetalType;
