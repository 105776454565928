import React from 'react';
import styled from '@emotion/styled/macro';
import { FormContent } from './commonText';

export const Step = styled.div`
  display: block;
  align-items: center;
  overflow: auto;
  width: 100%;
  padding: 14px 24px;
`;

export const NextStepButton = styled.div<any>`
  text-align: center;
  margin-top: 32px;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

export const StepGridContainer = styled.div`
  display: block;
  max-width: 500px;
  margin: 30px auto auto;
`;

export const FormContentNarrow = styled(FormContent)`
  max-width: 310px;
`;
