import React, { useState } from 'react';
import {
  TextField,
  Button,
  Alert,
  Typography,
  Icon,
} from '@worthy-npm/worthy-common-ui-components';
import {
  FormContentNarrow,
  Step,
  NextStepButton,
  StepContainer,
  StepGridContainer,
} from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectItemType, updateDiamondNumber } from '../../../slices/submitSlice';
import { StepProps } from '../common';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';

function DiamondNumber({ next, stepName, idx, prev, title, stepCaption }: StepProps) {
  const [diamondNumber, setDiamondNumber] = useState<number>(0);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [nextAvailability, setNextAvailability] = useState<boolean>(false);
  const [busy, setBusy] = useState(false);

  const itemType = useAppSelector(selectItemType);
  const dispatch = useAppDispatch();

  const validate = (value: string): void => {
    const parsedValue = Number(value);
    if (
      Number.isNaN(parsedValue) ||
      !Number.isInteger(parsedValue) ||
      parsedValue > 100 ||
      parsedValue < 1
    ) {
      setDiamondNumber(0);
      setIsValid(false);
      setNextAvailability(false);
    } else {
      setDiamondNumber(Math.trunc(parsedValue));
      setIsValid(true);
      setNextAvailability(true);
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      validate(e.target.value);

      if (isValid && nextAvailability) {
        dispatch(updateDiamondNumber(diamondNumber));
        next(e);
      }
    }
  };

  return (
    <StepContainer data-automation="stone-number-step">
      <Step>
        <StepTitle
          idx={idx}
          stepCaption={stepCaption}
          prev={prev}
          stepTitle={title || `Great! How many diamonds are set in your ${itemType.toLowerCase()}?`}
          stepName={stepName}
          stepNotice={`Please enter the total number of all diamonds ${
            itemType === 'Earrings' ? 'in one earring' : `in your ${itemType.toLowerCase()}`
          }`}
        />
        <StepGridContainer>
          <FormContentNarrow>
            <TextField
              autoFocus
              data-automation="stone-number-input"
              inputProps={{
                inputMode: 'numeric',
                type: 'number',
                step: 1,
                min: 1,
                max: 100,
              }}
              error={!isValid}
              placeholder="00"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                validate(e.target.value);
              }}
              onKeyDown={handleEnter}
              sx={{ input: { textAlign: 'center', minWidth: '10.3em' } }}
            />
          </FormContentNarrow>

          {!isValid && (
            <Alert style={{ marginTop: '20px' }} severity="error">
              <Typography>Error: You can only enter a whole number</Typography>
            </Alert>
          )}

          <Alert style={{ marginTop: '20px' }} variant="outlined" severity="info">
            <Typography>Tip: You can give an approximate number if you’re not sure</Typography>
          </Alert>

          <NextStepButton>
            <Button
              disableElevation
              data-automation="stone-number-next-button"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (diamondNumber > 0 && !busy) {
                  setBusy(true);
                  GA.submitNext(itemType, stepName, idx, diamondNumber.toString());
                  dispatch(updateDiamondNumber(diamondNumber));
                  next(e);
                  setTimeout(() => {
                    setBusy(false);
                  }, 150);
                }
              }}
              variant="contained"
              size="large"
              disabled={!nextAvailability}
              sx={{
                svg: { marginLeft: '12px' },
              }}
            >
              Continue
              <Icon.ContinueIcon />
            </Button>
          </NextStepButton>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default DiamondNumber;
