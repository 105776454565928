import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  mainTheme,
} from '@worthy-npm/worthy-common-ui-components';
import { useRollbar } from '@rollbar/react';
import { useAppSelector } from '../app/hooks';
import { selectSubmitData } from '../slices/submitSlice';

function NetworkErrorImage() {
  return (
    <img
      data-automation="network-error-overlay"
      src="/images/networkError.svg"
      alt="network error"
      style={{ marginBottom: '25px' }}
    />
  );
}

function RetryDialog(props: any) {
  const { retryHook, restartHook } = props;
  const [isLast, setIsLast] = useState(false);
  const rollbar = useRollbar();
  const isInitRun = useRef(true);
  const submitData = useAppSelector(selectSubmitData);
  const delay = 4000;

  const attemptRetry = (count = 0) => {
    if (count < 3) {
      setTimeout(() => {
        rollbar.error('Retrying to submit the item', {
          attempt: count + 1,
          submitData,
        });

        retryHook().then((res: any) => {
          if (!res.success) {
            attemptRetry(count + 1);
          }
        });
      }, delay);
    } else {
      setIsLast(true);
    }
  };

  useEffect(() => {
    if (isInitRun.current) {
      isInitRun.current = false;
      attemptRetry();
    }
  }, []);

  return (
    <Box
      data-automation="retry-overlay"
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      zIndex="5"
      bgcolor={mainTheme.palette.background.default}
    >
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        height="100%"
        flexWrap="nowrap"
        alignItems="center"
      >
        <Box textAlign="center" overflow="auto" width="100%" padding="14px 24px 50px 24px">
          <NetworkErrorImage />
          <Typography
            fontFamily="Playfair Display"
            fontWeight="400"
            fontSize="20px"
            color={mainTheme.palette.primary.main}
            marginBottom="15px"
          >
            We&apos;re sorry
          </Typography>
          <Typography variant="h1" fontSize="28px" marginBottom="20px">
            As in life, so to in technology things go wrong...
          </Typography>

          <Typography variant="body2" marginBottom="35px" fontSize="16px">
            {isLast
              ? "Due to a technical failure, your item details weren't saved and we kindly ask you to resubmit your information."
              : 'Due to a technical failure, please wait a few seconds while the system reconnects.'}
          </Typography>

          {isLast ? (
            <Button
              disableElevation
              data-automation="restart-button"
              onClick={() => {
                restartHook();
                rollbar.error('Restart flow', { submitData });
              }}
              variant="outlined"
              size="large"
              sx={{ backgroundColor: mainTheme.palette.common.white }}
            >
              Try Again
            </Button>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" marginTop="80px">
              <CircularProgress size="20px" />
              <Typography marginLeft="10px" color={mainTheme.palette.grey['400']}>
                Reconnecting
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
export default RetryDialog;
