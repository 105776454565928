import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import {
  BlueWordButton,
  StepTitleText,
  Step,
  NextStepButton,
  StepCTAContainer,
  ResponsiveSquareTile,
  ResponsiveTileContainer,
} from '../../styles/commonText';

import { MediumLinkButton } from '../../clarity';
import { DiamondClarity } from '../../data/ItemDetails';
import CenterDiamond from '../popup/center_diamond';
import { useMobileVersion, useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectItemType, selectSubmitData, updateClarity } from '../../slices/submitSlice';
import { StepProps } from './common';
import CTAContainer from '../CTAContainer';
import { IsMobileProp } from '../../styles/commonPropType';
import GA from '../../data/GA';

const Tile = styled(ResponsiveSquareTile)`
  max-width: 66px;
  max-height: 66px;
  ${(props: any) =>
    props.isMobile &&
    css`
      width: 20%;
    `}
`;

const TileContainer = styled(ResponsiveTileContainer)<IsMobileProp>`
  gap: 12px;
  max-width: 500px;
  flex-wrap: wrap;
  ${(props: any) =>
    props.isMobile &&
    css`
      max-width: 370px;
    `}
`;

interface IconClarity {
  code: string;
  caption: string;
}

const Clarity = [
  {
    code: DiamondClarity.FL,
    caption: 'FL',
  },
  {
    code: DiamondClarity.IF,
    caption: 'IF',
  },
  {
    code: DiamondClarity.VVS1,
    caption: 'VVS1',
  },
  {
    code: DiamondClarity.VVS2,
    caption: 'VVS2',
  },
  {
    code: DiamondClarity.VS1,
    caption: 'VS1',
  },
  {
    code: DiamondClarity.VS2,
    caption: 'VS2',
  },
  {
    code: DiamondClarity.SI1,
    caption: 'SI1',
  },
  {
    code: DiamondClarity.SI2,
    caption: 'SI2',
  },
  {
    code: DiamondClarity.I1,
    caption: 'I1',
  },
  {
    code: DiamondClarity.I2,
    caption: 'I2',
  },
  {
    code: DiamondClarity.I3,
    caption: 'I3',
  },
];

function StepClarity({ next, idx, stepName, prev }: StepProps) {
  const [popupVisible, showPopup] = useState<boolean>(false);
  const { clarity } = useAppSelector(selectSubmitData);
  const dispatch = useAppDispatch();
  const isMobile = useMobileVersion();
  const itemType = useAppSelector(selectItemType);

  return (
    <StepCTAContainer isMobile={isMobile}>
      <Step>
        <StepTitleText>
          <span>Noted! What about your&nbsp;</span>
          <BlueWordButton
            data-automation="clarity-center-diamond-button"
            onClick={() => showPopup(!popupVisible)}
          >
            diamond
          </BlueWordButton>
          <span>’s clarity grade?</span>
        </StepTitleText>
        <TileContainer isMobile={isMobile}>
          {Clarity.map((c: IconClarity) => (
            <Tile
              isMobile={isMobile}
              data-automation={`clarity-item-select-${c.code}`}
              selected={c.code === clarity}
              key={c.code}
              text={c.caption}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                dispatch(updateClarity(c.code));
                GA.select(itemType, stepName, idx, c.code);
                next(e);
              }}
            />
          ))}
        </TileContainer>
        {popupVisible && <CenterDiamond setPopupVisible={showPopup} />}
      </Step>
      <CTAContainer idx={idx} prev={prev}>
        <NextStepButton onClick={next} data-automation="wizard-next-button">
          <MediumLinkButton
            onClick={() => {
              dispatch(updateClarity(DiamondClarity.NotSure));
              GA.clarityNotSure(stepName, idx);
            }}
            text={"I'm not sure"}
          />
        </NextStepButton>
      </CTAContainer>
    </StepCTAContainer>
  );
}

export default StepClarity;
