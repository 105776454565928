import React from 'react';
import styled from '@emotion/styled/macro';
import {
  GENERAL_COLOR_VALUES,
  GENERAL_FONT_VALUES,
  GeneralColorNames,
  GeneralFontNames,
  SpacingSizes,
} from '../../clarity';

const ProgressBarContainer = styled.div`
  width: 94%;
  height: 30px;
  margin: ${SpacingSizes.md} auto ${SpacingSizes.md};
  border-radius: 30px;
  background-color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_200]};
  display: flex;
  align-items: center;
`;

const Bar = styled.div`
  height: 30px;
  border-radius: 30px;
  background-color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_500]};
  transition: width 1s, height 0.2s;
  text-align: right;
  padding-right: ${SpacingSizes.xs};
  padding-top: ${SpacingSizes.x3s};
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.opensans_16_regular]};
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.worthy_white]};
`;
function ProgressBar({ indx, caption, count }: { indx: number; caption: string; count: number }) {
  return (
    <ProgressBarContainer data-automation="progress-bar">
      <Bar
        style={{
          width: `${((indx + 1) * 100) / count}%`,
        }}
      >
        {caption}
      </Bar>
    </ProgressBarContainer>
  );
}

export default ProgressBar;
