import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

import {
  BlueWordButton,
  Step,
  StepTitleText,
  ResponsiveSquareTile,
  ResponsiveTileContainer,
  StepCTAContainer,
} from '../../styles/commonText';
import CenterDiamond from '../popup/center_diamond';
import { useMobileVersion, useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectItemType, selectSubmitData, updateShape } from '../../slices/submitSlice';
import { DiamondShape } from '../../data/ItemDetails';
import { StepProps } from './common';
import CTAContainer from '../CTAContainer';
import { IsMobileProp } from '../../styles/commonPropType';
import ListComponent from '../listComponent/list';
import GA from '../../data/GA';

const ShapeRound = 'images/icons/shape_round.svg';
const ShapePrincess = 'images/icons/shape_princess.svg';
const ShapeCushion = 'images/icons/shape_cushion.svg';
const ShapeMarquise = 'images/icons/shape_marquise.svg';
const ShapeEmerald = 'images/icons/shape_emerald.svg';
const ShapeOther = 'images/icons/shape_other.svg';

const MShapeRound = 'images/icons/jewelery/diamond-shape/ic_round.svg';
const MShapePrincess = 'images/icons/jewelery/diamond-shape/ic_princess.svg';
const MShapeCushion = 'images/icons/jewelery/diamond-shape/ic_cusion.svg';
const MShapeMarquise = 'images/icons/jewelery/diamond-shape/ic_marquise.svg';
const MShapeEmerald = 'images/icons/jewelery/diamond-shape/ic_emerald.svg';
const MShapeOther = 'images/icons/jewelery/diamond-shape/ic_other.svg';

const ShapeRoundSelected = 'images/icons/focus/shape_round.svg';
const ShapePrincessSelected = 'images/icons/focus/shape_princess.svg';
const ShapeCushionSelected = 'images/icons/focus/shape_cushion.svg';
const ShapeMarquiseSelected = 'images/icons/focus/shape_marquise.svg';
const ShapeEmeraldSelected = 'images/icons/focus/shape_emerald.svg';
const ShapeOtherSelected = 'images/icons/focus/shape_other.svg';

const Tile = styled(ResponsiveSquareTile)`
  max-width: 110px;
  ${(props: any) =>
    props.isMobile &&
    css`
      width: 29%;
    `}
`;
const TileContainer = styled(ResponsiveTileContainer)<IsMobileProp>`
  ${(props: any) =>
    props.isMobile &&
    css`
      max-width: 370px;
      flex-wrap: wrap;
    `}
  & img {
    width: 30% !important;
  }
`;

interface IIcon {
  code: string;
  icon: string;
  iconSelected: string;
}

const Shapes = [
  {
    code: DiamondShape.Round,
    icon: ShapeRound,
    iconM: MShapeRound,
    iconSelected: ShapeRoundSelected,
  },
  {
    code: DiamondShape.Princess,
    icon: ShapePrincess,
    iconM: MShapePrincess,
    iconSelected: ShapePrincessSelected,
  },
  {
    code: DiamondShape.Cushion,
    icon: ShapeCushion,
    iconM: MShapeCushion,
    iconSelected: ShapeCushionSelected,
  },
  {
    code: DiamondShape.Marquise,
    icon: ShapeMarquise,
    iconM: MShapeMarquise,
    iconSelected: ShapeMarquiseSelected,
  },
  {
    code: DiamondShape.Emerald,
    icon: ShapeEmerald,
    iconM: MShapeEmerald,
    iconSelected: ShapeEmeraldSelected,
  },
  {
    code: DiamondShape.Other,
    icon: ShapeOther,
    iconM: MShapeOther,
    iconSelected: ShapeOtherSelected,
  },
];

function StepShape({ next, idx, stepName, prev }: StepProps) {
  const [popupVisible, showPopup] = useState<boolean>(false);
  const { shape } = useAppSelector(selectSubmitData);
  const itemType = useAppSelector(selectItemType);
  const dispatch = useAppDispatch();
  const isMobile = useMobileVersion();
  return (
    // <StepCTAContainer isMobile={isMobile} data-automation="carat-step">

    <StepCTAContainer isMobile={isMobile} data-automation="shape-step">
      <Step>
        <StepTitleText isMobile={isMobile}>
          <span>What is your&nbsp;</span>
          <BlueWordButton
            data-automation="shape-center-diamond-button"
            onClick={() => showPopup(!popupVisible)}
          >
            center diamond
          </BlueWordButton>
          <span>’s shape?</span>
        </StepTitleText>
        {isMobile ? (
          <ListComponent
            next={next}
            idx={idx}
            stepName={stepName}
            itemsConfig={Shapes}
            selector={shape}
            updateFn={updateShape}
          />
        ) : (
          <TileContainer isMobile={isMobile}>
            {Shapes.map((s: IIcon) => (
              <Tile
                isMobile={isMobile}
                data-automation={`shape-item-select-${s.code}`}
                selected={s.code === shape}
                key={s.code}
                icon={s.icon}
                iconSelected={s.iconSelected}
                text={s.code}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  GA.select(itemType, stepName, idx, s.code);
                  dispatch(updateShape(s.code));
                  next(e);
                }}
              />
            ))}
          </TileContainer>
        )}
        {popupVisible && <CenterDiamond setPopupVisible={showPopup} />}
      </Step>
      <CTAContainer idx={idx} prev={prev} />
    </StepCTAContainer>
  );
}

export default StepShape;
