import _ from 'lodash';
import sha1 from 'sha1';
import { getItemType } from '../lib/commonUtils';
import { BaseEvent, pushToDataLayer, sendGeneralGAEvent } from './events';

const stepNameFormat = (stepName: string) => {
  const name = (stepName || '').replace('Step', '');
  return name.substring(0, 1).toLowerCase() + name.substring(1);
};

const DOMAINS = {
  authenticator: 'authenticator',
  submission: 'submission',
  contetnt: 'content',
  photoService: 'photoService',
};

class GA {
  /// data events
  static userPhone(data: { userPhone: string; userId: number }) {
    BaseEvent.data(data);
  }

  static userData(data: {
    userEmail: string;
    userFullName: string;
    userPhone: string;
    userId: number;
  }) {
    const emailSha1Encryption = sha1(data.userEmail);
    pushToDataLayer({
      event: 'userData',
      userId: data.userId,
      fullName: data.userFullName,
      email: data.userEmail,
      phone: data.userPhone,
      emailSha1Encryption,
    });
    BaseEvent.data({ ...data, emailSha1Encryption });
  }

  static firstQualifiedSubmission(data: {
    userId: number;
    projectedLeadValue: number;
    itemsCount: number;
    daysSinceUserCreated: number;
    postregScore: number;
    estimationValue: number;
    conversionValue: number;
  }) {
    if (!data?.estimationValue) {
      // not relevant when Items missing estimation
      return;
    }

    pushToDataLayer({
      event: 'hqPurchase',
      prereg_score: data.postregScore,
      Item_estimated_value: data.estimationValue,
      userId: data.userId,
    });
    pushToDataLayer({
      event: 'ml4PurchaseEvent',
      ML4_conversion_value: data.conversionValue,
    });

    pushToDataLayer({
      event: 'GTM event To GA - funnel stage',
      GA_event_category: 'funnel stage',
      GA_event_action: 'submission',
      GA_event_value: data.projectedLeadValue,
      projectedLeadValue: data.projectedLeadValue,
      items_count: data.itemsCount,
      days_since_user_created: data.daysSinceUserCreated,
      userId: data.userId,
      GA_event_label: 'first qualified submission - new lead',
    });
    pushToDataLayer({
      category: 'funnel stage',
      action: 'submission',
      event: 'ga4_funnelStage',
      value: data.projectedLeadValue,
      items_count: data.itemsCount,
      days_since_user_created: data.daysSinceUserCreated,
      userId: data.userId,
      label: 'first qualified submission - new lead',
    });

    BaseEvent.data(data);
  }

  static googleData(data: {
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    userPhone: string;
  }) {
    pushToDataLayer({
      email: data.userEmail,
      firstName: data.userFirstName,
      lastName: data.userLastName,
      phoneNumber: data.userPhone,
    });
    BaseEvent.data(data);
  }

  static estimationCalcution(itemId: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_value: 'funnelStep',
      GA_event_category: 'Item submission form - new',
      GA_event_action: 'Post submission - Calculating item estimation',
      GA_event_label: itemId,
    });
  }

  static estimatedSuccessfully(itemId: string, estimationValue: number) {
    pushToDataLayer({
      GA_event_category: 'Item submission form - new',
      GA_event_action: 'Post submission - Successful item estimation',
      GA_event_label: itemId,
      Item_estimated_value: estimationValue,
      item_id: itemId,
      event: 'GTM event To GA',
    });
  }

  static estimationFailed(itemId: string) {
    pushToDataLayer({
      event: 'GTM event To GA',
      GA_event_value: 'funnelStep',
      GA_event_category: 'Item submission form - new',
      GA_event_action: 'Post submission - No estimation',
      GA_event_label: itemId,
    });
  }

  static flow() {
    sendGeneralGAEvent({
      action: 'workflow',
      stepName: 'short',
      idx: 0,
      itemType: 'v1',
    });
  }

  static stepView(step: string, stepIndex: number) {
    const itemType = getItemType();
    sendGeneralGAEvent({
      action: 'view',
      stepName: step,
      idx: stepIndex,
      itemType: _.upperFirst(itemType),
    });
    if (step === 'StepRegistration') {
      BaseEvent.open([DOMAINS.authenticator, stepNameFormat(step)]);
    } else {
      BaseEvent.open([DOMAINS.submission, itemType, stepNameFormat(step)]);
    }
  }

  static typeSelectionView() {
    sendGeneralGAEvent({
      action: 'view',
      stepName: 'Jewelry type',
      idx: 0,
      itemType: 'Jewelry type',
    });
    BaseEvent.open([DOMAINS.submission, 'typeSelection']);
  }

  /// click events
  static previousClick(page: string, step: string, stepIndex: number) {
    const itemType = getItemType();
    sendGeneralGAEvent({
      action: 'click',
      clickText: 'Previous',
      stepName: step,
      idx: stepIndex,
      itemType: _.upperFirst(itemType),
    });
    BaseEvent.navigate([DOMAINS.submission, itemType, stepNameFormat(step)], 'back');
  }

  static select(page: string, step: string, stepIndex: number, value: any) {
    const itemType = getItemType();
    sendGeneralGAEvent({
      action: 'click',
      stepName: step,
      idx: stepIndex,
      clickText: value,
      itemType: _.upperFirst(itemType),
    });
    BaseEvent.select(
      [DOMAINS.submission, itemType, stepNameFormat(step)],
      stepNameFormat(step),
      value,
    );
  }

  static intercomClick(page: string, step: string, stepIndex: number) {
    const itemType = getItemType();
    sendGeneralGAEvent({
      action: 'click',
      stepName: step,
      idx: stepIndex,
      clickText: 'I need help with this',
    });
    BaseEvent.navigate([DOMAINS.submission, itemType, stepNameFormat(step)], 'intercom');
  }

  static submitItemType(value: string) {
    sendGeneralGAEvent({
      action: 'click',
      stepName: 'Jewelry type',
      idx: 0,
      clickText: value,
    });

    sendGeneralGAEvent({
      action: 'click',
      stepName: 'Jewelry type',
      idx: 0,
      clickText: 'Next',
    });
    BaseEvent.select([DOMAINS.submission, 'typeSelection'], 'itemType', value);
  }

  static registrationSuccess(
    page: string,
    step: string,
    stepIndex: number,
    leadId: number,
    userId: number,
  ) {
    sendGeneralGAEvent({
      action: 'success',
      idx: stepIndex,
      stepName: step,
      leadId,
      userId,
      itemType: page,
    });
    BaseEvent.success([DOMAINS.authenticator, 'registration']);
  }

  static phoneSuccess(page: string, step: string, stepIndex: number) {
    sendGeneralGAEvent({
      action: 'phone success',
      idx: stepIndex,
      stepName: step,
      itemType: page,
    });
  }

  static continue(page: string, step: string, stepIndex: number) {
    sendGeneralGAEvent({
      action: 'continue',
      idx: stepIndex,
      stepName: step,
      itemType: page,
    });
  }

  static colorNotSure(step: string, stepIndex: number) {
    sendGeneralGAEvent({
      action: 'click',
      idx: stepIndex,
      stepName: step,
      clickText: "I'm not sure",
    });
  }

  static clarityNotSure(step: string, stepIndex: number) {
    sendGeneralGAEvent({
      action: 'click',
      idx: stepIndex,
      stepName: step,
      clickText: "I'm not sure",
    });
  }

  static brandSkip(page: string, step: string, stepIndex: number) {
    sendGeneralGAEvent({
      action: 'click',
      stepName: step,
      idx: stepIndex,
      clickText: 'none',
      itemType: page,
    });
  }

  static certificateSkip(page: string, step: string, stepIndex: number) {
    sendGeneralGAEvent({
      action: 'click',
      stepName: step,
      idx: stepIndex,
      clickText: 'none',
      itemType: page,
    });
  }

  static submitNext(page: string, step: string, stepIndex: number, value: any) {
    const itemType = getItemType();
    sendGeneralGAEvent({
      action: 'click',
      idx: stepIndex,
      stepName: step,
      clickText: value,
      itemType: _.upperFirst(itemType),
    });

    sendGeneralGAEvent({
      action: 'click',
      idx: stepIndex,
      stepName: step,
      clickText: 'Next',
      itemType: _.upperFirst(itemType),
    });
    BaseEvent.input(
      [DOMAINS.submission, itemType, stepNameFormat(step)],
      stepNameFormat(step),
      value,
    );
  }

  static submitGIANumber(page: string, step: string, stepIndex: number, value: any) {
    sendGeneralGAEvent({
      action: 'click',
      idx: stepIndex,
      stepName: step,
      clickText: value,
    });

    sendGeneralGAEvent({
      action: 'click',
      idx: stepIndex,
      stepName: step,
      clickText: 'Confirm',
    });
    BaseEvent.input([DOMAINS.submission, page, 'certificate'], stepNameFormat(step), value);
  }

  static focus(step: string, stepIndex: number, field: string) {
    sendGeneralGAEvent({
      action: 'click',
      clickText: `${field} verification form field focused`,
      idx: stepIndex,
      stepName: step,
    });
  }

  static invalidCarat(step: string, stepIndex: number) {
    sendGeneralGAEvent({
      action: 'click',
      stepName: step,
      idx: stepIndex,
      clickText: 'Invalid diamond carat weight filled',
    });
  }

  static uploadPhotoEvents(
    page: string,
    step: string,
    stepIndex: number,
    value:
      | 'Upload button'
      | 'failed to upload'
      | 'remove button'
      | 'successful remove'
      | 'failed to remove',
  ) {
    sendGeneralGAEvent({
      action: 'click',
      stepName: step,
      idx: stepIndex,
      clickText: value,
      itemType: page,
    });
  }

  static successfullPhotoUpload(page: string, step: string, stepIndex: number) {
    sendGeneralGAEvent({
      action: 'click',
      stepName: step,
      idx: stepIndex,
      clickText: 'successful upload',
      itemType: page,
    });
    BaseEvent.success([DOMAINS.photoService, 'photoUpload']);
  }

  static skipPhotoUpload(page: string, step: string, stepIndex: number) {
    sendGeneralGAEvent({
      action: 'click',
      stepName: step,
      idx: stepIndex,
      clickText: 'skip',
      itemType: page,
    });
    BaseEvent.navigate([DOMAINS.photoService, 'photoUpload'], 'skip');
  }

  static continuePhotoUpload(page: string, step: string, stepIndex: number) {
    sendGeneralGAEvent({
      action: 'click',
      stepName: step,
      idx: stepIndex,
      clickText: 'continue',
      itemType: page,
    });
    BaseEvent.navigate([DOMAINS.photoService, 'photoUpload'], 'continue');
  }

  static successfullGoogleRegister(
    page: string,
    step: string,
    stepIndex: number,
    leadId: number,
    userId: number | undefined,
    isUserLogin: boolean,
  ) {
    sendGeneralGAEvent({
      action: `successful Google ${isUserLogin ? 'login' : 'registration'}`,
      idx: stepIndex,
      stepName: step,
      clickText: 'Google button',
      itemType: page,
      leadId,
      userId,
    });
    // also send the same event as the regular
    sendGeneralGAEvent({
      action: `success${isUserLogin ? ' login' : ''}`,
      idx: stepIndex,
      stepName: step,
      itemType: page,
      leadId,
      userId,
    });
    if (isUserLogin) {
      BaseEvent.success([DOMAINS.authenticator, 'login', 'google']);
    } else {
      BaseEvent.success([DOMAINS.authenticator, 'registration', 'google']);
    }
  }

  static successfullItemSubmission() {
    BaseEvent.success([DOMAINS.submission, 'submitItem']);
  }

  static registrationFields(
    page: string,
    step: string,
    stepIndex: number,
    register: Record<string, any>,
  ) {
    Object.values(register).forEach((field) => {
      sendGeneralGAEvent({
        action: 'click',
        stepName: step,
        idx: stepIndex,
        clickText: field.value,
        itemType: page,
      });
    });
  }

  static GIANumberPopupView(stepIndex: number) {
    /// check if need to be deleted
    sendGeneralGAEvent({
      action: 'view',
      stepName: 'GIA Certificate # Popup',
      idx: stepIndex,
    });
  }
}

export default GA;
