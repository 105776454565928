import * as React from 'react';

import styled from '@emotion/styled/macro';

const LoadingGif = 'images/generateEstimationLoader.gif';

const Image = styled.img`
  margin: 0 auto;
`;

function Loading() {
  return <Image src={LoadingGif} alt="loading" />;
}

export default Loading;
