import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { PhotoUpload } from '@worthy-npm/worthy-ui-components';
import { StepTitleText, Step, StepCTAContainer } from '../../styles/commonText';
import { StepProps } from './common';
import CTAContainer from '../CTAContainer';
import { MediumPrimaryButton } from '../../clarity';
import WorthyAPI from '../../services/worthyAPI';
import { useMobileVersion, useAppDispatch, useAppSelector } from '../../app/hooks';
import { SecondaryButton } from '../../styles/commonStyledComp';
import {
  PhotoOldI,
  selectItemType,
  selectPhotosOld,
  selectSubmitAPI,
  selectType,
  updatePhotosOld,
} from '../../slices/submitSlice';
import StepLoading from './step_loading';
import GA from '../../data/GA';

const UploadContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
`;
const UploadItem = styled.div`
  max-width: 135px;
  width: 100%;
`;
const SubTitle = styled.div`
  font-family: Roboto, serif;
  text-align: center;
  max-width: 600px;
  align-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const OptionalTitle = styled.span`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  color: #999999;
`;
const PhotoUploadStepTitleText = styled(StepTitleText)`
  margin-bottom: 7px;
`;

const uploadImage = async (itemId: string, image: File) => {
  const formData = new FormData();
  formData.append('file', image);
  return WorthyAPI.uploadImage(itemId, formData).catch((e) => {
    console.error(e);
    throw new Error(e);
  });
};
const removeImage = async (itemId: string, path: string) => {
  return WorthyAPI.removeImages([path], itemId).catch((e) => {
    console.error(e);
    throw new Error(e);
  });
};

function StepPhotoUpload({ next, idx, stepName, prev }: StepProps) {
  const dispatch = useAppDispatch();
  const { itemId, forcePhoto, itemAutoRejected } = useAppSelector(selectSubmitAPI);
  const type = useAppSelector(selectType);
  const photos = useAppSelector(selectPhotosOld);
  const isMobile = useMobileVersion();
  const [changeOrder, updateChangeOrder] = useState(0);
  const itemType = useAppSelector(selectItemType);

  const sendEvent = (
    text:
      | 'Upload button'
      | 'failed to upload'
      | 'remove button'
      | 'successful remove'
      | 'failed to remove',
  ) => GA.uploadPhotoEvents(itemType, stepName, idx, text);

  useEffect(() => {
    if (itemAutoRejected) {
      next({} as any);
    }
  }, [itemAutoRejected, next]);

  useEffect(() => {
    const newOrderedArray = [...photos].sort((a, b) => +!!b.src - +!!a.src);
    dispatch(updatePhotosOld(newOrderedArray));
  }, [changeOrder]);

  const upload = (file: File, clb: any, photo: PhotoOldI) => {
    sendEvent('Upload button');
    uploadImage(itemId, file)
      .then((r) => {
        const index = photos.findIndex((i) => i.id === photo.id);
        if (index !== -1) {
          const newPhotos = [...photos];
          newPhotos.splice(index, 1, {
            id: photos[index].id,
            src: r.paths[0],
          });
          dispatch(updatePhotosOld(newPhotos));
        }
        clb();
        GA.successfullPhotoUpload(itemType, stepName, idx);
      })
      .catch(() => {
        clb(true);
        GA.uploadPhotoEvents(itemType, stepName, idx, 'failed to upload');
      });
  };

  const remove = (clb: any, photo: PhotoOldI) => {
    sendEvent('remove button');
    if (!photo.src) {
      clb(false);
      return;
    }

    removeImage(itemId, photo.src)
      .then(() => {
        const index = photos.findIndex((i) => i.id === photo.id);
        if (index !== -1) {
          const newPhotos = [...photos];
          newPhotos.splice(index, 1, {
            id: photos[index].id,
            src: null,
          });
          dispatch(updatePhotosOld(newPhotos));
        }
        clb();
        sendEvent('successful remove');
      })
      .catch(() => {
        clb(true);
        sendEvent('failed to remove');
      });
  };

  const nextClb = (clb: any) => {
    GA.continuePhotoUpload(itemType, stepName, idx);
    next({} as any);
    clb();
  };

  const afterUpload = () => {
    updateChangeOrder(changeOrder + 1);
  };
  const afterRemove = () => {
    updateChangeOrder(changeOrder + 1);
  };

  return itemAutoRejected ? (
    <StepLoading />
  ) : (
    <StepCTAContainer
      isMobile={isMobile}
      isVCentred={type === 'earrings'}
      data-automation="upload-photo-step"
    >
      <Step>
        <PhotoUploadStepTitleText>
          Upload Photos
          {!forcePhoto ? <OptionalTitle> &nbsp; (Optional)</OptionalTitle> : ''}
        </PhotoUploadStepTitleText>
        <SubTitle>
          Please upload at least one top view photo of your {type} and of any documentation you may
          have. (1-4 photos)
        </SubTitle>
        <UploadContainer>
          {photos.map(
            (photo, i) =>
              (i === 0 || photos[0].src) && (
                <UploadItem key={`${photo.id}-${itemId}`}>
                  <PhotoUpload
                    imageSrc={photo.src || undefined}
                    upload={(f, clb) => upload(f, clb, photo)}
                    remove={(clb) => remove(clb, photo)}
                    afterRemove={afterRemove}
                    afterUpload={afterUpload}
                  />
                </UploadItem>
              ),
          )}
        </UploadContainer>
      </Step>
      <CTAContainer idx={idx} prev={prev}>
        {forcePhoto || photos[0].src ? (
          <MediumPrimaryButton
            disabled={!photos[0].src}
            data-automation="upload-photo-button"
            click={nextClb}
            text="Next"
          />
        ) : (
          <SecondaryButton
            data-automation="upload-photo-button"
            onClick={(e: any) => {
              GA.skipPhotoUpload(itemType, stepName, idx);
              next(e);
            }}
          >
            Skip
          </SecondaryButton>
        )}
      </CTAContainer>
    </StepCTAContainer>
  );
}

export default StepPhotoUpload;
