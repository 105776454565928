import React, { useEffect } from 'react';
import { ThemeProvider, mainTheme } from '@worthy-npm/worthy-common-ui-components';
import CommonLayout from './layout/commonLayout';
import Wizard, { IStep } from '../components/wizard';
import { useAppDispatch } from '../app/hooks';
import { updateSecondarySubset, updateWizardStepName, updateType } from '../slices/submitSlice';
import StepShape from '../components/steps/common/step_shape';
import { EarringsStyle, Subsets } from '../data/ItemDetails';
import DiamondNumber from '../components/steps/common/step_stoneNumber';
import StepBrand from '../components/steps/common/step_brand';
import TotalWeight from '../components/steps/common/step_totalWeight';
import Certificate from '../components/steps/common/step_certificate';
import Weight from '../components/steps/common/step_weight';
import StepRegistration from '../components/steps/common/step_registration';
import StepPhotoUpload from '../components/steps/common/step_photoUpload';
import StepStyle from '../components/steps/common/step_style';
import GA from '../data/GA';

export const earringsStyleItems = [
  {
    text: 'Drop',
    img: 'images/icons/drop.png',
    code: EarringsStyle.Drop,
    secondarySubset: Subsets.CenterStone,
  },
  {
    text: 'Stud',
    img: 'images/icons/stud.png',
    code: EarringsStyle.Stud,
    secondarySubset: Subsets.CenterStone,
  },
  {
    text: 'Jacket',
    img: 'images/icons/jacket.png',
    code: EarringsStyle.Jacket,
    secondarySubset: Subsets.CenterStone,
  },
  {
    text: 'Central Cluster',
    img: 'images/icons/central_cluster.png',
    code: EarringsStyle.CentralCluster,
    secondarySubset: Subsets.CenterStone,
  },
  {
    text: 'Blossom Cluster',
    img: 'images/icons/blossom_cluster.png',
    code: EarringsStyle.BlossomCluster,
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    text: 'Chandelier',
    img: 'images/icons/chandelier.png',
    code: EarringsStyle.Chandelier,
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    text: 'Hoops',
    img: 'images/icons/hoops.png',
    code: EarringsStyle.Hoops,
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    text: 'Huggies',
    img: 'images/icons/huggie.png',
    code: EarringsStyle.Huggie,
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    code: EarringsStyle.Other,
    secondarySubset: Subsets.CenterStone,
    img: 'images/icons/other.svg',
    text: 'Other',
  },
];

export const createSteps = (): IStep[] => [
  {
    comp: StepStyle,
    name: 'StepEarringsStyle',
    caption: 'Earrings Style',
    title: 'What is the style of your earrings?',
    props: { items: earringsStyleItems },
    disableBackButton: true,
  },
  {
    comp: StepShape,
    name: 'StepLargestDiamondsShape',
    caption: 'Diamonds Shape',
    title: 'What is the shape of the largest diamond?',
    secondarySubset: Subsets.CenterStone,
  },
  {
    comp: DiamondNumber,
    name: 'StepNumberOfStones',
    caption: 'Number of Stones',
    title: 'Great! How many diamonds are set in your earring?',
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    comp: TotalWeight,
    name: 'StepTotalCaratWeight',
    caption: 'Carat Weight',
    title: 'What is the total carat weight?',
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    comp: StepShape,
    name: 'StepCommonDiamondsShape',
    caption: '',
    title: 'What is the most common diamond shape in your earrings?',
    secondarySubset: Subsets.MinorStoneBatch,
  },
  {
    comp: Weight,
    name: 'StepCaratWeight',
    caption: 'Carat Weight',
    title: 'Great! What is the largest diamond’s carat weight?',
    secondarySubset: Subsets.CenterStone,
  },
  {
    comp: Certificate,
    name: 'StepCertificate',
    caption: 'Certificate',
    title: 'What type of certificate do you have?',
    secondarySubset: Subsets.CenterStone,
  },
  {
    comp: StepBrand,
    name: 'StepBrand',
    caption: 'Brand',
    title: 'What brand are your earrings?',
  },
  {
    comp: StepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    itemRequired: true,
    skipOnlogin: true,
    disableBackButton: true,
  },
  {
    comp: StepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload photos',
    itemRequired: true,
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
  },
];

function EarringsPage() {
  const dispatch = useAppDispatch();
  const steps = createSteps();

  dispatch(updateType('earrings'));

  useEffect(() => {
    // default subset for earrings to prevent wrong calculation for count of steps
    dispatch(updateSecondarySubset('diamond_center_stone'));
    dispatch(updateWizardStepName(steps[0].name));
    GA.flow();

    const el = document.querySelector("meta[name='theme-color']");
    if (el) {
      el.setAttribute('content', '#F6F8FF');
    }
  }, [dispatch, steps]);

  return (
    <ThemeProvider theme={mainTheme}>
      <CommonLayout itemType="earrings">
        <Wizard steps={steps} />
      </CommonLayout>
    </ThemeProvider>
  );
}

export default EarringsPage;
