import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import {
  BlueWordButton,
  StepTitleText,
  FormContentNarrow,
  Step,
  NextStepButton,
  StepCTAContainer,
} from '../../styles/commonText';

import {
  MediumPrimaryButton,
  GENERAL_COLOR_VALUES,
  GENERAL_FONT_VALUES,
  GeneralColorNames,
  GeneralFontNames,
  SpacingSizes,
  NumberInput,
  MarginVsides,
} from '../../clarity';

import { isValidCaratValue, castToCarat } from '../../validation/validations';
import CenterDiamond from '../popup/center_diamond';
import { useMobileVersion, useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectItemType, selectSubmitData, updateCarat } from '../../slices/submitSlice';
import { StepProps } from './common';
import CaratWeight from '../popup/carat_weight';
import CTAContainer from '../CTAContainer';
import { suggestCaratThreshold } from '../../data/constants';
import GA from '../../data/GA';

const CaratInputWrapper = styled.div`
  margin-top: ${SpacingSizes.sm};
  margin-bottom: ${SpacingSizes.xl};
  width: 100%;
  /* Chrome, Safari, Edge, Opera */
  & input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & input[type='number'] {
    -moz-appearance: textfield;
  }
`;
const NoteText = styled.div`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.roboto_16]};
  font-size: 16px;
  text-align: center;
  width: 100%;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
`;
const IntercomLink = styled.button`
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  display: inline;
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main]};
  margin-bottom: ${SpacingSizes.sm};
`;

function StepCarat({ next, stepName, idx, prev }: StepProps) {
  const [centerDiamondPopupVisible, showCenterDiamond] = useState<boolean>(false);
  const [caratWeightPopupVisible, showCaratWeight] = useState<boolean>(false);

  const { carat } = useAppSelector(selectSubmitData);
  const [caratValue, setCaratValue] = useState<number>(carat);
  const [isCaratValueValid, validateCaratValue] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const isMobile = useMobileVersion();
  const itemType = useAppSelector(selectItemType);

  const sendCaratWeightToGA = (customCarat: number) => {
    // We're sending 2 events here - for carat weight and Next button being clicked
    GA.submitNext(itemType, stepName, idx, (customCarat || caratValue).toString());
  };

  const nextClb = (e: React.MouseEvent<HTMLButtonElement>, value: number) => {
    dispatch(updateCarat(value));
    sendCaratWeightToGA(value);
    next(e);
  };

  const change = (value: string) => {
    const isValid = isValidCaratValue(value);
    validateCaratValue(isValid);
    if (isValid) {
      const res = castToCarat(value);
      setCaratValue(res);
    } else setCaratValue(0);
  };

  return (
    <StepCTAContainer isMobile={isMobile} data-automation="carat-step">
      <Step>
        <StepTitleText>
          <span>Great, and what is the carat weight of your&nbsp;</span>
          <BlueWordButton
            data-automation="carat-center-diamond-button"
            onClick={() => showCenterDiamond(!centerDiamondPopupVisible)}
          >
            center diamond
          </BlueWordButton>
          <span>?</span>
        </StepTitleText>
        <FormContentNarrow>
          <CaratInputWrapper>
            {!caratWeightPopupVisible && (
              <NumberInput
                overrideWrapperCSS={{
                  marginBottom: '-14px',
                }}
                initialValue={`${carat || ''}`}
                isValid={isCaratValueValid}
                autoFocus={!isMobile}
                enter={(value: string) => {
                  const isValid = isValidCaratValue(value);
                  validateCaratValue(isValid);
                  if (isValid) {
                    const res = castToCarat(value);
                    if (res >= suggestCaratThreshold) {
                      GA.invalidCarat(stepName, idx);
                      showCaratWeight(true);
                    } else {
                      dispatch(updateCarat(res));
                      sendCaratWeightToGA(res);
                      // @ts-ignore
                      next();
                    }
                  }
                }}
                change={change}
                blur={(value: string) => {
                  if (value === `${carat}` || value === '') {
                    return;
                  }
                  const isValid = isValidCaratValue(value);
                  validateCaratValue(isValid);
                  if (isValid) {
                    const res = castToCarat(value);
                    if (res >= suggestCaratThreshold) {
                      GA.invalidCarat(stepName, idx);
                      showCaratWeight(true);
                    }
                  }
                }}
                dataAutomation="carat-set-input"
                label="Carat weight"
                marginVsides={MarginVsides.xs}
              />
            )}
            <NoteText>
              Tip: Most common center diamond Carat weight is 0.9CT.
              <IntercomLink
                data-automation="intercom-open-step-carat"
                className="intercom_chat_link"
                onClick={() => {
                  GA.intercomClick(itemType, stepName, idx);
                }}
              >
                I need help with this
              </IntercomLink>
            </NoteText>
          </CaratInputWrapper>
        </FormContentNarrow>
        {centerDiamondPopupVisible && <CenterDiamond setPopupVisible={showCenterDiamond} />}
        {caratWeightPopupVisible && (
          <CaratWeight next={nextClb} setPopupVisible={showCaratWeight} itemCarat={caratValue} />
        )}
      </Step>

      <CTAContainer idx={idx} prev={prev}>
        <NextStepButton
          data-automation="wizard-next-button"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            if (caratValue > 0) {
              nextClb(e, caratValue);
            }
          }}
        >
          <MediumPrimaryButton
            click={(clb: any) => clb && clb()}
            dataAutomation="carat-set-next"
            disabled={!isCaratValueValid || caratValue <= 0}
            text="Next"
          />
        </NextStepButton>
      </CTAContainer>
    </StepCTAContainer>
  );
}

export default StepCarat;
