import _ from 'lodash';

declare const window: any;

/// use only for google button callback ////////////////
export const getItemIdFromWindow = () => {
  return window?.google?.worthy_temp_itemId;
};
export const setItemIdToWindow = (itemId: string) => {
  if (itemId) {
    _.set(window, 'google.worthy_temp_itemId', itemId);
  }
};
/// /////////////
