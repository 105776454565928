import * as React from 'react';
import { Button, Box, Typography, mainTheme, Logo } from '@worthy-npm/worthy-common-ui-components';
import { COLOR_VALUES } from '@worthy-npm/worthy-common-ui-components/theme/colors';
import styled from '@emotion/styled/macro';
import { InfoMail } from '../data/objects/env';
import { useAppSelector } from '../app/hooks';
import { selectItemType, selectSubmitAPI, selectSubmitData } from '../slices/submitSlice';
import { NewFlowsTypes } from './submission/common';
import { Step, StepContainer } from '../styles/common';
import { directToItemTypePage } from '../lib/commonUtils';

const InfoBoxWrap = styled(Box)(() => ({
  borderRadius: mainTheme.shape.borderRadius,
  border: `1px solid ${mainTheme.palette.grey['200']}`,
  backgroundColor: mainTheme.palette.background.paper,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: '379px',
  width: '100%',
  height: '200px',
  padding: '30px',
}));

const mailTo = `mailto:${InfoMail}`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`;

const Container = styled.div`
  text-align: center;
  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: auto;
    padding: 0 28px;
  }
`;

const StyleWrap = styled.div`
  position: absolute;
  justify-content: center;
  left: 0;
  z-index: 1;
  overflow: auto;
  max-height: 100%;
  min-width: 100%;
  width: 100%;
`;

function SorryImg() {
  return (
    <img data-automation="network-error-overlay" src="/images/sorryImg.svg" alt="sorry bird" />
  );
}

function InfoBox() {
  return (
    <Box
      display="flex"
      columnGap="16px"
      rowGap="16px"
      marginTop="27px"
      flexWrap="wrap"
      justifyContent="center"
    >
      <InfoBoxWrap>
        <Typography variant="h6" fontSize="20px" color={mainTheme.palette.grey['600']}>
          Have More Jewelry?
        </Typography>
        <Typography variant="button" textTransform="none" textAlign="center">
          We would love to hear about other items you&apos;re considering selling
        </Typography>
        <Button
          variant="outlined"
          size="large"
          disableElevation
          sx={{ width: '152px', height: '51px', padding: 0 }}
          onClick={directToItemTypePage}
        >
          <Typography color={COLOR_VALUES.primary_700_main} variant="button">
            submit new item
          </Typography>
        </Button>
      </InfoBoxWrap>

      <InfoBoxWrap>
        <Typography variant="h6" fontSize="20px" color={mainTheme.palette.grey['600']}>
          Still Have More Questions?
        </Typography>
        <Typography variant="button" textTransform="none" textAlign="center">
          Our team is here to answer further questions about your item should you need
          <Box display="flex" justifyContent="center" alignItems="center" columnGap="10px">
            <Link href={mailTo}>
              <Button
                variant="outlined"
                size="large"
                disableElevation
                sx={{ width: '152px', height: '68px', display: 'flex', flexDirection: 'column' }}
              >
                <Typography color={COLOR_VALUES.primary_700_main} variant="button">
                  email us
                </Typography>
                <Typography
                  color={mainTheme.palette.grey['500']}
                  fontSize="14px"
                  textTransform="none"
                >
                  info@worthy.com
                </Typography>
              </Button>
            </Link>
            <Link href="tel:+18882220208">
              <Button
                variant="outlined"
                size="large"
                disableElevation
                sx={{ width: '152px', height: '68px', display: 'flex', flexDirection: 'column' }}
              >
                <Typography color={COLOR_VALUES.primary_700_main} variant="button">
                  call us
                </Typography>
                <Typography
                  color={mainTheme.palette.grey['500']}
                  fontSize="14px"
                  textTransform="none"
                >
                  1 (888) 222-0208
                </Typography>
              </Button>
            </Link>
          </Box>
        </Typography>
      </InfoBoxWrap>
    </Box>
  );
}

const getText = (itemTypeLower: string, rejectReason: string) => (
  <Typography
    variant="button"
    textAlign="center"
    textTransform="none"
    margin="32px 0 32px 0"
    maxWidth="740px"
  >
    {rejectReason === 'properties' ? (
      <Typography>
        Thank you for considering selling your {itemTypeLower} with Worthy. We work with a network
        of professional diamond buyers, and to help our sellers get the best deal for their jewelry,
        we can only accept diamonds with a minimum carat size of 0.7 CTs. Unfortunately, the
        information you provided regarding your item does not match the characteristics and will not
        perform well on our auction platform.
      </Typography>
    ) : (
      <Typography>
        Thank you for considering selling your {itemTypeLower} with Worthy. We work with a network
        of professional diamond buyers, and to help our sellers get the best deal for their jewelry,
        we need to accept items with specific diamond properties. Unfortunately, the information you
        provided regarding your item does not match the characteristics and will not perform well on
        our auction platform.
      </Typography>
    )}
  </Typography>
);

export default function ItemRejection() {
  const itemType = useAppSelector(selectItemType);
  const { type } = useAppSelector(selectSubmitData);
  const { rejectReason } = useAppSelector(selectSubmitAPI);
  const itemTypeLower = itemType.toLowerCase();
  const showHeader = NewFlowsTypes.includes(type);

  return (
    <Box
      style={!showHeader ? { position: 'fixed' } : {}}
      width="100%"
      height="100%"
      top="0"
      left="0"
      zIndex="5"
      overflow="auto"
      bgcolor={mainTheme.palette.background.default}
    >
      {!showHeader && (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin="32px 0"
          onClick={directToItemTypePage}
        >
          <Logo />
        </Box>
      )}
      <StyleWrap style={showHeader ? { height: '100%' } : { top: '13%' }}>
        <StepContainer data-automation="style-step">
          <Step id="step">
            <Container>
              <SorryImg />
              <Typography
                color={COLOR_VALUES.primary_700_main}
                fontFamily="Playfair"
                fontSize="20px"
                margin="16px 0 8px 0"
              >
                We&apos;re sorry
              </Typography>
              <Typography fontWeight="500" fontSize="28px" textAlign="center" paddingTop="5px">
                Your {itemTypeLower} is not eligible for auction
              </Typography>
            </Container>
            <Box display="block" maxWidth="780px" margin="27px auto auto">
              {getText(itemTypeLower, rejectReason)}
              <Typography
                color={COLOR_VALUES.primary_700_main}
                fontFamily="Playfair"
                fontSize="20px"
                textAlign="center"
                marginTop="27px"
              >
                This doesn&apos;t have to be goodbye...
              </Typography>
              <InfoBox />
            </Box>
          </Step>
        </StepContainer>
      </StyleWrap>
    </Box>
  );
}
