import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { Typography } from '@worthy-npm/worthy-common-ui-components';
import {
  FormContent,
  StepContainer,
  StepCTAContainer,
  StepTitleText,
  ExternalLink,
  RunningText,
} from '../../styles/commonText';
import { EmailInput, MediumPrimaryButton, NameInput, PhoneInput } from '../../clarity';
import { useMobileVersion, useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  registerUser,
  selectItemType,
  selectRegister,
  selectRegisterAPI,
  selectSubmitAPI,
  TRegistration,
  updateItemAutoRejected,
  updateRegistration,
  updateUserPhoneNumber,
} from '../../slices/submitSlice';
import { StepProps, InputItem, ErrorMessage, sendFocusEvent } from './common';
import { initialState, TLocalInputsState } from './user_details';
import { checkRegistrationFields, isItemRejected } from '../../validation/validations';
import CTAContainer from '../CTAContainer';
import { GoogleSignIn, GoogleSignInEnabled } from '../google_signin';
import { CookieStore } from '../../services/cookieStore';
import { LoadingContainer, LoadingOverlay, LoadingImg } from '../overlay';
import { IsMobileProp } from '../../styles/commonPropType';
import { generateEstimation, postRegistrationItemEvents } from '../../lib/submitHelper';
import { getItemIdFromWindow, setItemIdToWindow } from '../../lib/item';
import GA from '../../data/GA';
import { privacyPolicyLink, termsConditionsLink } from './common/step_registration';

const RunningTextBold = styled(RunningText)`
  font-weight: 500;
`;

const LogInContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 50px;
  align-items: center;
`;

const TermsConditionsContainer = styled.div`
  display: flex;
  text-align: center;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: 20px auto auto;
  padding-bottom: 20px;
`;

const HRContainer = styled.div`
  font-family: roboto, sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
  color: #cccccc;
  max-width: 1440px;
  font-size: 80%;
  margin: 1em auto;
`;

const HR = styled.hr`
  width: 100%;
  margin: 1em 0;
`;

const VSuccess = 'images/icons/v_success.svg';

const GoogleVerified = styled.div<IsMobileProp>`
  padding-bottom: 2rem;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #00bc35;
`;

const prepareLocalStateForStore = (localState: TLocalInputsState) => {
  const res: TRegistration = {};
  Object.entries(localState).forEach(([key, val]) => {
    res[key] = val.value;
  });
  return res;
};

function LogInLink() {
  return <ExternalLink href={`${process.env.REACT_APP_APP_URL}/signin`}>Log in</ExternalLink>;
}

function StepRegistration({ next, stepName, idx, prev }: StepProps) {
  const registerAPI = useAppSelector(selectRegisterAPI);
  const registration = useAppSelector(selectRegister);
  const { displayOptionalPhoneLabel, forcePhoneNumber, itemId } = useAppSelector(selectSubmitAPI);
  const dispatch = useAppDispatch();
  const [register, setRegister] = useState(initialState);
  const [verificationView, setVerificationView] = useState(false);
  const [allFieldsAreValid, setAllFieldsAreValid] = useState(false);
  const isMobile = useMobileVersion();
  const [loading, setLoading] = useState(false);
  const itemType = useAppSelector(selectItemType);

  useEffect(() => {
    if (verificationView) {
      const newData: any = { ...registration };
      Object.keys(newData).forEach((key) => {
        newData[key] = { value: newData[key] };
      });
      const [allFieldsCheck, newState] = checkRegistrationFields(
        { ...register },
        {
          forcePhoneNumber,
          verificationView,
        },
      );
      setRegister(newState);
      setAllFieldsAreValid(allFieldsCheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationView]);

  setItemIdToWindow(itemId);

  const enableVerification = () =>
    CookieStore.getVerifyPhoneNumberExp() && CookieStore.getGoogleSigninExp();

  const getStepName = () => (verificationView ? 'StepVerification' : stepName);

  interface IUser {
    id: number;
    phone: string;
    email: string;
  }

  const afterSuccessfullLoginOrRegistrationActivities = async (
    itemID: string,
    isRegistration: boolean,
  ) => {
    const result = await generateEstimation(itemID);
    const { user, item }: any = result;

    const { rejected, rejectReason } = isItemRejected(item);
    if (rejected) {
      dispatch(updateItemAutoRejected({ rejected, reason: rejectReason }));
    }
    if (!user || !item) {
      return;
    }
    if (!isRegistration) {
      return;
    }

    postRegistrationItemEvents(user, item);
  };

  const onSuccessfulGoogleLogin = async (
    user: IUser | undefined,
    isUserLogin: boolean,
    leadId: number,
  ) => {
    const isRegistration = !isUserLogin;
    const stepNameWithLogin = isUserLogin ? 'StepLogin' : getStepName();
    // send an event on successful Google
    GA.successfullGoogleRegister(itemType, stepNameWithLogin, idx, leadId, user?.id, isUserLogin);

    await afterSuccessfullLoginOrRegistrationActivities(getItemIdFromWindow(), isRegistration);

    if (enableVerification() && isRegistration && (!user?.phone || user?.phone === '0000000001')) {
      setVerificationView(true);
    } else {
      next('' as any);
    }
  };

  const sendEventsWithFieldsData = () => {
    GA.registrationFields(itemType, getStepName(), idx, register);
  };

  const sendEventContinueClick = () => {
    // send event for user clicked 'continue'
    GA.continue(itemType, getStepName(), idx);
  };

  const errorMessageProccess = (errMsg: string) => {
    const isLoginContain = errMsg.match('Log in');
    if (isLoginContain) {
      return (
        <>
          {errMsg.substring(0, isLoginContain.index)}
          <LogInLink />
          {errMsg.substring((isLoginContain.index || 0) + 6)}
        </>
      );
    }
    return errMsg;
  };

  const onVerficationSubmit = async (stopAnimation: any) => {
    if (registerAPI.loading) {
      return;
    }

    sendEventsWithFieldsData();
    sendEventContinueClick();

    const registerWithPhone = { ...registration, phoneNumber: register.phoneNumber?.value };
    dispatch(
      updateUserPhoneNumber({
        data: registerWithPhone,
        callBack: stopAnimation,
        successCallBack: () => {
          if (registerWithPhone.phoneNumber !== '') {
            GA.phoneSuccess(itemType, getStepName(), idx);
          }
          next('' as any);
        },
      } as any) as any,
    );
  };

  const isMandatoryPhoneLabelExperiment = CookieStore.getMandatoryPhoneLabel();
  const optionalPhoneLabelExperiment = CookieStore.getOptionalPhoneLabelExp();

  const onFocus = (fieldName: string) => {
    sendFocusEvent(fieldName, idx, getStepName());
  };

  const onChange = (key: string, value: string) => {
    const updatedState = {
      ...register,
      [key]: {
        value,
      },
    };
    const [allFieldsCheck, newState] = checkRegistrationFields(
      {
        ...updatedState,
      },
      {
        forcePhoneNumber,
        verificationView,
      },
    );
    setRegister(newState);
    setAllFieldsAreValid(allFieldsCheck);
    return newState[key].isValid;
  };

  return (
    <LoadingContainer>
      {loading && (
        <LoadingOverlay className="loadingdiv">
          <LoadingImg />
        </LoadingOverlay>
      )}
      <StepCTAContainer
        isMobile={isMobile}
        data-automation={verificationView ? 'verification-step' : 'registration-step'}
      >
        <StepContainer>
          {!verificationView && (
            <StepTitleText>Done! Enter your details and see how much you can get.</StepTitleText>
          )}
          {/* without div - GoogleSignIn breaks other steps */}
          <div className="black-magic-dont-delete">
            <GoogleSignIn
              onSuccess={onSuccessfulGoogleLogin}
              setLoading={setLoading}
              disabled={verificationView}
            />
          </div>
          {verificationView && (
            <GoogleVerified>
              <img src={VSuccess} alt="" width="16px" />
              <span> Google account verified </span>
            </GoogleVerified>
          )}
          <FormContent isMobile={isMobile}>
            {GoogleSignInEnabled() && !verificationView && (
              <HRContainer>
                <HR />
                <div style={{ margin: '0 0.5em' }}>OR</div>
                <HR />
              </HRContainer>
            )}
            <InputItem
              key="input-field-firstName"
              isMobile={isMobile}
              data-testid="input-field-firstName"
              data-automation="input-field-firstName"
            >
              <NameInput
                focus={() => onFocus('firstName')}
                change={(v: string) => onChange('firstName', v)}
                label="First Name"
                disabled={verificationView}
                verification={verificationView}
                initialValue={registration.firstName}
                showValidationMessage
                isValid={register.firstName.isValid}
              />
            </InputItem>
            <InputItem
              key="input-field-lastName"
              isMobile={isMobile}
              data-testid="input-field-lastName"
              data-automation="input-field-lastName"
            >
              <NameInput
                focus={() => onFocus('lastName')}
                change={(v: string) => onChange('lastName', v)}
                label="Last Name"
                disabled={verificationView}
                verification={verificationView}
                initialValue={registration.lastName}
                showValidationMessage
                isValid={register.lastName.isValid}
              />
            </InputItem>
            <InputItem
              key="input-field-email"
              isMobile={isMobile}
              data-testid="input-field-email"
              data-automation="input-field-email"
            >
              <EmailInput
                focus={() => onFocus('email')}
                change={(v: string) => onChange('email', v)}
                label="Email address"
                disabled={verificationView}
                verification={verificationView}
                initialValue={registration.email}
                showValidationMessage
                isValid={register.email.isValid}
              />
            </InputItem>
            <InputItem
              key="input-field-phoneNumber"
              isMobile={isMobile}
              data-testid="input-field-phoneNumber"
              data-automation="input-field-phoneNumber"
            >
              <PhoneInput
                label={`Phone number${
                  displayOptionalPhoneLabel && optionalPhoneLabelExperiment ? ' (optional)' : ''
                }`}
                initialValue={registration.phoneNumber}
                verification={verificationView}
                focus={() => onFocus('phoneNumber')}
                change={(v: string) => onChange('phoneNumber', v)}
                showValidationMessage
                isValid={register.phoneNumber.isValid}
              />
            </InputItem>
          </FormContent>
          {registerAPI.isError && (
            <ErrorMessage>{errorMessageProccess(registerAPI.errorMsg)}</ErrorMessage>
          )}
        </StepContainer>
        <CTAContainer idx={idx} prev={verificationView ? null : prev}>
          {verificationView ? (
            <MediumPrimaryButton
              dataAutomation="verification-button"
              noPadding
              click={onVerficationSubmit}
              text="Continue"
              disabled={!allFieldsAreValid}
            />
          ) : (
            <MediumPrimaryButton
              dataAutomation="registration-button"
              click={(stopAnimation: any) => {
                if (registerAPI.loading) {
                  return;
                }
                sendEventsWithFieldsData();
                const processedState = prepareLocalStateForStore(register);
                // @ts-ignore
                dispatch(updateRegistration(processedState));
                dispatch(
                  registerUser({
                    data: processedState,
                    clb: stopAnimation,
                    successClb: async (data: any) => {
                      GA.registrationSuccess(
                        itemType,
                        getStepName(),
                        idx,
                        data?.lead_id,
                        data?.user?.id,
                      );
                      await afterSuccessfullLoginOrRegistrationActivities(itemId, true);
                      next('' as any);
                    },
                  } as any) as any,
                );
              }}
              disabled={!allFieldsAreValid}
              text="Finish"
            />
          )}
        </CTAContainer>
        {!verificationView && (
          <div>
            <LogInContainer>
              <RunningTextBold>Already signed up?&nbsp;</RunningTextBold>
              <LogInLink />
            </LogInContainer>
          </div>
        )}
        <TermsConditionsContainer>
          <Typography variant="body2" marginTop="24px" textAlign="center" fontSize="12px">
            By signing up, you agree to the Worthy {termsConditionsLink} and to receive marketing
            communications from Worthy in accordance with our {privacyPolicyLink}.
          </Typography>
        </TermsConditionsContainer>
      </StepCTAContainer>
    </LoadingContainer>
  );
}
export default StepRegistration;
