import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const { REACT_APP_GIT_HASH } = process.env;

const VERSION = '1.0.0';

const EVENTS = {
  names: {
    interaction: 'interaction',
    data: 'pushData',
    feedback: 'feedback',
  },
  actions: {
    open: 'open',
    click: 'click',
    success: 'success',
    select: 'select',
    input: 'input',
    navigate: 'navigate',
    clear: 'clear',
    toggle: 'toggle',
    failure: 'failure',
    close: 'close',
  },
  domains: {
    submisssionFlow: 'submisssionFlow',
    registration: 'registration',
    photoUpload: 'photoUpload',
    submitItem: 'submitItem',
  },
  navigationObjects: {
    intercom: 'intercom',
    back: 'back',
    skip: 'skip',
    continue: 'continue',
  },
} as const;

interface GAEvent {
  event: string;
  action: string;
}

interface GAEventUniversal extends GAEvent {
  category: string;
  label: string;
}

interface GAEventUniversalViaGTM {
  event: 'GTM event To GA';
  GA_event_value: string;
  GA_event_category: string;
  GA_event_action: string;
  GA_event_label: string;
  WorthyClientID?: number; // this is lead id. NEVER put user id here!
  userId?: number;
  projectedLeadValue?: number;
}

interface GA4Event extends GAEvent {
  stepNumber: number;
  stepName: string;
  pageType: string;
  itemType: string;
  pagePath: string;
  clickText?: string;
  leadId?: number;
  userId?: number;
  value?: string;
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

interface KeyVal {
  [key: string]: any;
}

const removeUndefined = (obj: KeyVal) => {
  // eslint-disable-next-line no-param-reassign
  Object.keys(obj).forEach((key) => ([null, undefined].includes(obj[key]) ? delete obj[key] : {}));
  return obj;
};

export const getDataLayer = () => {
  return window.dataLayer;
};

export const pushToDataLayer = (data: any) => {
  if (_.isEmpty(data)) return;

  if (!window.dataLayer) return;

  window.dataLayer.push(removeUndefined(data));
};

const prepareGAEventForGTM = (data: any): GAEventUniversalViaGTM => ({
  event: 'GTM event To GA',
  GA_event_value: data.value || data.event,
  GA_event_category: data.pageType,
  GA_event_action: `${data.stepName} ${data.action}`.trim(),
  GA_event_label: `${data.clickText || data.itemType}`,
  WorthyClientID: data?.leadId,
  userId: data?.userId,
});

const baseEvent = {
  version: VERSION,
  uniqueId: uuidv4(),
};

const prepareGA4Event = (data: any) => {
  const cleanEvent = { ...data };
  delete cleanEvent.event;
  delete cleanEvent.category;
  delete cleanEvent.action;
  delete cleanEvent.label;
  return {
    ...baseEvent,
    ...cleanEvent,
    event: `ga4_${data.event}`,
  };
};

export const sendEvent = (data: GA4Event | GAEventUniversal) => {
  if (window.dataLayer === undefined) {
    return;
  }
  // eslint-disable-next-line no-useless-catch
  try {
    const gaEventData = prepareGAEventForGTM(data);
    pushToDataLayer(gaEventData);
    const ga4EventData = prepareGA4Event(data);
    pushToDataLayer(ga4EventData);
  } catch (e) {
    // TODO - change to rollbar error once configured
    throw e;
  }
};

interface StepsGAEventProps {
  action: string;
  stepName: string;
  idx: number;
  clickText?: string;
  itemType?: string;
  leadId?: number;
  userId?: number;
  value?: string;
  pageType?: string;
}

export const sendGeneralGAEvent = ({
  action,
  idx,
  stepName,
  clickText,
  itemType,
  leadId,
  userId,
  value,
  pageType,
}: StepsGAEventProps) => {
  sendEvent({
    clickText,
    leadId,
    value,
    userId,
    itemType: itemType || 'Ring',
    action,
    event: action === 'click' ? 'funnelInteraction' : 'funnelStep',
    stepName: stepName.replace('Step', ''),
    stepNumber: idx + 1,
    pageType: pageType || 'SubmissionFlow',
    pagePath: `${document.location.origin}${document.location.pathname}`,
  });
};

export class BaseEvent {
  static pushToDataLayer = (data: Record<string, any>) => {
    if (_.isEmpty(data)) return;

    if (!window.dataLayer) return;

    window.dataLayer.push(data);
  };

  // should be set when the app start
  static submissionId: string;

  static setSubmissionId = (id: string) => {
    this.submissionId = id;
  };

  static locationObject = (location: string[]) => {
    const [
      location1 = null,
      location2 = null,
      location3 = null,
      location4 = null,
      location5 = null,
    ] = location;
    return {
      location1,
      location2,
      location3,
      location4,
      location5,
    };
  };

  static eventObject = (
    eventName: typeof EVENTS.names[keyof typeof EVENTS.names],
    action: typeof EVENTS.actions[keyof typeof EVENTS.actions],
    location: string[],
    actionedObject?: string,
    actionedValue?: any,
  ) => ({
    event: eventName,
    action: action || null,
    actionedObject: actionedObject || null,
    actionedValue: actionedValue || null,
    fieldName: 'submissionId',
    fieldValue: this.submissionId || null,
    appVersion: REACT_APP_GIT_HASH || null,
    ...this.locationObject(location),
  });

  static dataEventObject = (data: any) =>
    removeUndefined({
      ...data,
      event: EVENTS.names.data,
      appVersion: REACT_APP_GIT_HASH,
      submissionId: this.submissionId,
    });

  static data(data: object) {
    this.pushToDataLayer(this.dataEventObject(data));
  }

  static open(location: string[]) {
    this.pushToDataLayer(this.eventObject(EVENTS.names.feedback, EVENTS.actions.open, location));
  }

  static click(location: string[], actionedObject?: string, actionedValue?: any) {
    this.pushToDataLayer(
      this.eventObject(
        EVENTS.names.interaction,
        EVENTS.actions.click,
        location,
        actionedObject,
        actionedValue,
      ),
    );
  }

  static success(location: string[]) {
    this.pushToDataLayer(this.eventObject(EVENTS.names.feedback, EVENTS.actions.success, location));
  }

  static select(location: string[], actionedObject: string, actionedValue: any) {
    this.pushToDataLayer(
      this.eventObject(
        EVENTS.names.interaction,
        EVENTS.actions.select,
        location,
        actionedObject,
        actionedValue,
      ),
    );
  }

  static navigate(
    location: string[],
    actionedObject: typeof EVENTS.navigationObjects[keyof typeof EVENTS.navigationObjects],
  ) {
    this.pushToDataLayer(
      this.eventObject(EVENTS.names.interaction, EVENTS.actions.navigate, location, actionedObject),
    );
  }

  static input(location: string[], actionedObject: string, actionedValue: any) {
    this.pushToDataLayer(
      this.eventObject(
        EVENTS.names.interaction,
        EVENTS.actions.input,
        location,
        actionedObject,
        actionedValue,
      ),
    );
  }
}
